div#jitsi-iframe {
  //margin-left: 65px;
}

.jitsi_pip_mode_button {
  background-color: #141414;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline-style: none;
  position: absolute;
  right: 150px;
  top: 72px;
  border-radius: 6px;
  cursor: pointer;
  height: 48px;
  width: 48px;
}

.jitsi_pip_mode_button:hover {
  background-color: #999696;
}