.ccMainCont {
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ccMainSub {
  width: 97%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
// .filtersCCCOnt {
//   // width: 28%;
// }
.CCSubCateCont {
  width: 74%;
}
.CCfilteredItems-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.CCfiltered-item {
  border: 1px solid #e5e9ed;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 10px;
}

.category-box-gig {
  border: 1px solid #e5e9ed;
  width: 140px;
  display: flex;
  min-height: 135px;
  max-height: 135px;
  flex-direction: column;
  // justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.category-box-gig img {
  width: 135px;
  height: 100px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  object-fit: fill;
  transform-origin: 50% 0%;
  transition: 0.2s all ease-in-out;
}

.category-box-gig:hover img {
  width: 135px;
  height: 80px;
}
