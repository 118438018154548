.main-div {
  width: 100%;
  padding: 5px 10px;
  height: 100%;
  margin: 0px;
}

.no-content-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loader-div {
  // border: 1px solid red;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.summary-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.session-title {
  color: #6b6b6b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  width: calc(25ch);
}
.session-topic {
  color: #6b6b6b;
  margin-left: 5px;
}
.topics-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}
.topic-name {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #6b6b6b;
  margin-bottom: 15px;
}
