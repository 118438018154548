@import "../../themes/colors.scss";

.Join-button,
.google,
.facebook,
.apple {
  animation: slide-in 1s ease-in-out 0.5s forwards;
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
}

// .google:hover {
//   transform: scale(1.1);
//   transform: rotate(360deg);
//   transition: transform 0.5s ease-in-out;
//   // box-shadow: 0px 5px 15px rgba(114, 113, 113, 0.3);
// }

.react-tel-input .country-list .country.highlight {
  border-radius: 0px !important;
}
.react-tel-input .form-control {
  color: #000000 !important;
  background-color: #ffffff !important;
  border-color: #bcb8b8 !important;
}

.css-afrueh-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 90px !important;
}

.heading-con-fa {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30%;
}

.signup-container-role {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  margin-top: 20px;
  // position: fixed;
}
.signup-container-role-mobile {
  width: 100%;
  // padding-left: 5%;
  // padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  // margin-top: 20px;
  // position: fixed;
}

.what-brings {
  line-height: 0px;
}

.select-option {
  line-height: 0px;
}

.img-con {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mpped-items {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: scroll;
  height: 100%;
  // background-color: red;
}

.mpped-items-mobiles {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.registe-cards {
  height: 320px;
  // width: 349px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  border-radius: 25px;
  transition: transform 10s;
}

.registe-cards-mob {
  height: 150px;
  width: 200px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.provider-img {
  // width: 100%;
  background-size: contain;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  transition: transform 1s;
}

.provider-img:hover {
  transition: transform 0.3s ease;
}

.provider-img-mob {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 8px;
}

.aim {
  margin-top: 10px;
  transition: 0.02px;
  color: $grey_text;
}

.otp-bottom-con-fa {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  align-self: flex-end;
}
.social-btn-signin {
  border: 1px solid #e8e9ea;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
}
.social-btn-signin-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Join-button {
  background-color: #3166ba;
  transition: scale 1s !important;
}
.Join-button:hover {
  scale: 1.05 !important;
}

.instituteProfile-div1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.instituteProfile-div2 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.instituteProfile-div3 {
  background-color: #f2f2f6;
  padding: 13px;
  border-radius: 15%;
  position: fixed;
  right: 0;
  top: 140px;
  z-index: 200;
  margin-right: 20px;
  cursor: pointer;
}
.IP-icon1 {
  color: #000000;
}
.instituteSignup-div1,
.instituteWD-div1 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.instituteSignup-div1 {
  overflow: auto;
}
.instituteWD-div1 {
  margin-top: 40px;
}
// .css-1abc02a {
//     display: none !important;
// }

// .css-hn784z {
//     display: none !important;
// }

.inst-create-banner-sec-main .slick-initialized .slick-slide {
  margin-top: unset !important;
  position: relative;
}

.inst-create-banner-sec-main .slick-dots li button {
  display: block;
  padding: 0;
  width: 8px !important;
  height: 8px !important;
  border: none;
  border-radius: 100%;
  background-color: white !important;
  text-indent: -9999px;
}
.inst-create-banner-sec-main .slick-dots .slick-active button {
  width: 11px !important;
  height: 11px !important;
}

.inst-create-banner-text-cont div {
  margin-top: 0px !important;
  // margin-left: 50px !important;
}
