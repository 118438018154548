@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Montserrat:wght@500;600&family=Mulish&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.chat-group-create-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

// .chat-group-image-wrapper {
//   border: solid 2px #649bff;
//   width: 97px;
//   height: 97px;
//   border-radius: 50%;
//   cursor: pointer;
//   align-self: center;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   margin-bottom: 8px;

  .chat-group-image{
    height:100%;
    width:100%;
    border-radius: 50%;
  }


  #chat_room_profile_edit_menu_button{
    bottom:0px;
    right:0px;
    background-color: #3166ba;
    border-radius:50%;
    position: absolute;
  }

  .upload-icon-on-hover {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #eaf1ffd6;
    z-index: 2;
    border-radius: 50px;

    // .upload-image-icon {
    //   height: 50px;
    //   width: 50px;
    //   color: rgb(107 159 255);
    // }
  }
// }


#list_container_add_group {
  height: 280px;
  overflow: auto;
  .list-item-add-group {
    height: 55px;
  }

  .css-1xun3v8-MuiModal-root-MuiDialog-root .MuiDialogActions-root {
    padding-right: 25px;
    padding-bottom: 25px;
  }

  .css-g28sfz-MuiListItem-root > .MuiListItemButton-root {
    font-family: "Mulish", sans-serif !important;
    padding-right: 48px;
    height: 70px;
    cursor: auto;
  }

  .css-g28sfz-MuiListItem-root > .MuiListItemButton-root:hover {
    background: transparent;
  }


  .list-item-button-styles {
    height: 40px;
    width: 40px;
    border: none;
    background: #00bfa5;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    border-radius: 50px;
  }

  .button-with-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  p.button-text-add {
    margin-top: 2px;
    margin-left: 5px;
  }
}