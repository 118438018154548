.mainContainer-feedback {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}
@media (max-width: 600px) {
  .mainContainer-feedback {
    padding: 10px;
  }
}
.feedbacks-list-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .RRchield1div3 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .feedbacks-list-container {
    width: 100%;
    gap: 10px;
  }
}
.feedback-item {
  height: 160px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #dedede;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .feedback-item {
    padding: 5px;
    height: 130px;
    width: 100%;
    padding: 10px 10px;
    border-radius: 4px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .feedback-item {
    width: 49%;
    height: 150px;
  }
}
.feedback-comment-text {
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
}
.feedback-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
}
.feedback-child-div2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.feedback-child-div {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.feedback-main-div2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
