@import "../../themes/colors.scss";

#head-categories {
  top: 5px !important;
  .css-1hjrgzd-MuiTooltip-tooltip {
    margin-top: 10px !important;
  }
}
.hd-container {
  // height: 100px;
  background-color: $white_color;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 202;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 4px 0px #1e1e1e1f;
  padding: 6px 0px;
}

.hd-con2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.head-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signup-btn {
  background-color: $Aauti_theme_color;
  color: $white_color;
  padding: 8px;
  font-size: 18px;
  margin-left: 25px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.signin-btn {
  color: $balck_color;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.tabs {
  color: "red";
}

.sub-cat {
  padding: 5px 10px 5px 10px;
  margin: 5px;
  transform: transform 0.3s;
  cursor: pointer;
  width: 100%;
}

.sub-cat:hover {
  background-color: #d9eff9;
  border-radius: 4px;
}
.serviceprovider {
  background-color: transparent;
  border: 1px solid grey;
  padding: 10px;
}
#NotDrawer {
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 100%;
  }
}

.CountrySelect-div1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}

.CountrySelect-div2 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.CountrySelect-div3 {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 200px;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding-left: 7%;
  padding-right: 7%;
}

.CountrySelect-div4 {
  border-radius: 6px;
  border-style: solid;
  cursor: pointer;
  width: 45%;
  padding: 2px;
}

.CountrySelect-div5 {
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Country-image {
  width: 45px !important;
  height: 42px !important;
  border-radius: 5px !important;
}

.HeadCat-div1 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  min-width: 100%;
  justify-content: space-around;
  align-items: center;
  // margin-top: 5px;
  // overflow: hidden;
  overflow-x: scroll;
}

.HeadCat-div2 {
  padding: 10px;
  width: fit-content;
  max-width: fit-content;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
.HeadCat-div3 {
  padding: 5px 10px 5px 10px;
  // margin: 5px;
  transform: transform 0.3s;
  // width: 160px !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  // min-width: fit-content !important;
  overflow: scroll;
  // max-width: 200px;
  border-radius: 4px;
}

.HeadCat-text1 {
  color: black;
  // width: 100%;
}

.HeadCat-text2 {
  user-select: none;
  // width: 100%;
}

.Header-div1 {
  width: 100%;
}
.Header-div2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Header-div3 {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  justify-content: flex-end;
  gap: 15px;
}

.Header-tab1 {
  align-self: center;
  margin-top: 12px;
}
.Header-tab2 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.Header-img1 {
  height: 40px;
}

.Header-icon2 {
  cursor: pointer;
}
.Header-icon1 {
  cursor: pointer;
  margin-right: 10px;
}

.Header-button1 {
  border: none !important;
  outline: none;
  background-color: white;
}
.Header-button2,
.Header-button3 {
  margin-right: 10px;
  border-width: 0px;
  width: fit-content;
}
.Header-button4 {
  border: none;
  color: #078dda;
  font-weight: 600;
  padding: 5px;
  font-size: 18px;
  background: none;
  cursor: pointer;
}
.Header-text1 {
  color: #3083ef;
  font-size: 11px;
  margin-bottom: -8px;
}

.HeaderTabLogin-div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.HeaderTabLogin-div2 {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HeaderTabLogin-text1 {
  color: #000000;
  margin-left: 4px;
}
.HeaderTabLogin-text2 {
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}
.HeaderTabLogin-text3 {
  color: #000000;
  font-size: 10px;
  margin-top: 2px;
}

.ProfileMenu-menu1 {
  overflow: visible;
  // filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
  margin-top: 3rem; // Assuming mt: 5 means 5rem
  width: 330px;
  z-index: 4500;
  background-color: #f2f2f2 !important;
  // padding:10px;

  .MuiAvatar-root {
    width: 32px;
    height: 32px;
    // margin-bottom: 0.5rem; // Assuming mb: 0.5 means 0.5rem
  }

  .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 15px;
    width: 10px;
    height: 10px;
    background-color: #ffffff; // Assuming you have a variable $background-paper defined for the background color
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
  }
}

.Subscription-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // justify-content: space-around;
  margin-top: 10px;
}

.ProfileMenu-menu2 {
  display: none;
}
.ProfileMenu-div1 {
  width: 40px !important;
  height: 40px !important;
}
.ProfileMenu-button1 {
  margin-top: 10px;
  background-color: #066cb5;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: none;
  color: #000000;
  cursor: pointer;
}

.ProfileMenu-button2 {
  margin-top: 10px;
  background-color: #066cb5;
  padding: 9px 10px 9px 10px;
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: none;
  color: #000000;
  cursor: pointer;
  gap: 4px;
}
.ProfileMenu-text1 {
  color: #ffffff;
}
.ProfileMenu-text2 {
  color: #000000;
}
@media screen and (max-width: 600px) {
  .CountrySelect-div1 {
    flex-direction: column;
    align-items: flex-start;
  }
  .CountrySelect-div3 {
    gap: 5px;
  }
  .CountrySelect-div4 {
    width: 48%;
  }
  .Header-img1 {
    height: 35px;
    cursor: pointer;
  }
  .Header-button2 {
    width: 60px;
  }
  .Header-button3 {
    width: 70px;
  }
  .HeaderTabLogin-div1 {
    gap: 2px;
  }
  .ProfileMenu-menu2 {
    display: flex;
  }
  .HeadCat-div1 {
    justify-content: flex-start !important;
  }
}

.nav-item {
  // text-decoration: none;
  // font-size: 1rem;
  // transition: color 0.3s ease;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap; /* Prevent line breaks */
}

.search-bar {
  width: 390px;
}

.aauti_search_input {
  border: solid 1.5px #e4e4e4;
  /* width: calc(250px + 57*(100vw - 320px) / 1230); */
  /* border-radius: calc(8px + 2*(100vw - 320px) / 1230); */
  display: flex !important;
  align-items: center;
  background-color: #ffffff;
  height: 44px;
  border-radius: 10px;
  width: 400px;
  .aauti_search_input_text {
    padding-left: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
    flex: 1;
  }
  .aauti_search_button_icon {
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
  }
}

/* Responsive Design */

@media screen and (max-width: 600px) {
  .aauti_search_input {
    width: 100%;
  }
}
