// adhoc event card styles
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55");

.events-card {
  border: 1px solid #cac8c8;
  width: 32.6%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.app-cartCard {
  width: 100%;
  // margin-right: 50px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 15px;
  // min-height: 190px;
  background-color: rgb(255, 255, 255);
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.app-cartCard1 {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.card-head-dots {
  display: flex;
  padding: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #cac8c8;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.family-discount {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.family-discount.expand {
  height: calc-size(auto);
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.hide-expand {
  display: none;
}

.family-btn {
  background-color: transparent;
  padding: 0px !important;
  border: none;
  align-self: flex-start;
  display: flex;
  color: #2076e5;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

.events-card-data {
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.courseCard-Text1 {
  // font-family: "Helvetica Neue", sans-serif !important;
  line-height: 22px;
  text-align: left;
}

.sp-name-styles {
  // font-family: "Helvetica Neue", sans-serif !important;
  line-height: 22px;
  color: rgba(92, 91, 87, 1);
}
.sp-name-styles:hover {
  color: #3083EF;
}

.yes-button {
  border: none;
  outline: none;
  background-color: #3083ef;
  padding: 12px 25px 12px 25px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin: 8px;
  font-size: 20px;
  cursor: pointer;
}
.no-button {
  border: 1px solid #3083ef;
  outline: none;
  background-color: transparent;
  padding: 12px 25px 12px 25px;
  border-radius: 5px;
  color: #3083ef;
  font-weight: bold;
  margin: 8px;
  font-size: 20px;
  cursor: pointer;
}

.container-adhoc {
  display: flex;
  flex-direction: column;
  width: 93%;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 600px) {
  .events-card {
    width: 100%;
  }
}

.category-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 12px;
}


.span {
  display: inline-block
}

.textBox,
.textBox2,
.textBox3, .textBoxWeek {
  width: 100%;
  display: inline-block; /* important */
  white-space: nowrap;
  overflow: hidden;
  color: #5c5b57;


}

.capitalize-first {
  text-transform: lowercase;
}
.capitalize-first::first-letter {
  text-transform: uppercase;
}