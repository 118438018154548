.booking-sp-text {
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  overflow-wrap: break-word;
  color: rgba(30, 30, 30);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-width: 230px;
}
.booking-sp-div1 {
  // display: flex;
  height: 100%;
  // margin-top: 20px;
  width: 100%;
  justify-content: center;
  padding: 20px 30px !important;
}
@media (max-width: 900px) {
  .booking-sp-div1 {
    width: 100%;
    padding: 10px !important;
  }
}
.booking-sp-div2 {
  width: 100%;
  // display: flex;
  height: 90%;
  overflow: scroll;
}
@media (max-width: 900px) {
  .booking-sp-div2 {
    width: 100%;
    gap: 10px;
  }
}
.booking-search-div {
  width: 100%;
  margin-bottom: 15px;
  justify-content: flex-end;
  display: flex;
}
.booking-view-more {
  color: #3083ef !important;
  cursor: pointer !important;
  font-size: 12px !important;
  font-family: "poppins" !important;
  text-align: end !important;
}
.booking-infinity-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
  width: 100%;
}
.booking-infinity-div-ske {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
  width: 100%;
}
.booking-text-div-70 {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.booking-sp-map-div1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgb(222, 222, 222);
  min-width: 24%;
  padding: 10px;
  // height: 200px;
}
@media (max-width: 600px) {
  .booking-sp-map-div1 {
    min-width: 100%;
  }
}
@media (min-width: 901px) and (max-width: 1400px) {
  .booking-sp-map-div1 {
    min-width: 32%;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .booking-sp-map-div1 {
    min-width: 49%;
  }
}
.booking-sp-div-div1 {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
}
.booking-wrap-div {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 74vh;
  flex-wrap: wrap;
}
