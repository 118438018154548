.my-subscription-div1 {
  display: flex;
  flex-direction: column;
  width: 95%;
}
.my-subscription-subMap-data {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin: 20px 0px;
}
@media (max-width: 600px) {
  .my-subscription-subMap-data {
    margin: 10px 0px;
  }
}
.my-subscription-bottom-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 2px solid rgb(222, 222, 222);
}
@media (max-width: 600px) {
  .my-subscription-bottom-div {
    padding: 8px 0px;
  }
}
.my-subscription-sub-text-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 90%;
}
@media (max-width: 600px) {
  .my-subscription-sub-text-div {
    width: 80%;
    gap: 5px;
  }
}
.mybooking-maindiv1 {
  height: 100%;
  width: 100%;
  justify-content: center;
  padding: 20px 30px !important;
  display: flex;
}
@media (max-width: 600px) {
  .mybooking-maindiv1 {
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    margin: 10px 0px 10px 0px;
    justify-content: flex-start;
  }
}
.my-subscription-second-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
@media (max-width: 600px) {
  .my-subscription-second-div {
    display: none;
  }
}
.my-subscription-second-div2 {
  display: none;
}
@media (max-width: 600px) {
  .my-subscription-second-div2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    margin-top: 5px;
  }
}
.my-subscription-active-status {
  text-align: center;
  padding: 5px 10px;
  height: 30px;
  border-radius: 5px;
}
.my-subscription-first-chiled-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;
}
@media (max-width: 600px) {
  .my-subscription-first-chiled-div {
    width: 100%;
    gap: 5px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .my-subscription-first-chiled-div {
    width: 60%;
  }
}
@media (min-width: 901px) and (max-width: 1350px) {
  .my-subscription-first-chiled-div {
    width: 50%;
  }
}
.my-subscription-skeleton-div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  //   flex-direction: column;
  gap: 10px;
}
.my-subscription-order-type {
  text-decoration: underline;
  margin: 10px 0px 0px 0px;
  cursor: pointer;
  color: rgb(48, 131, 239);
}
@media (max-width: 600px) {
  .my-subscription-order-type {
    margin: 5px 0px 0px 0px;
  }
}
// .booking-sp-text {
//   font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
//   overflow-wrap: break-word;
//   color: rgba(30, 30, 30);
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   -webkit-line-clamp: 2;
//   text-overflow: ellipsis;
//   max-width: 215px;
// }
// @media (min-width: 601px) and (max-width: 900px) {
//   .orders-adhoc-session-div {
//     row-gap: 10px;
//   }
// }
// @media (max-width: 600px) {
//   .orders-start-image {
//     width: 14px;
//     height: 14px;
//     margin-right: 5px;
//   }
// }
.my-subscription-main-div1 {
  // display: flex;
  height: 100%;
  // margin-top: 20px;
  width: 100%;
  justify-content: center;
  padding: 20px;
}

.my-subscription-maindiv2 {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  gap: 15px;
}
// @media (max-width: 900px) {
//   .booking-sp-div2 {
//     width: 100%;
//     gap: 10px;
//   }
// }
// .booking-sp-map-div1 {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   border-radius: 8px;
//   border: 1px solid rgb(222, 222, 222);
//   width: 350px;
//   padding: 10px;
//   height: 190px;
// }
// @media (max-width: 600px) {
//   .booking-sp-map-div1 {
//     width: 100%;
//   }
// }
// @media (min-width: 601px) and (max-width: 900px) {
//   .booking-sp-map-div1 {
//     width: 49%;
//   }
// }
// .booking-sp-div-div1 {
//   display: flex;
//   flex-direction: row;
//   gap: 6px;
//   align-items: flex-start;
// }
