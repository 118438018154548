@import "../../themes/colors.scss";

.orderMainDiv {
  width: 99%;
  // height: 200px;
  padding: 2px 2px 2px 2px;
  // background-color: #3166ba;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.orders-start-image {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
@media (max-width: 600px) {
  .orders-start-image {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}
.order-user-list-div {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.order-gig-users-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.orders-course-level-div {
  display: flex;
  flex-direction: row;
}
.divider-div-orders1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  border-radius: 0px;
  background-color: transparent;
}
.avatar-orders-yellow {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(249, 239, 153, 1);
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.userlist-popu-main-card {
  display: flex;
  flex-direction: row;
  width: 49%;
  gap: 10px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid rgb(222, 222, 222);
  align-items: center;
}
@media (max-width: 600px) {
  .userlist-popu-main-card {
    width: 100%;
  }
}
.popup-user-cards-second-div-gig {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.popup-user-cards-second-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.popup-user-cards-div {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
  flex-direction: row;
}
.class-details-text-orders {
  cursor: pointer;
  color: $Aauti_theme_color !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
.users-lenth-text-orders {
  color: rgba(30, 30, 30, 0.9);
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
@media (max-width: 600px) {
  .popup-user-cards-div {
    margin-top: 5px;
  }
}
.orders-each-batches-map {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.orders-not-gig-div {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.orders-return-reviews-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.orders-view-more-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}
.orders-return-reviews-div-adhoc {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}
.orders-adhoc-purchased-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .orders-adhoc-purchased-div {
    display: none;
  }
}
.orders-adhoc-course-gig-level-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .orders-course-reviews-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
.divider-div-orders2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  border-radius: 6px;
  background-color: rgb(200, 220, 246);
}
.InputAdornment-orders {
  display: flex;
  justify-content: flex-start;
}
.IconButton-orders {
  padding: 0px;
  justify-content: flex-start;
  margin-left: 12px;
}
.grey-color-text {
  color: $GREY_TEXT !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
.red-color-text {
  color: $Aauti_Red !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
.waitingList-text-style {
  color: #fea300 !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
.order-item-watingList {
  color: #fea300 !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  margin-bottom: 3px;
}
.aauti-color-text {
  color: $Aauti_theme_color !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
.black-color-text {
  color: $Aauti_black_color !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
.marginRight-grey-text {
  color: $GREY_TEXT !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  margin-right: 5px;
}
.marginRight-black-text {
  color: $Aauti_black_color !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  margin-right: 5px;
}
.marginRight-aauti-icon {
  color: $Aauti_theme_color !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  margin-right: 5px;
}
.marginBottom-black-text {
  color: $Aauti_black_color !important;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  margin-bottom: 5px;
}
.eye-icon-style {
  color: $GREY_TEXT !important;
  margin-top: 2px;
  padding: 0px !important;
}
.group-image-orders {
  height: 20px;
  width: 20px;
}
.overview-container-gig-padding {
  border-radius: 7px;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.file-icon-style {
  color: $Aauti_theme_color !important;
  margin-left: 5px;
  padding: 0px !important;
}
.batchname-text-orders-summery {
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  overflow-wrap: break-word;
  color: rgba(30, 30, 30, 0.9);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-width: 90%;
}
@media (max-width: 600px) {
  .batchname-text-orders-summery {
    -webkit-line-clamp: 2;
    max-width: 90%;
  }
}
.batchname-text-orders-summery-batch {
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  overflow-wrap: break-word;
  color: rgba(30, 30, 30, 0.9);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-width: 100%;
}
.course-name-orders {
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-width: 98%;
  color: $Aauti_black_color;
}
.receipt-purchased-div {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  row-gap: 2px;
  flex-direction: row;
}
@media (max-width: 600px) {
  .receipt-purchased-div {
    column-gap: 0;
    row-gap: 2px;
    flex-direction: column;
  }
}
.levels-waiting-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 600px) {
  .levels-waiting-div {
    flex-direction: column;
    gap: 3px;
  }
}
.TOPnodata {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .TOPnodata {
    width: 100%;
    height: 100px;
  }
}
.total-orders-div {
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  align-items: center;
}
.orders-status-div {
  display: flex;
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);
  align-items: center;
  justify-content: space-around;
}
.tickStyle {
  width: 40;
  height: 40;
  border-radius: 50%;
  background-color: black;
  color: #ffffff;
}
.orders-overflow-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.orders-spinner-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
.orders-other-courses-div {
  width: 100%;
  flex-direction: column;
  row-gap: 15px;
  display: flex;
  margin-top: 15px;
}
@media (max-width: 600px) {
  .orders-other-courses-div {
    row-gap: 10px;
    margin-top: 10px;
  }
}
.orders-summery-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.order-item-type-div {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.order-item-type-pointer {
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ordrs-type-div2 {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.orders-adhoc-session-div {
  flex-direction: column;
  display: flex;
  width: 49%;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid rgb(218, 218, 218);
  row-gap: 15px;
}
@media (max-width: 600px) {
  .orders-adhoc-session-div {
    width: 100%;
    padding: 8px;
    row-gap: 5px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .orders-adhoc-session-div {
    row-gap: 10px;
  }
}
.orders-adhoc-gig-div {
  flex-direction: row;
  display: flex;
  width: 350px;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid rgb(218, 218, 218);
}
.gig-adhoc-price {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
@media (max-width: 600px) {
  .gig-adhoc-price {
    flex-direction: column;
    gap: 0px;
  }
}
.adhoc-classCourse-type {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: baseline;
}
.waiting-list-div {
  display: flex;
  flex-direction: row;
  gap: 3px;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .waiting-list-div {
    flex-direction: column;
  }
}
.orders-gig-div {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .orders-adhoc-gig-div {
    width: 100%;
    padding: 5px;
  }
}
.orders-max-width {
  max-width: 77%;
  flex-direction: row;
  display: flex;
  gap: 3px;
}
@media (min-width: 601px) and (max-width: 900px) {
  .orders-max-width {
    max-width: 75%;
  }
}
.orders-rec-div {
  flex-direction: row;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .OSskeletondiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
  }
}

.OSskeletondiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.cardDivStyleNew {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: row;
  border: 1px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  justify-content: space-between;
  background-color: #ffffff;
}

@media (max-width: 600px) {
  .cardDivStyleNew {
    height: 155px;
    margin-bottom: 10px;
  }
}

.imageStyleNew {
  width: 30%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
}

@media (max-width: 600px) {
  .imageStyleNew {
    padding: 4px;
  }
}

.cardDetailsDivStyleNew {
  width: 68%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

@media (max-width: 600px) {
  .cardDetailsDivStyleNew {
    width: 70%;
  }
}

.orderDetailsDivStyle2 {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px;
  border-style: solid;
  border-color: #dddddd;
  background-color: #ffffff;
}

.orderDetailsDiv2Style {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.ODotherCoursesDiv {
  width: 99%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  // padding: 2px;
}

.ODOtherCourseButtonDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // margin-bottom: 10px;
}

// .ODOtherCourseButtons{
//    width: 147px;
//    height: 62px;
//    padding: 2px;
//    border-radius: 10px;
//    border: 1px;
//    border-style: solid;
//    border-color: rgba(30, 30, 30, 0.35);
//    background-color: #ffffff;
//    box-sizing: border-box;
// }
.ODCourseCardDiv {
  width: 100%;
  margin-top: 10px;
}

.ODCourseCardStyle {
  width: 310px;
  height: 380px;
  padding: 2px;
  border: 1px;
  border-style: solid;
  border-color: #dddddd;
  margin-right: 15px;
  box-sizing: border-box;
  background-color: red;
}

.cardDiv {
  display: flex;
  flex-direction: row;
  //width:310px;
  //height: 320px;
}

.ODPriceDivStyle {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.ODWishlistStyle {
  // padding-horizontal:15;
  display: flex;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  height: fit-content;
  top: -24px;
  left: -90px;
  padding: 5px;
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px;
  border-style: solid;
  border-color: lightgrey;
}

.ODOfferTextStyle {
  display: flex;
  flex-direction: row;
  background-color: hsla(0, 1%, 41%, 0.565);
  height: fit-content;
  top: -170px;
  left: 15px;
  padding: 5px;
  position: absolute;
  width: 95px;
  //height: 30px;
  align-items: center;
  justify-content: center;
}

.reportDialog {
  padding: 40px !important;
}

.ReportDialogDiv {
  margin: 25px;
}

.ODRatingTextStyle {
  display: flex;
  flex-direction: row;
  background-color: hsla(0, 1%, 41%, 0.565);
  height: fit-content;
  top: -170px;
  left: 210px;
  padding: 5px;
  position: absolute;
  width: 60px;
  align-items: center;
  justify-content: center;
}

.ODProviderDetailsTextStyle {
  width: 100px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  top: -60px;
  left: 20px;
  padding: 5px;
  position: absolute;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}

.OSmaindiv {
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
}

.OScolumnStyle {
  flex-direction: column;
  width: 95%;
  margin-bottom: 10px;
  display: flex;
}

@media (max-width: 600px) {
  .OScolumnStyle {
    margin-top: 110px !important;
    width: 98%;
  }
}

.TOPnodata {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TSskeletondiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

@media (max-width: 600px) {
  .TOPnodata {
    width: 100%;
    height: 100px;
  }
}

@media (min-width: 601px) and (max-width: 1250px) {
  .OScolumnStyle {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .OSmaindiv {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-bottom: 10px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .OSmaindiv {
    padding: 0px;
  }
}

.OSchield1div {
  width: 64%;
  flex-flow: wrap;
  // align-items: center;
  display: flex;
  overflow: scroll;
  // height: 100vh;
  // justify-content: space-between;
  gap: 10px;
  padding: 1px;
}

.same-courses-by-spOS {
  width: 100%;
  margin: 10px 0px;
}

@media (max-width: 600px) {
  .same-courses-by-spOS {
    padding: 0px;
  }
}

@media (max-width: 600px) {
  .OSchield1div {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .OSchield1div {
    width: 50%;
  }
}

.OSchield2div {
  cursor: pointer;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  width: 49%;
  height: 180px;
  padding: 8px;
  border-radius: 10px;
  // border: 1px solid rgb(221, 221, 221);
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  align-items: center;
}

@media (min-width: 601px) and (max-width: 900px) {
  .OSchield2div {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .OSchield2div {
    width: 100%;
    height: 140px;
    padding: 5px;
  }
}

.OSchield1Img {
  width: 29%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0);
}

@media (max-width: 600px) {
  .ReportDialogDiv {
    margin: 15px 0px;
  }
}

.OSchield1div2 {
  width: 69%;
}

@media (max-width: 600px) {
  .ReportDialogDiv {
    margin: 15px 0px;
  }
}

.OSchield1Star {
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

@media (max-width: 600px) {
  .ReportDialogDiv {
    margin: 15px 0px;
  }
}

.OSlineDiv {
  width: 1px;
  background-color: #dddddd;
}

@media (max-width: 600px) {
  .OSlineDiv {
    width: 100%;
    background-color: #dddddd;
    height: 1px;
    margin: 20px 0px;
  }
}

.OSchield2maindiv {
  width: 33%;
  padding: 0px;
  padding-bottom: 5px;
}

@media (min-width: 601px) and (max-width: 900px) {
  .OSchield2maindiv {
    width: 49%;
  }
}

.RWDforSummery {
  display: flex;
}

@media (max-width: 600px) {
  .RWDforSummery {
    display: none;
  }
}

@media (max-width: 600px) {
  .OSchield2maindiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    padding-bottom: 5px;
  }
}

.OSchield2maindiv2 {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 600px) {
  .OSchield2maindiv2 {
    margin-bottom: 10px;
  }
}

.OSchield2maindiv3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

@media (max-width: 600px) {
  .OSchield2maindiv3 {
    width: 98%;
  }
}

.OCoverview-container {
  border-radius: 7px;
  border: 1px solid rgb(238, 231, 231);
  width: 100%;
  background-color: #ffffff;
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  .OCoverview-container {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

.OCcategory-box {
  border: 1px solid #cfcbcb;
  width: 120px;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid #3083ef;
    transform: scale(1.001);
    transition-duration: 0.5s;
  }
}

.TOPnodata {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .TOPnodata {
    width: 100%;
    height: 100px;
  }
}

@media (max-width: 600px) {
  .OCcategory-box {
    width: 88px;
  }
}

.OSnewDescText {
  color: #333333;
  margin-bottom: 8px;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.OSorderSumtext {
  color: black;
  margin: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.OScourseTypeNewdiv {
  align-items: center;
  flex-direction: row;
  display: flex;
  margin-bottom: 8px;
}

.OSratingNewdiv {
  align-items: center;
  flex-direction: row;
  display: flex;
  margin-top: 5px;
  width: 100%;
}

.OSnewflagDiv {
  align-items: center;
  flex-direction: row;
  display: flex;
  margin-bottom: 8px;
  width: 100%;
}

.OScourseTypeNewdiv2 {
  align-items: center;
  flex-direction: row;
  display: flex;
}

.OSnewImage {
  width: 95%;
  height: 90px;
  margin: 5px;
}

@media (max-width: 600px) {
  .OSnewImage {
    height: 65px;
    width: 90%;
  }
}

.OSnewCategoryName {
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 1px solid #dddddd;
  padding: 3px 0px;
}

.mayLikeText {
  color: #002058;
  margin-bottom: 25px;
  text-align: flex-start;
}

@media (max-width: 600px) {
  .mayLikeText {
    margin-bottom: 10px;
  }
}

.OCcategories-map {
  width: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}

.refund-main-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.refund-main-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radio-item-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  width: 95%;
  gap: 5px;
}

.gigPreview-div10 {
  margin-top: 15px;
}
.gigPreview-div10-margin {
  margin: 0px;
}
.gigPreview-div14 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 5px;
}
@media (max-width: 600px) {
  .gigPreview-div14 {
    align-items: flex-start;
  }
}
.gigPreview-text10 {
  color: #1e1e1e;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}

.gigPreview-text11 {
  color: #2076e5;
  cursor: pointer;
}

.gigPreview-div12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 10px;
}

.gigPreview-div13 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 300px;
}

//refund-request styles
.main-div-refund {
  display: flex;
  flex-direction: column;
  display: flex;
  row-gap: 10px;
  margin-left: -3px;
}
@media (max-width: 600px) {
  .main-div-refund {
    margin: 0px;
    row-gap: 8px;
  }
}
.refund-flex {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 6px;
  width: 100%;
  border: 1px solid rgb(242, 242, 246);
  border-radius: 6px;
}
.refund-img {
  height: 150px;
  width: 160px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .refund-img {
    height: 100%;
    width: 95px;
  }
}
.flex-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
@media (max-width: 600px) {
  .flex-info {
    gap: 5px;
  }
}
.fast-con {
  background-color: #3083ef;
  padding: 5px 15px;
  border-radius: 6px;
}

.flex-name {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-top: 4px;
}

.tabs-flex {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}
.tab-refund {
  width: fit-content;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid lightgray;
  padding: 8px 20px;
  cursor: pointer;
}

.active-class {
  border: 1px solid #2076e5;
  background-color: #3083ef1a;
  cursor: pointer;
}

.list-main {
  margin-top: 10px;
  width: 100%;
}

.refund-img2 {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}
@media (min-width: 601px) and (max-width: 900px) {
  .refund-img2 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
}
@media (max-width: 600px) {
  .refund-img2 {
    height: 50px;
    width: 50px;
  }
}
.refund-card {
  border: 1px solid #dedede;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 440px;
  align-items: center;
}
@media (min-width: 601px) and (max-width: 700px) {
  .refund-card {
    width: 340px;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
  .refund-card {
    width: 48%;
  }
}
@media (max-width: 600px) {
  .refund-card {
    width: 100%;
    gap: 5px;
    padding: 5px;
  }
}
.refund-right-div {
  display: flex;
  width: 80%;
  flex-direction: column;
  row-gap: 3px;
}
@media (max-width: 600px) {
  .refund-right-div {
    width: 83%;
    row-gap: 2px;
  }
}
@media (min-width: 601px) and (max-width: 700px) {
  .refund-right-div {
    width: 88%;
  }
}
.refund-main {
  // display: flex;
  flex-direction: row;
  // gap: 10px;
  height: 69vh;
  overflow: scroll;
  width: 100%;
  flex-wrap: wrap;
}
.orders-skeleton-div {
  width: 100%;
  flex-direction: column;
  display: flex;
  row-gap: 10px;
  margin-bottom: 10px;
}
.duplicate-count-div {
  display: flex;
  flex-direction: column;
}
.orders-skeleton-div1 {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.price-cont-New {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.orders-left-div2 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  // background: rgb(255, 255, 255);
  border: 1px solid #e0e0e0;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // gap: 10px;
  // padding: 10px;
  border-radius: 5px;
}
// missingRWD at 900 to 980
@media (max-width: 980px) {
  .orders-left-div2 {
    flex-direction: column;
    padding: 5px;
    min-height: 80vh;
  }
}
@media (max-width: 600px) {
  .orders-left-div2 {
    padding: 0px;
    border: 0px;
  }
}
.orders-left-div3 {
  width: 30%;
  background: rgb(255, 255, 255);
  // border-radius: 5px;
  // padding: 15px;
  max-width: 30%;
  min-width: 360px;
  border-right: 1px solid #e0e0e0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
@media (max-width: 980px) {
  .orders-left-div3 {
    width: 100%;
    padding: 0px;
    max-width: 100%;
    min-width: 100%;
    border-right: 0px;
    margin-bottom: 10px;
    border-bottom-left-radius: 0px;
  }
}
@media (max-width: 900px) {
  .orders-search-div {
    padding: 10px 0px !important;
  }
}
.orders-lenth-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px;
}
@media (max-width: 900px) {
  .orders-lenth-div {
    padding: 5px;
  }
}
.orders-search-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin-top: 8px;
  border-radius: 5px;
  padding: 10px;
}
.orders-scroll-div {
  overflow: hidden scroll;
  height: 70vh;
  padding: 0px 10px 10px 10px;
  row-gap: 5px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 900px) {
  .orders-scroll-div {
    padding: 0px;
  }
}
.orders-left-cont {
  display: flex;
  flex-direction: row;
  gap: 4px;
  // margin-bottom: 8px;
  padding: 8px;
  // background-color: #c8dcf6;
  cursor: pointer;
  align-items: center;
}
@media (min-width: 600px) and (max-width: 900px) {
  .orders-left-cont {
    padding: 10px;
  }
}

.orders-left-img {
  height: 45px;
  width: 45px;
  // min-height: 60px;
  // min-width: 60px;
  border-radius: 50%;
}

.orders-left-img-div2 {
  margin-left: 5px;
  width: 86%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}
@media (max-width: 600px) {
  .orders-left-img-div2 {
    margin-left: 5px;
    width: 75%;
  }
}
@media (min-width: 601px) and (max-width: 980px) {
  .orders-left-img-div2 {
    width: 90%;
  }
}
.orders-type-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.button-div-orders {
  min-width: 50px !important;
  font-size: 22px;
  color: #5c5b57;
  background-color: $BORDER_COLOR;
  padding: 9px 14px;
  margin-left: 8px;
}
.formcontol-orders {
  width: 95%;
  background-color: $BORDER_COLOR;
  border: 0px solid;
  border-radius: 4px;
  padding-right: 0px !important;
}
.orders-pack-id {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  // margin-top: 5px;
}
.orders-review-div {
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.orders-Failed-div {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.orders-icons-div {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-bottom: 15px;
}
@media (max-width: 600px) {
  .orders-icons-div {
    margin-bottom: 10px;
  }
}
.orders-mr-div {
  width: 100%;
}
.orders-mr-div-new {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}
@media (max-width: 600px) {
  .orders-mr-div-new {
    flex-direction: column;
    gap: 10px;
  }
}
.orders-100div {
  width: 100%;
}
.orders-order-summary {
  padding: 15px;
  width: 70%;
  height: 82vh;
  overflow: hidden;
  overflow-y: scroll;
  // margin-top: 10px;
}
@media (max-width: 980px) {
  .orders-order-summary {
    width: 100%;
    height: 100%;
    padding: 0px;
  }
}
.orders-summery-total {
  border: 1px solid #e9e9e9;
  padding: 15px;
  width: 55%;
  border-radius: 10px;
  height: 170px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 601px) and (max-width: 1050px) {
  .orders-summery-total {
    width: 49% !important;
  }
}
@media (max-width: 600px) {
  .orders-summery-total {
    width: 100% !important;
    padding: 10px !important;
    height: 140px !important;
  }
}
@media (max-width: 900px) {
  .orders-loading-ske {
    width: 100% !important;
  }
}
.orders-loading-ske {
  width: 30%;
}
.orders-ske-div2 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  margin-bottom: 4px;
}
.orders-ske-80 {
  width: 90%;
}
.orders-width-100 {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.rxCross-icon-div {
  cursor: pointer;
}
.orders-orderState-text {
  color: $Aauti_black_color;
  cursor: pointer;
  width: 30%;
  text-align: center;
  font-family: "Segoe UI", "San Francisco", "Helvetica Neue", Arial, sans-serif !important;
}
@media (max-width: 600px) {
  .orders-orderState-text {
    width: 50%;
  }
}
.order-summery-div-orders {
  width: 100%;
  display: flex;
  align-items: center;
}
.orders-ske-main-div {
  width: 69%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 900px) {
  .orders-ske-main-div {
    width: 100%;
    margin-top: 10px;
  }
}

.orderSkeleton-div1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
@media (max-width: 900px) {
  .orderSkeleton-div1 {
    flex-direction: column;
  }
}
.orderSkeleton-div2 {
  width: 30%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 900px) {
  .orderSkeleton-div2 {
    width: 100%;
  }
}
.order-summery-maindiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 15px;
}
@media (max-width: 900px) {
  .order-summery-maindiv {
    margin-bottom: 10px;
    padding-bottom: 10px;
    gap: 10px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .order-summery-maindiv {
    gap: 10px;
    margin-bottom: 15px;
  }
}
.order-summery-div2 {
  display: flex;
  flex-direction: column;
  width: 110px;
  row-gap: 5px;
}
@media (min-width: 601px) and (max-width: 900px) {
  .order-summery-div2 {
    width: 120px;
  }
}
@media (max-width: 600px) {
  .order-summery-div2 {
    width: 30%;
    min-width: 30%;
  }
}
.order-summery-image-div {
  height: 115px;
  width: 110px;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 600px) {
  .order-summery-image-div {
    width: 100%;
    min-width: 30%;
    height: 130px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .order-summery-image-div {
    height: 140px;
    width: 120px;
  }
}
.order-summery-gap-div {
  display: flex;
  align-items: center;
  // cursor: pointer;
  position: relative;
}
.order-summery-85 {
  width: 90%;
  margin-left: 8px;
  row-gap: 3px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .order-summery-85 {
    width: 100%;
    margin-left: 5px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .order-summery-85 {
    width: 85%;
  }
}
.orders-isrefund-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.order-summery-name-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.order-summery-columngap {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}
.order-summery-ratingdiv {
  width: 100%;
  flex-direction: row;
  column-gap: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.order-summery-orderdate-div {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .order-summery-orderdate-div {
    flex-direction: column;
    align-items: flex-start;
  }
}
.order-summery-orderdate-div2 {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .order-summery-orderdate-div2 {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
}
.order-summery-boton {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 5px;
}
.order-summery-orderdate-div2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
}
.order-summery-rowdiv {
  display: flex;
  flex-direction: row;
}

.google-ads-style {
  width: 44%;
  border-radius: 10px;
  height: 170px;
}
@media (min-width: 601px) and (max-width: 1050px) {
  .google-ads-style {
    width: 49%;
  }
}
@media (max-width: 600px) {
  .google-ads-style {
    width: 100%;
    height: 150px;
  }
}
.order-summery-total-amount-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}
.order-summery-right-image {
  height: 18px;
  width: 18px;
  margin-right: 3px;
}
// @media (min-width: 901px) and (max-width: 1050px) {
//   .DScalenderdivnew {
//     width: 333px !important;
//   }
// }
.refundscreen-gap1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
}
.refundscreen-gap6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .refundscreen-gap6 {
    margin-top: 3px;
  }
}
.refundscreen-img {
  height: 18px;
  width: 18px;
  z-index: 100;
}
.refundscreen-auto {
  width: auto;
}
.refundscreen-gap2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2px;
}
.refundscreen-90 {
  width: 90%;
}
.refundscreen-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  // border: 1px solid #f2f2f6;
  border: 1px solid lightgrey;
}
.refundscreen-radio {
  height: 18px;
  width: 18px;
  margin-top: 3px;
}

.refundscreen-credits-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.refundscreen-label2 {
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  // border: 1px solid #f2f2f6;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
}
.refundscreen-m15 {
  flex-direction: column;
  display: flex;
  row-gap: 10px;
}
@media (max-width: 600px) {
  .refundscreen-gap6 {
    row-gap: 5px;
  }
}
.returnScroll-orders {
  display: flex;
  flex-direction: row;
}

.refundscreen-submit {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}
