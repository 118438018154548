@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: auto;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("./fonts/HelveticaNeueBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueLight";
  src: url("./fonts/HelveticaNeueLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@mixin label-style {
  height: 18px;
  width: 140px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.homepage-wrapper {
  height: 100%;
  width: 100%;
}

#LEARN_NEW_PROFESSION {
  height: 94%;
  display: flex;
  margin-top: 10px;
  // margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1550 - 320)));
  position: relative;
  width: 100%;

  .learn_new_profession_frame {
    margin-left: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
    // margin-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
    width: calc(300px + (650 - 300) * ((100vw - 320px) / (1550 - 320)));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    .learn_new_profession_text {
      width: calc(300px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
      height: calc(150px + (270 - 150) * ((100vw - 320px) / (1550 - 320)));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;

      .learn_new_profession_label {
        width: calc(300px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
        height: calc(100px + (215 - 100) * ((100vw - 320px) / (1550 - 320)));
        font-size: calc(30px + (54 - 30) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 700;
        font-family: "HelveticaNeueBold", sans-serif;
        line-height: calc(40px + (66 - 40) * ((100vw - 320px) / (1550 - 320)));
        text-align: left;

        .learn_new_profession_span {
          color: #3083ef;
        }
      }

      .learn_new_profession_label2 {
        width: calc(250px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
        height: calc(20px + (28 - 20) * ((100vw - 320px) / (1550 - 320)));
        font-family: "HelveticaNeueLight", sans-serif;
        font-size: calc(16px + (23 - 16) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 600;
        // line-height: calc(12px + (16 - 12) * ((100vw - 320px) / (1550 - 320)));
        text-align: left;
        color: #5c5b57;
        margin-top: calc(8px + (13 - 8) * ((100vw - 320px) / (1550 - 320)));
      }

      .text_underline_container {
        position: absolute;
        top: calc(40px + (70 - 40) * ((100vw - 320px) / (1550 - 320)));
        left: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
      }
    }

    .search_all_courses_container {
      width: calc(300px + (625 - 300) * ((100vw - 320px) / (1550 - 320)));
      height: calc(40px + (60 - 40) * ((100vw - 320px) / (1550 - 320)));
      padding: calc(10px + (18 - 10) * ((100vw - 320px) / (1550 - 320)))
        calc(10px + (19 - 10) * ((100vw - 320px) / (1550 - 320)))
        calc(10px + (18 - 10) * ((100vw - 320px) / (1550 - 320)))
        calc(8px + (14 - 8) * ((100vw - 320px) / (1550 - 320)));
      gap: 0px;
      border-radius: 10px;
      border: solid 2px #e4e4e4;
      margin-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
      display: flex;
      align-items: center;

      .search_all_courses_input {
        flex: 1;
      }
    }

    .learn_new_profession_button_container {
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
      width: calc(300px + (516 - 300) * ((100vw - 320px) / (1550 - 320)));
      height: calc(40px + (60 - 40) * ((100vw - 320px) / (1550 - 320)));
      gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
      display: flex;

      .learn_new_profession_button {
        width: calc(150px + (240 - 150) * ((100vw - 320px) / (1550 - 320)));
        height: calc(40px + (55 - 40) * ((100vw - 320px) / (1550 - 320)));
        padding: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)))
          calc(15px + (30 - 15) * ((100vw - 320px) / (1550 - 320)));
        gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
        border-radius: 10px;
        background: #3083ef;
        text-transform: capitalize;
        font-family: "Lato", sans-serif;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 600;
        line-height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
        text-align: center;
      }

      .see_how_it_works_container {
        position: relative;
        display: flex;
        align-items: center;

        .see_how_it_works_label {
          font-family: "Lato", sans-serif;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
          font-weight: 600;
          line-height: calc(
            16px + (20 - 16) * ((100vw - 320px) / (1550 - 320))
          );
          text-align: center;
          margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
          // width: calc(100px + (136 - 100) * ((100vw - 320px) / (1550 - 320)));
          height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
        }

        .text_underline {
          position: absolute;
          top: calc(45px + (43 - 30) * ((100vw - 320px) / (1550 - 320)));
          right: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
        }
      }
    }
  }

  .learn_professions_right_image {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));

    img {
      height: calc(300px + (515 - 300) * ((100vw - 320px) / (1550 - 320)));
      margin-top: calc(20px + (45 - 20) * ((100vw - 320px) / (1550 - 320)));
    }
  }

  .learn_professions_right_image_top {
    display: none;
  }
}

@media screen and (max-width: 899px) {
  #LEARN_NEW_PROFESSION {
    flex-direction: column;
    align-items: center;
    height: max-content;
    padding-bottom: 40px;
    // margin-top: 80px;

    .learn_new_profession_frame {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .learn_new_profession_text {
        height: max-content;

        .learn_new_profession_label {
          height: max-content;
        }

        .learn_new_profession_label2 {
          width: 100%;
          height: max-content;
          margin-bottom: 10px;
        }
      }
    }

    .learn_professions_right_image {
      display: none;
    }

    .learn_professions_right_image_top {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

// #DOSE_OF_INSPIRATION {
//     width: 100vw;
//     height: calc(600px + (955 - 600) * ((100vw - 320px) / (1550 - 320)));
//     display: flex;
//     flex-direction: column;
#DOSE_OF_INSPIRATION {
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: max-content !important;
  padding-top: 20px;
  padding-bottom: 25px;

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
    border: solid 1.11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    content: " " !important;
    background: #000000;
  }

  .slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px !important;
    height: 10px !important;
    content: "" !important;
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: solid 1.11px !important;
    border-radius: 50% !important;
  }

  .text_underline.text_underline2 {
    bottom: 44px !important;
  }

  // .dose_of_inspiration_frame {
  //     width: calc(500px + (770 - 500) * ((100vw - 320px) / (1550 - 320)));
  //     height: calc(80px + (127 - 80) * ((100vw - 320px) / (1550 - 320)));
  //     margin-top: calc(30px + (56 - 30) * ((100vw - 320px) / (1550 - 320)));
  //     align-self: center;
  //     position: relative;
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     justify-content: center;
  .dose_of_inspiration_frame {
    width: calc(500px + 270 * (100vw - 360px) / 1230);
    align-self: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
    // margin-bottom: 15px;

    .dose_of_inspiration_label {
      // width: 400px;
      // width: 75%;
      max-width: calc(477px + (670 - 500) * ((100vw - 320px) / (1550 - 320)));
      // height: calc(60px + (87 - 60) * ((100vw - 320px) / (1550 - 320)));
      height: max-content;
      // max-height: calc(max-content + 20px);
      // padding-bottom: 30px;
      padding-bottom: 10px;
      font-family: "HelveticaNeueBold", sans-serif;
      font-size: calc(30px + (45 - 30) * ((100vw - 320px) / (1550 - 320)));
      font-weight: 700;
      line-height: calc(38px + (57 - 38) * ((100vw - 320px) / (1550 - 320)));
      text-align: center;
      position: relative;
      z-index: 1;
      text-transform: capitalize;
      padding-inline: 5px;
    }

    .block_background {
      position: absolute;
      height: calc(20px + (31 - 20) * ((100vw - 320px) / (1550 - 320)));
      width: calc(150px + (213 - 150) * ((100vw - 320px) / (1550 - 320)));
      background-color: #f9ef99;
      border-radius: 100px;
      top: calc(15px + (25 - 15) * ((100vw - 320px) / (1550 - 320)));
      right: calc(120px + (185 - 120) * ((100vw - 320px) / (1550 - 320)));
      z-index: 0;
    }

    .block_background_adjustment_courses {
      position: absolute;
      height: calc(20px + (31 - 20) * ((100vw - 320px) / (1550 - 320)));
      width: calc(120px + (184 - 120) * ((100vw - 320px) / (1550 - 320)));
      background-color: #f9ef99;
      border-radius: 100px;
      top: calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
      right: calc(
        80px + (122 - 80) * ((100vw - 320px) / (1550 - 320))
      ) !important;
      z-index: 0;
    }

    .text_underline {
      // position: absolute;
      // bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
  .what_to_expect_frame_content {
    width: calc(300px + (736 - 300) * ((100vw - 320px) / (1550 - 320)));
    // height: calc(40px + (52 - 40) * ((100vw - 320px) / (1550 - 320)));
    font-family: "Helvetica";
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1550 - 320)));
    font-weight: 600;
    line-height: calc(30px + (36 - 30) * ((100vw - 320px) / (1550 - 320)));
    text-align: center;
    color: #5c5b57;
    padding-top: 30px;
    height: max-content;
  }

  .filter_courses_container {
    margin-left: calc(30px + (100 - 30) * ((100vw - 320px) / (1550 - 320)));
    margin-right: calc(30px + (100 - 30) * ((100vw - 320px) / (1550 - 320)));
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    .filter_course_section {
      // height: calc(32px + (48 - 32) * ((100vw - 320px) / (1550 - 320)));
      // padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1550 - 320))) calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
      border-radius: 10px;
      border: solid 1px #e4e4e4;
      margin: calc(4px + (7 - 4) * ((100vw - 320px) / (1550 - 320)));
      font-family: "Lato", sans-serif;
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
      font-weight: 700;
      line-height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 7px;
      padding-bottom: 7px;
      height: max-content;
      cursor: pointer;
      text-transform: capitalize;
    }
  }

  .courses_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // width: calc(150px + (310 - 150) * ((100vw - 320px) / (1550 - 320)));
    height: 20px;
    margin-left: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
    margin-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
    margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1550 - 320)));

    .courses_container_label {
      font-family: "Lato", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      text-align: right;
      color: #5c5b57;
    }
  }

  // .simple_slider_container {
  //     height: 345px;
  //     top: 429px;
  //     left: 100px;
  //     gap: 32px;
  //
  //     // border: solid;
  //     margin-left: 100px;
  //     margin-right: 100px;
  //     margin-top: 15px;
  // }

  .simple_slider_container {
    // height: calc(300px + (345 - 300) * ((100vw - 320px) / (1550 - 320)));
    // margin-left: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    // margin-right: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    margin-left: calc(25px + 50 * (100vw - 320px) / 1230);
    margin-right: calc(25px + 50 * (100vw - 320px) / 1230);
    margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1550 - 320)));
    margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1550 - 320)));
  }

  .more_categories_list_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    .more_categories_list {
      //     width: 300px;
      //     height: 250px;
      // width: calc(250px + (300 - 250) * ((100vw - 320px) / (1550 - 320)));
      // height: calc(200px + (250 - 200) * ((100vw - 320px) / (1550 - 320)));
      width: max-content;
      margin-left: 25px;
      margin-right: 25px;
      height: max-content;

      .more_categories_list_ul {
        list-style-type: none;
        width: max-content;

        .more_categories_list_label_head {
          // font-family: "HelveticaNeueBold", sans-serif;
          // font-weight: 700;
          // margin-bottom: 15px;
          // margin-top: 15px;
          // font-size: 26px;
          // font-weight: 700;
          // line-height: 20px;
          font-family: "HelveticaNeueBold", sans-serif;
          font-weight: 700;
          margin-bottom: 15px;
          margin-top: 15px;
          font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1550 - 320)));
          line-height: calc(
            24px + (30 - 24) * ((100vw - 320px) / (1550 - 320))
          );
        }

        .text_blue {
          // color: #3083EF;
          // font-size: 20px;
          // font-family: "HelveticaNeueBold", sans-serif;
          // font-size: 21px;
          // font-weight: 700;
          // line-height: 20px;
          // margin-top: 15px;
          color: #3083ef;
          font-family: "HelveticaNeueBold", sans-serif;
          font-weight: 700;
          margin-top: 15px;
          font-size: calc(18px + (21 - 18) * ((100vw - 320px) / (1550 - 320)));
          line-height: calc(
            18px + (20 - 18) * ((100vw - 320px) / (1550 - 320))
          );
          cursor: pointer;
        }

        .more_categories_list_label_content:hover {
          color: #3083ef;
        }
        .more_categories_list_label_content {
          // font-family: "HelveticaNeueLight", sans-serif;
          // font-size: 20px;
          // font-weight: 400;
          // line-height: 20px;
          // text-align: left;
          // margin-bottom: 10px;

          font-family: "HelveticaNeueLight", sans-serif;
          font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1550 - 320)));
          font-weight: 400;
          line-height: 1.5;
          // calc(
          //   20px + (28 - 20) * ((100vw - 320px) / (1550 - 320))
          // );
          text-align: left;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .more_categories_list_label_content:hover {
          color: #3083ef;
        }
      }
    }
  }

  .view_more_container {
    // border: solid;
    // margin-top: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: 45px;

    // .view_more_button {
    //     width: 248px;
    //     height: 60px;
    //     padding: 10px 30px 10px 30px;
    //     gap: 10px;
    //     border-radius: 10px;
    //     background: #3083EF;
    //     font-family: "Lato", sans-serif;
    //     font-size: 18px;
    //     font-weight: 600;
    //     line-height: 20px;
    //     text-align: center;
    //     color: #ffffff;
    //     text-transform: capitalize;
    // }
    .view_more_button {
      width: calc(200px + (248 - 200) * ((100vw - 320px) / (1550 - 320)));
      height: calc(50px + (60 - 50) * ((100vw - 320px) / (1550 - 320)));
      padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)))
        calc(25px + (30 - 25) * ((100vw - 320px) / (1550 - 320)));
      gap: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
      border-radius: 10px;
      background: #3083ef;
      font-family: "Lato", sans-serif;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1550 - 320)));
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      text-transform: capitalize;
    }
  }
}

@media screen and (max-width: 500px) {
  .dose_of_inspiration_label {
    // width: 80% !important;
    max-width: 350px !important;
  }

  .more_categories_list_label_content {
    width: 100px;
    word-wrap: break-word;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

#FINDING_SLIDER {
  .flex_container {
    display: flex;
    flex-direction: row;
    flex-wrap: "wrap";
    width: 100%;
    gap: 10px;
    margin-top: 10px;
  }

  .slider_container_height {
    height: 420px !important;
  }

  .slick-list {
    width: 97%;
    margin-left: 1%;
  }
  .slider_container_box {
    height: 355px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%; /* or any size that fits all items */

    .content_width {
      width: 304px !important;
    }

    .slick-slide {
      padding: 5px;
    }

    .slide_content_box {
      width: 304px !important;
      height: 320px !important;
      border-radius: 16px;
      box-shadow: 0px 0px 8px 0px #1e1e1e29;
      cursor: pointer;

      .slider_image {
        // width: 304px;
        width: 100%;
        height: 194px;
        gap: 0px;
        border-radius: 16px 16px 0px 0px;
      }

      @media screen and (max-width: 1550px) {
        .slider_image {
          // width: 256px;

          width: 100%;
          height: 170px !important;
        }
      }

      .slider_content_box_wrapper {
        width: 304px;
        height: 126px;
        padding-left: 15px;
        padding-right: 15px;

        .slider_content_box_learners_box {
          height: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          margin-bottom: 10px;

          .slider_content_box_learners_text {
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
            color: #5c5b57;
          }

          @media screen and (max-width: 1550px) {
            .slider_content_box_learners_text {
              font-size: 13px;
            }
          }
        }

        .slider_content_box_title_text {
          font-family: "Lato", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;
          text-align: left;
          color: #5c5b57;
          width: 240px;
          height: 44px;
          text-transform: capitalize;
          // border: solid orange;
        }

        @media screen and (max-width: 1550px) {
          .slider_content_box_title_text {
            font-size: 16px;
          }
        }

        .slider_content_box_certified_box {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .slider_content_box_certified_text {
            font-family: "Lato", sans-serif;
            // width: 155px;
            height: 30px;
            border-radius: 7.5px;
            // margin-top: 7px;
            background: #3083ef29;
            font-size: 15px;
            font-weight: 400;
            line-height: 15px;
            text-align: left;
            color: #1e1e1e;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            padding-right: 10px;

            .slider_content_box_certified_icon {
              color: #3083ef;
            }
          }

          @media screen and (max-width: 1550px) {
            .slider_content_box_certified_text {
              font-size: 12px;
              // width: 130px;
              height: 25px;
            }
          }

          .slider_content_box_certified_follow {
            margin-left: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .slider_content_box_certified_follow_button {
              width: 60px;
              // height: 28px;
              height: 25px;
              // padding: 9px 19px 9px 20px;
              border-radius: 8px;
              text-transform: capitalize;
              margin-left: 7px;
              background-color: #3083ef;
              font-family: "HelveticaNeueLight", sans-serif;
              font-size: 12px;
              box-shadow: none;
            }
          }
        }
      }

      @media screen and (max-width: 1550px) {
        .slider_content_box_wrapper {
          width: 100% !important;
        }
      }
    }

    @media screen and (max-width: 1550px) {
      .slide_content_box {
        width: 265px !important;
        height: 301px !important;
      }
    }
    @media screen and (max-width: 600px) {
      .slide_content_box {
        width: 100% !important;
        height: 320px !important;
        .slider_image {
          height: 190px !important;
        }
      }
      .content_width {
        width: 304px !important;
      }
    }

    @media screen and (min-width: 1550px) {
      .slick-slide {
        width: 320px !important;
      }
    }
  }
}

#POOL_OF_COURSES {
  height: max-content;
  background: #f2f2f6;
  // border: solid;
  width: 100%;

  .pool_of_courses_top_frame {
    height: 86px;
    background: #ffffff;
    margin-top: -40px;
  }

  .pool_of_courses_info_frame {
    // height: 190px;
    // border-radius: 0px 0px 60px 60px;
    // margin-left: 100px;
    // margin-right: 100px;
    // border: solid;
    // background: #1E1E1E;
    // margin-top: -30px;
    // position: relative;
    // margin-bottom: 50px;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;
    // padding-left: 100px;
    // padding-right: 100px;
    // padding-top: 30px;
    // padding-bottom: 30px;
    // height: calc(190px + (250 - 190) * ((100vw - 320px) / (1550 - 320)));
    border-radius: 0px 0px
      calc(60px + (80 - 60) * ((100vw - 320px) / (1550 - 320)))
      calc(60px + (80 - 60) * ((100vw - 320px) / (1550 - 320)));
    margin-left: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    margin-right: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    border: solid 1px #e4e4e4;
    background: #1e1e1e;
    margin-top: calc(-56px + (0 - (-30)) * ((100vw - 320px) / (1550 - 320)));
    position: relative;
    margin-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1550 - 320)));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    padding-right: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
    padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
    height: max-content;

    // .pool_of_courses_info_content_container {
    //   border-right: solid #ffffff 2px;
    //   height: 100%;
    //   width: 100%;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;

    //   .pool_of_courses_info_label {
    //     font-family: "Lato", sans-serif;
    //     font-size: 18px;
    //     font-weight: 600;
    //     line-height: 20px;
    //     text-align: center;
    //     color: #ffffff;
    //   }

    //   p {
    //     font-family: "Lato", sans-serif;
    //     font-size: 18px;
    //     font-weight: 500;
    //     line-height: 20px;
    //     text-align: center;
    //     margin-top: 10px;
    //     color: #ffffff;
    //   }
    // }

    // .pool_of_courses_info_content_container_last {
    //   border-right: none;
    // }

    .pool_of_courses_info_fold_left {
      width: 36px;
      height: 36px;
      position: absolute;
      top: -3px;
      left: -33px;
    }

    @media screen and (max-width: 1550px) {
      .pool_of_courses_info_fold_left {
        top: -1px;
        left: -28px;
      }
    }

    .pool_of_courses_info_fold_right {
      width: 36px;
      height: 36px;
      position: absolute;
      top: -1px;
      right: -33px;
    }
  }
}

.pool_of_courses_info_content_container {
  color: #fff;
  width: 25%;
}

@media screen and (min-width: 900px) {
  .pool_of_courses_info_content_container {
    border-right: 1px solid #fff;
  }
}

.pool_of_courses_info_content_container:last-child {
  border-right: none;
}

.pool_of_courses_info_label {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.pool_of_courses_info {
  text-align: center;
}

@media screen and (min-width: 550px) and (max-width: 899px) {
  .pool_of_courses_info_content_container {
    width: 50%;
    margin-bottom: 30px;
  }

  .pool_of_courses_info_content_container:nth-child(odd) {
    border-right: 1px solid #fff;
  }
}

@media screen and (max-width: 549px) {
  .pool_of_courses_info_content_container {
    width: 50%;
    margin-bottom: 10px;
    // border-bottom: 1px solid #fff;
    // padding-bottom: 5px;
    // border-right: 1px solid #ffffff;
    font-size: 12px;
    // padding-right: 5px;
  }

  .pool_of_courses_info_content_container:nth-child(odd) {
    border-right: 1px solid #fff;
  }

  .pool_of_courses_info_content_container:last-child {
    border-right: none;
  }

  @media screen and (max-width: 375px) {
    .pool_of_courses_info_content_container {
      font-size: 10px;
    }

    .pool_of_courses_info_content_container:last-child {
      border-right: none;
    }

    #POOL_OF_COURSES .pool_of_courses_info_frame {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

#WHAT_TO_EXPECT {
  // height: calc(500px + (926 - 500) * ((100vw - 320px) / (1550 - 320)));
  // background: #ffffff;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // width: 100%;

  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: max-content;
  margin-top: 40px;
  margin-bottom: 40px;

  // .what_to_expect_frame_text_container {
  //     width: 770px;
  //     height: 163px;
  //     top: 56px;
  //     left: 371px;
  //     gap: 40px;
  //
  //     // border: solid;
  //     margin-top: 56px;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  .what_to_expect_frame_text_container {
    width: calc(300px + (770 - 300) * ((100vw - 320px) / (1550 - 320)));
    // height: calc(120px + (163 - 120) * ((100vw - 320px) / (1550 - 320)));
    height: max-content;
    gap: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
    margin-top: calc(30px + (56 - 30) * ((100vw - 320px) / (1550 - 320)));
    display: flex;
    flex-direction: column;
    align-items: center;

    // .what_to_expect_frame_header {
    //     width: 770px;
    //     height: 71px;
    //     gap: 40px;
    //
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: flex-start;

    // .what_to_expect_frame_header_label {
    //     font-family: "HelveticaNeueBold", sans-serif;
    //     font-size: 40px;
    //     font-weight: 700;
    //     line-height: 70px;
    //     text-align: center;
    //     width: 770px;
    //     height: 31px;
    //     margin-top: -15px;
    //     position: relative;
    //     z-index: 1;
    // }
    .what_to_expect_frame_header {
      width: calc(300px + (770 - 300) * ((100vw - 320px) / (1550 - 320)));
      height: calc(50px + (71 - 50) * ((100vw - 320px) / (1550 - 320)));
      gap: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .what_to_expect_frame_header_label {
        font-family: "HelveticaNeueBold", sans-serif;
        font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 700;
        line-height: calc(50px + (70 - 50) * ((100vw - 320px) / (1550 - 320)));
        text-align: center;
        width: calc(300px + (770 - 300) * ((100vw - 320px) / (1550 - 320)));
        margin-top: calc(
          -10px + (-15 - (-10)) * ((100vw - 320px) / (1550 - 320))
        );
        position: relative;
        z-index: 1;
        height: max-content;
      }

      .block_background_adjustment_expect {
        // height: 31px;
        // width: 144px;
        // position: absolute;
        // top: 20px;
        // right: 349px;
        // border-radius: 100px;
        // background: #F9EF99;
        // z-index: 0;
        height: 31px;
        width: 144px;
        position: absolute;
        border-radius: 100px;
        background: #f9ef99;
        z-index: 0;
      }

      .text_underline {
        position: absolute;
        bottom: -4px;
      }
    }

    // .what_to_expect_frame_content {
    //     width: 736px;
    //     height: 52px;
    //     // border: solid;
    //     font-family: "HelveticaNeueLight", sans-serif;
    //     font-size: 22px;
    //     font-weight: 600;
    //     line-height: 36px;
    //     text-align: center;
    //     color: #5C5B57;

    // }
    .what_to_expect_frame_content {
      width: calc(300px + (736 - 300) * ((100vw - 320px) / (1550 - 320)));
      // height: calc(40px + (52 - 40) * ((100vw - 320px) / (1550 - 320)));
      font-family: "HelveticaNeueLight", sans-serif;
      font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1550 - 320)));
      font-weight: 600;
      line-height: calc(30px + (36 - 30) * ((100vw - 320px) / (1550 - 320)));
      text-align: center;
      color: #5c5b57;
      height: max-content;
    }
  }

  .what_to_expect_frame_card_container {
    // border: solid;
    width: 990px;
    height: 610px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    .what_to_expect_frame_card {
      // border: solid;
      width: 310px;
      height: 290px;
      border-radius: 10px;
      box-shadow: 0px 7px 25px 0px #0000001a;
      margin: 7px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 30px;
    }

    .what_to_expect_frame_card_label {
      width: 247.16px;
      // height: 64px;
      gap: 0px;

      font-family: "HelveticaNeueBold", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      margin-top: 7px;
      margin-bottom: 7px;
    }

    .what_to_expect_frame_card_description {
      font-family: HelveticaNeueLight;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #5c5c57;
    }
  }
}

#AAUTI_BOT {
  // height: calc(400px + (580 - 400) * ((100vw - 320px) / (1550 - 320)));
  background: #f2f2f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
  width: 100%;
  height: max-content;

  // .aauti_bot_text_container {
  //     // border: solid;
  //     width: 770px;
  //     height: 100%;
  .aauti_bot_text_container {
    width: calc(300px + (770 - 300) * ((100vw - 320px) / (1550 - 320)));
    height: 100%;

    // .aauti_bot_text_frame {
    //     width: 770px;
    //     height: 270px;
    .aauti_bot_text_frame {
      width: calc(320px + (770 - 320) * ((100vw - 320px) / (1550 - 320)));
      // height: calc(200px + (270 - 200) * ((100vw - 320px) / (1550 - 320)));
      height: max-content;

      // border: solid;

      // .aauti_bot_text {
      //     width: 770px;
      //     font-family: "HelveticaNeueBold", sans-serif;
      //     font-size: 44px;
      //     font-weight: 700;
      //     line-height: 70px;
      //     text-align: left;
      //     position: relative;
      .aauti_bot_text {
        width: calc(300px + (770 - 300) * ((100vw - 320px) / (1550 - 320)));
        font-family: "HelveticaNeueBold", sans-serif;
        font-size: calc(24px + (44 - 24) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 700;
        line-height: calc(40px + (70 - 40) * ((100vw - 320px) / (1550 - 320)));
        text-align: left;
        position: relative;

        .aauti_bot_text {
          color: #3083ef;
          position: relative;

          .text_underline {
            position: absolute;
            bottom: -30px;
          }
        }
      }

      // .aauti_bot_text_description {
      //     color: #5C5B57;
      //     font-family: "HelveticaNeueLight", sans-serif;
      //     font-size: 24px;
      //     font-weight: 400;
      //     line-height: 36px;
      //     text-align: left;
      //     margin-top: 20px;

      .aauti_bot_text_description {
        color: #5c5b57;
        font-family: "HelveticaNeueLight", sans-serif;
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 400;
        line-height: calc(28px + (36 - 28) * ((100vw - 320px) / (1550 - 320)));
        text-align: left;
        margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));

        .aauti_bot_text_strong {
          font-family: "HelveticaNeueBold", sans-serif;
        }
      }
    }

    .aauti_bot_button_container {
      width: 149px;
      height: 60px;
      top: 420px;
      left: 622px;
      margin: 10px;

      margin-left: 0px;
      margin-top: 17px;

      .aauti_bot_button {
        // width: 149px;
        // height: 60px;
        // padding: 10px 30px 10px 30px;
        width: calc(136px + 48 * (100vw - 320px) / 1230);
        height: calc(50px + (60 - 50) * ((100vw - 320px) / (1550 - 320)));
        padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)))
          calc(25px + (30 - 25) * ((100vw - 320px) / (1550 - 320)));
        font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1550 - 320)));
        line-height: calc(30px + (36 - 30) * ((100vw - 320px) / (1550 - 320)));
        gap: 10px;
        border-radius: 10px;

        background: #3083ef;
        text-transform: capitalize;
        font-family: "HelveticaLight", sans-serif;
        // font-size: 17px;
        font-weight: 400;
        // line-height: 36px;
        text-align: center;
      }
    }
  }
}

.what_others_talk_about_us {
  height: 650px !important;
  background-color: #f2f2f6;

  // .view_more_container {
  //   // margin-top: -35px !important;
  // }
}

.more_categories_explore {
  height: 580px !important;
}

#SLICK_PREV_ARROW {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 40%;
  left: -35px;
  border-radius: 50%;
  background: #626262;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .slick_prev {
    color: #ffffff;
    height: 25px;
    z-index: 1;
  }
}

#SLICK_NEXT_ARROW {
  // position: absolute;
  // height: 60px;
  // width: 60px;
  // top: 40%;
  // right: -53px;
  // border-radius: 50%;
  // background: #626262;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  // z-index: 1;
  position: absolute;
  height: 50px;
  width: 50px;
  top: 40%;
  right: -30px;
  border-radius: 50%;
  background: #626262;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .slick_next {
    color: #ffffff;
    height: 25px;
    z-index: 1;
  }
}

@media screen and (max-width: 600px) {
  #SLICK_NEXT_ARROW,
  #SLICK_PREV_ARROW {
    display: none;
  }

  .simple_slider_container {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}

#WHAT_OTHERS_TALK {
  padding: 10px;
  .flex_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
  }
  .slide_content_box {
    height: 198px;
    // margin-bottom: 50px;
    width: 312px !important;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px #1e1e1e40;
    background-color: #ffffff;
    cursor: pointer;
    padding: 16px !important;

    .slider_content_box_profile {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .slider_image {
        height: 52px;
        width: 52px;
        border-radius: 100%;
      }

      @media screen and (max-width: 1550px) {
        .slider_image {
          height: 40px;
          width: 40px;
        }
      }

      .slider_content_box_profile_wrapper {
        // border: solid;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 10px;

        .slider_content_box_profile_name {
          font-family: "HelveticaNeueLight", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 17.91px;
          text-align: left;
          margin-bottom: 5px;
        }

        .slider_content_box_profile_title {
          font-family: "HelveticaNeueLight", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 13.83px;
          text-align: left;
          width: max-content;
        }
      }
    }

    .slider_content_box_text {
      // padding:16px
      margin-bottom: 10px !important;
      font-family: "HelveticaNeueLight", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      width: 250px !important;
      height: 72px;
      text-transform: capitalize;
    }

    .css-15befwp-MuiRating-root {
      color: #3083ef !important;
    }

    .css-1c99szj-MuiRating-icon {
      color: #3083ef !important;
    }
  }

  .slick-slide.slick-active {
    width: 360px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .slick-list {
    height: 215px !important;
  }
}

#ALL_COURSES_BANNER {
  height: max-content;
  position: fixed;
  top: 0px;
  z-index: 500 !important;
  left: 0;
  right: 0;
  background: #ffffff;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 85px;
  padding-bottom: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  .all_courses_banner_frame {
    height: max-content;
    display: flex;
    flex-direction: row;

    .all_courses_banner_explore_courses {
      width: 200px;
      height: 384px;
      margin-top: 30px;
      // border: solid;
      margin-right: 100px;

      .all_courses_banner_explore_courses_ul {
        list-style-type: none;

        .all_courses_banner_explore_courses_label {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: #5c5b57;
          margin-bottom: 10px;
        }

        .all_courses_banner_explore_courses_sub_label {
          // width: 112px;
          // height: 20px;
          gap: 10px;

          font-family: "Lato", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
          margin-bottom: 5px;
          cursor: pointer;
          user-select: none;
        }

        .all_courses_banner_explore_courses_label_2 {
          font-family: "Lato", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: #5c5b57;
          margin-bottom: 10px;
          cursor: pointer;
          user-select: none;
        }
        .all_courses_banner_explore_courses_label_2:hover {
          color: #3083ef;
        }
      }
    }
  }
}

#ALL_COURSES_BANNER_MENU {
  height: 600px;
  position: fixed;
  top: 80px;
  z-index: 3;
  left: 0;
  right: 0;
  background: #ffffff;
  padding-left: 100px;
  padding-right: 100px;

  .all_courses_banner_frame {
    // border: solid;
    height: 600px;
    display: flex;
    flex-direction: row;

    .all_courses_banner_explore_courses {
      width: 200px;
      height: 384px;
      margin-top: 30px;
      // border: solid;
      // margin-right: 100px;

      .all_courses_banner_explore_courses_ul {
        list-style-type: none;

        .all_courses_banner_explore_courses_label {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: #5c5b57;
          margin-bottom: 10px;
        }

        .all_courses_banner_explore_courses_sub_label {
          // width: 112px;
          // height: 20px;
          gap: 10px;

          font-family: "Lato", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
          margin-bottom: 5px;
        }

        .all_courses_banner_explore_courses_label_2 {
          font-family: "Lato", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: #5c5b57;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (max-width: 899px) {
  #DOSE_OF_INSPIRATION {
    .filter_courses_container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;
      flex-wrap: nowrap;

      p.filter_course_section_label {
        width: max-content;
      }
    }

    .more_categories_list {
      width: 29% !important;
      margin-left: 25px;
      margin-right: 25px;
      height: max-content;
    }
  }

  // .what_others_talk_about_us {
  //   .view_more_container {
  //     // margin-top: -80px !important;
  //   }
  // }

  #WHAT_OTHERS_TALK {
    // display: flex;
    // flex-direction: row;
    // overflow: auto;
    // justify-content: flex-start;
    // display: flex;
    // flex-direction: row;
    // overflow: auto;
    // justify-content: flex-start;

    .slider_container_box {
      display: flex;
      margin-top: 25px;

      .slide_content_box {
        width: 330px !important;
        height: 165px !important;
        padding: 10px !important;
        margin-right: 15px !important;

        .slider_content_box_text {
          height: 50px;
        }
      }
    }
  }

  #WHAT_TO_EXPECT {
    .what_to_expect_frame_card_container {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow: auto;
      padding-top: 35px;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .what_to_expect_frame_text_container {
      .what_to_expect_frame_content {
        height: max-content;
        text-align: center;
        color: #5c5b57;
        width: 100%;
      }

      .what_to_expect_frame_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .what_to_expect_frame_header_label {
          width: 100%;
          line-height: normal;
          padding-bottom: 25px;
        }
      }
    }
  }

  #AAUTI_BOT {
    background: #f2f2f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc(50px + 50 * (100vw - 320px) / 1230);
    width: 100%;
    height: max-content;
  }

  #POOL_OF_COURSES {
    .pool_of_courses_info_frame {
      flex-wrap: wrap;
      padding: 20px;
      margin: 0px 40px 10px 40px;

      .pool_of_courses_info_fold_left {
        top: 0px;
        left: -30px;
      }

      .pool_of_courses_info_fold_right {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 0px;
        right: -35px;
      }

      // .pool_of_courses_info_content_container {
      //   border-right: none;
      //   // border-bottom: 1.11px solid #ffffff;
      // }

      // .pool_of_courses_info_content_container_last {
      //   border-bottom: none;
      // }
    }
  }
}

#FINDING_SLIDER_COURSE {
  .slider_container_box {
    .slick-dots {
      position: relative !important;
      display: block !important;
      width: 100% !important;
      list-style: none;
      text-align: center;
    }
  }

  .slick-initialized {
    .slick-list {
      .slick-slide {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // width: 285px !important;
      }
    }

    .slick-dots {
      position: relative !important;
      bottom: -10px !important;
    }
  }

  /* Base styles */
  .slide_content_box {
    width: 95% !important;
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    margin: 8px;
    position: relative;
    background-color: #fff;
    cursor: pointer;
  }

  .slider_image {
    width: 100%;
    height: 160px !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .slider_content_box_wrapper {
    padding: 16px;
  }

  .slider_content_box_title_text {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .slider_content_box_details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .slider_content_box_contact,
  .slider_content_box_price {
    font-size: 16px;
  }

  .slider_content_box_certified_box {
    background-color: #f9ef99;
    padding: 3px;
    border-radius: 10px;
    text-align: center;
    width: 56%;
    margin-top: 10px;
    color: #000 !important;
    font-weight: 600;
  }

  .slider_content_box_certified_text {
    font-size: 14px;
    color: #000000;
  }

  /* Responsive styles */
  @media (max-width: 699px) {
    .slide_content_box {
      padding: 12px;
    }

    .slider_content_box_title_text {
      font-size: 16px;
    }

    .slider_content_box_details {
      flex-direction: column;
    }

    .slider_content_box_contact,
    .slider_content_box_price {
      font-size: 14px;
    }
  }

  @media (min-width: 700px) and (max-width: 939px) {
    .slide_content_box {
      padding: 14px;
    }

    .slider_content_box_title_text {
      font-size: 17px;
      height: 44px;
    }

    .slider_content_box_contact,
    .slider_content_box_price {
      font-size: 15px;
    }
  }

  @media (min-width: 940px) and (max-width: 1325px) {
    .slide_content_box {
      padding: 16px;
    }

    .slider_content_box_title_text {
      font-size: 18px;
      height: 44px;
    }

    .slider_content_box_contact,
    .slider_content_box_price {
      font-size: 16px;
    }
  }

  @media (min-width: 1326px) {
    .slide_content_box {
      padding: 18px;
    }

    .slider_content_box_title_text {
      font-size: 19px;
    }

    .slider_content_box_contact,
    .slider_content_box_price {
      font-size: 17px;
    }
  }
}

@media (max-width: 1336px) and (min-width: 1210px) {
  #POOL_OF_COURSES .pool_of_courses_info_frame .pool_of_courses_info_fold_left {
    top: 2px;
    left: -30px;
  }

  #POOL_OF_COURSES
    .pool_of_courses_info_frame
    .pool_of_courses_info_fold_right {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 2px;
    right: -36px;
  }
}

@media (max-width: 1210px) and (min-width: 320px) {
  #POOL_OF_COURSES .pool_of_courses_info_frame {
    margin-top: -30px;
  }
}

@media screen and (max-width: 700px) {
  #LEARN_NEW_PROFESSION
    .learn_new_profession_frame
    .learn_new_profession_text
    .learn_new_profession_label {
    height: max-content;
    font-size: 30px;
  }

  #LEARN_NEW_PROFESSION
    .learn_new_profession_frame
    .learn_new_profession_text
    .text_underline_container {
    position: absolute;
    top: 37px;
    left: 0px;
  }

  #LEARN_NEW_PROFESSION
    .learn_new_profession_frame
    .learn_new_profession_button_container
    .learn_new_profession_button {
    width: 110px;
    font-size: 13px;
  }
}

@media screen and (max-width: 500px) {
  #DOSE_OF_INSPIRATION .dose_of_inspiration_frame .dose_of_inspiration_label {
    font-size: 24px;
  }

  #DOSE_OF_INSPIRATION .dose_of_inspiration_frame .block_background {
    display: none;
  }

  span.dose_of_inspiration_span {
    background: #f9ef99;
    border-radius: 20px;
  }

  #DOSE_OF_INSPIRATION .filter_courses_container {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  #WHAT_TO_EXPECT
    .what_to_expect_frame_text_container
    .what_to_expect_frame_header
    .block_background_adjustment_expect {
    display: none;
  }

  #AAUTI_BOT {
    svg {
      height: 124px;
      width: 124px;
    }
  }

  #AAUTI_BOT .aauti_bot_text_container .aauti_bot_text_frame .aauti_bot_text {
    text-align: center;
    font-size: 24px;
  }

  #AAUTI_BOT
    .aauti_bot_text_container
    .aauti_bot_text_frame
    .aauti_bot_text
    .aauti_bot_text
    .text_underline {
    position: absolute;
    bottom: -40px;
    left: -11%;
  }

  #AAUTI_BOT
    .aauti_bot_text_container
    .aauti_bot_text_frame
    .aauti_bot_text_description {
    text-align: center;
  }

  #AAUTI_BOT .aauti_bot_text_container .aauti_bot_button_container {
    margin: 10px;
    margin-left: 0px;
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  #DOSE_OF_INSPIRATION .view_more_container {
    height: 60px;
    margin-top: 0px;

    .view_more_courses_button {
      margin-top: 35px;
    }
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 600px) {
  #AAUTI_BOT
    .aauti_bot_text_container
    .aauti_bot_button_container
    .aauti_bot_button {
    width: fit-content;
  }
}
