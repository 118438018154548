.preview_wrapper {
  height:inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.no_preview_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.full_dimensions{
  height:100%;
  width:100%;
}

.video{
  height:auto;
  width: 100%;
}


.no_preview_wrapper.full_dimensions.audio {
  width: 90%;
  padding:12px
}