@import "../../../../themes/colors.scss";

.gigPreview-div16 {
  padding: 10px 15px;
  border: 2px solid #f2f2f6;
  border-radius: 6px;
  width: 100%;
}

.gigPreview-div17 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gigPreview-div18 {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.gigPreview-text14 {
  width: 50%;
}
.gigPreview-div19 {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
}
.gigLevels-text1 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 50%;
  flex-wrap: wrap;
}

.gigPreview-div20 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
}

.advertisement-container-gigPreview {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid #f2f2f6;
  border-radius: 6px;
  margin-top: 10px;
  height: 320px;
  min-height: 320px;
}

.discounted-price-gig-right {
  text-decoration: line-through;
  margin-right: 10px;
  color: $HEADING_COLOR;
  font-weight: 400;
}
.checked-user-gig-right {


  position: absolute;
  top: 0px;
  left: 0pc;
  width: 100%;
  height: 100%;
  background-color: #222222;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
