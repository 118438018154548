

.chat-group-image-delete-icon {
  color: red($color: #000000);
  position: relative;
  bottom: 70px;
  left: 75px;
  cursor: pointer;
}

.name-description-form-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.upload-label {
    opacity: 0;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #eaf1ffd6;
    z-index: 2;
    border-radius: 50px;
}

.chat-group-camera-icon{
    align-self: center;
    height:40px;
    width: 40px;
    position: absolute;
}