.chat-room-info-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: 100%;
  row-gap: 10px;
  padding: 0px 16px;

  .overflow-text {
    font-family: HelveticaNeueBold, Poppins;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .chat-room-info-header {
    display: flex;
    width: inherit;
    justify-content: flex-start;
    column-gap: 8px;
    padding-top: 8px;
    .texts-wrapper {
      display: flex;
      flex-direction: column;
      width: inherit;
      overflow-x: scroll;

      .room-name {
        width: inherit;
        font-size: 16px;
        line-height: 20px;
        color:rgba(30, 30, 30, 1);
      }

      .room-name.clamp-text-2{
        -webkit-line-clamp: 2;

      }
      .room-name.clamp-text-3{
        -webkit-line-clamp: 3;
      }

      .room-description { 
        font-family: Arial, "HelveticaNeueLight", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-top: 10px;
        -webkit-line-clamp: 3;
      }
    }
    .close-edit-icon-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box;
      .close-icon{
        height:26px;
        width: 26px;
        align-items: center;
        padding: 2px;
        right: inherit;
        top:inherit;
      }
      .chat-room-edit-text{
        color: #3083EF;
        text-decoration: underline;
        cursor: pointer;
        text-align: right;
      }
    }
  }

  .chat-room-info-access-card-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    height:60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(242, 242, 246, 1);
    border-radius: 10px;
    padding: 10px;

    .p-dropdown {
      border-radius: 10px;
      border-color:transparent;

      .p-inputtext{
        font-size: 14px;
        font-weight: 400;
        font-family: Poppins;
      }
    }
    .chat-room-access-title{
      margin-bottom:10px;
      color:'rgba(92, 91, 87, 1)';
      vertical-align: middle;
      padding-top:8px;
      padding-left:6px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .chat-room-info-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    overflow-y: scroll;

   
    .name-with-tag-wrapper{
      display: flex;
      column-gap: 2px;
      font-size: 14px;
      align-items: center;
      .username{
        font-weight: 600px;
        color:rgba(30, 30, 30, 1);
      }

      .username-tag{
        font-size: 12px;
        font-weight: 500px;
        color:rgba(92, 91, 87, 1)
      }
    }

  }

  .chat-room-info-footer {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: "center";
  }

  .chat-room-info-action-btn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    text-transform: none;
    color: red;
  }
}
