/* General styles */

@import "../../../themes/colors.scss";
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header-subHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  background-color: $white_color;
  position: fixed;
  top: 60.3px;
  z-index: 202;
  // margin-top: 13px;
  height: 50px;
  box-shadow: 0px 0px 4px 0px #1e1e1e1f;
}

/* Navigation styles */
.nav {
  // flex-grow: 1;
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-icon {
  color: $balck_color;
  height: 20px;
  width: 20px;
}
.rotate {
  rotate: 270deg;
}

.nav-item {
  // text-decoration: none;
  // font-size: 1rem;
  // transition: color 0.3s ease;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap; /* Prevent line breaks */
}

.search-bar {
  width: 390px;
}

.aauti_search_input {
  border: solid 1.5px #e4e4e4;
  /* width: calc(250px + 57*(100vw - 320px) / 1230); */
  /* border-radius: calc(8px + 2*(100vw - 320px) / 1230); */
  display: flex !important;
  align-items: center;
  background-color: #ffffff;
  height: 44px;
  border-radius: 10px;
  width: 400px;
  .aauti_search_input_text {
    padding-left: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
    flex: 1;
  }
  .aauti_search_button_icon {
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
  }
}

/* Responsive Design */

.instituteSubHeader-div2 {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.instituteSubHeader-div3 {
  display: flex;
  flex-direction: row;
  width: 15%;
  max-width: fit-content;
  align-items: center;
  cursor: pointer;
  gap: 36px;
}

@media screen and (max-width: 600px) {
  .aauti_search_input {
    width: 100%;
  }
  .instituteSubHeader-div3 {
    width: 10%;
  }
  .nav {
    width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .instituteSubHeader-div3 {
    width: 20%;
  }
}
@media screen and (max-width: 1024px) {
  .header-subHeader {
    padding: 2px 5px;
  }
  .instituteSubHeader-div2 {
    width: 98%;
  }
  .instituteSubHeader-div3 {
    gap: 10px;
  }
}

.tabChangeButton {
  background-color: #ffff;
  border-radius: 0px;
  min-width: fit-content !important;
  height: 35px;
}

.tabChangeButton:hover {
  background-color: #dddddd9e !important;
  border-radius: 2px;
}
