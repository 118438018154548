@import "../../../../themes/colors.scss";

.subscription_creation_main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.subscription_creation_form_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.subscription_left_menu {
  width: 25%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px 0px #1e1e1e66;
  border-radius: 8px;
  height: fit-content;
}

.selectedItemBorder {
  position: absolute;
  border: 2px solid #3083ef;
  height: 65%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.selected_border {
  margin-right: 10px;
}

.left_menu_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 10px 10px 0px;
  cursor: pointer;
  margin: 5px 0px;
}

.active {
  border-left: 3px solid red;
  border-top-left-radius: -10px;
  border-bottom-left-radius: -10px;
}

.active_border {
}

.subscription_creation_form {
  height: 100%;
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.border_none {
  border: none;
}

.subscription_main_content_container {
  height: 98%;
  overflow-y: scroll;
  box-shadow: 0px 0px 2px 0px #1e1e1e66;
  padding: 20px;
  border-radius: 8px;
  width: 75%;
}

.subscription_overview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subscription_continer {
  height: 80%;
  width: 100%;
}

.subscriptin_input_main {
  width: 100%;
  margin-bottom: 15px;
}

.subscription_btns_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.feaures_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.features_selection {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
}

.features_Selection_listing {
  width: 100%;
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
}

.feature_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  border-bottom: 1px solid lightgrey;
}

.features_empty_cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.no-subtype {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

// Billing
.subscription_billing_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.billing_sub_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  align-items: center;
  margin-bottom: 10px;
}

.sub_inputs_main_section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.input_container_subscription {
  width: calc(100% / 3);
}

.gap_20 {
  gap: 20px;
  margin-bottom: 20px;
}

.subscription_cancellation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.subscription_refunds_cont {
  display: flex;
  flex-direction: row;
  height: 74%;
  gap: 25px;
  width: 50%;
  margin-top: 15px;
  margin-bottom: 15px;
  flex: 1;
  overflow-y: scroll;
  align-items: start;
}

.billing_flex_container {
  flex: 1;
  overflow-y: auto;
}

.margin_bottom_down {
  margin-bottom: 10px;
}

.trial_period {
  margin-top: 20px;
}

.frequency_listing_billing {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.pricing_containers {
  width: calc(100% - 160px);
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.display_none {
  display: none;
}

.adhcosub-main {
  padding: 1% 2%;
}

.adhcosub-main2 {
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  padding: 10px;
  background: #fff;
  width: 55%;
  height: 65vh;
  overflow: scroll;
}

.adhocsub-main1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 8px;
  padding: 15px;
  background: #fff;
  height: 70vh;
  width: 100%;
  overflow: scroll;
  gap: 15px;
}

.adhocsub-main6 {
  width: 45%;
}

.adhocsub-main9 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.adhocsub-item-countainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $BORDER_LIGHT_GREY;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
}

.add_feature_form {
  background-color: #fff;
  // padding: 20px;
  height: 95%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .form_content {
    height: 89%;
    min-height: 75vh;
    max-height: 89%;
    overflow: hidden;
  }

  .form_field {
    margin-bottom: 10px;
    width: 100%;
  }

  .list_form_field {
    margin-bottom: 10px;
    width: 100%;
    height: 60%;
  }

  .pricing-options {
    display: flex;
    width: 84%;
    align-items: center;
  }

  .search-form-field {
    margin: 15px 0px 10px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chips_container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .radio_group {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 23%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .input_field {
    margin-top: 10px;
  }

  .form_actions {
    display: flex;
    min-height: 10%;
    gap: 10px;
    position: fixed;
    right: 11%;
    top: 84%;
    justify-content: flex-end;
  }

  .listselection {
    display: flex;
    flex-wrap: wrap;
    // min-height: 100%;
    max-height: 45vh;
    height: inherit;
    overflow: scroll;
    gap: 10px;
  }
  @media only screen and (max-width: 480px) {
    .search-form-field {
      flex-direction: column;
      align-items: flex-start;
    }

    .listselection {
      height: 36vh;
    }

    .addPackage-button2 {
      width: 100%;
    }

    .addPackage-text7 {
      width: 95%;
    }
  }
}

.input_container_subscription .css-4jnixx-MuiStack-root > .MuiTextField-root {
  min-width: 100%;
  padding-top: 0px;
}

.input_container_subscription .css-4jnixx-MuiStack-root {
  padding-top: 10px;
}

@media only screen and (max-width: 480px) {
  .subscription_creation_main_container {
    flex-direction: row;
    // padding: 3px;
  }
  .subscription_creation_form {
    flex-direction: column;
    gap: 10px;
  }
  .subscription_left_menu {
    width: 100%;
    margin-bottom: 10px;
  }
  .subscription_main_content_container {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .subscription_creation_main_container {
    flex-direction: row;
    // padding: 2% 3%;
  }
  .subscription_creation_form {
    flex-direction: column;
    gap: 10px;
  }
  .subscription_left_menu {
    width: 100%;
    margin-bottom: 10px;
  }
  .subscription_main_content_container {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .subscription_creation_main_container {
    flex-direction: row;
    // padding: 2% 3%;
  }
  .subscription_creation_form {
    flex-direction: row;
  }
  .subscription_left_menu {
    width: 30%;
  }
  .subscription_main_content_container {
    width: 70%;
  }
}

@media only screen and (min-width: 1025px) {
  .subscription_creation_main_container {
    flex-direction: row;
    // padding: 1% 3%;
  }
  .subscription_creation_form {
    flex-direction: row;
  }
  .subscription_left_menu {
    width: 25%;
  }
  .subscription_main_content_container {
    width: 75%;
  }
}

@media only screen and (max-width: 480px) {
  .feaures_main_container {
    // padding: 5%;
  }
  .feature_item {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .subscription_btns_container {
    flex-direction: row;
    align-items: stretch;
  }
  .add_feature_form {
    .radio_group {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .pricing-options {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 15px;
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .feaures_main_container {
    padding: 2%;
  }
  .feature_item {
    flex-direction: row;
    align-items: flex-start;
  }
  .subscription_btns_container {
    flex-direction: row;
    justify-content: flex-end;
  }

  .add_feature_form {
    .radio_group {
      width: fit-content;
      min-width: 27%;
      display: flex;
      align-items: center;
    }
    .pricing-options {
      display: flex;
      width: 98%;
      align-items: center;
      margin-bottom: 15px;
      margin-right: 10px;
    }
    .search-form-field {
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .feaures_main_container {
    padding: 3%;
  }
  .feature_item {
    flex-direction: row;
    align-items: center;
  }
  .subscription_btns_container {
    justify-content: flex-end;
  }
  .add_feature_form {
    .radio_group {
      width: fit-content;
      min-width: 20%;
      display: flex;
      align-items: center;
    }
    .pricing-options {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .add_feature_form {
    .radio_group {
      display: flex;
      gap: 20px;
      align-items: center;
      width: fit-content;
      min-width: 15%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .pricing-options {
      display: flex;
      width: 84%;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 480px) {
  .frequency_listing_billing {
    flex-direction: column;
    align-items: flex-start;
  }
  .pricing_containers {
    width: calc(100% - 160px);
    flex-direction: column;
    align-items: center;
  }
  .input_container_subscription {
    width: 100%;
  }
  .sub_inputs_main_section {
    flex-direction: column;
    gap: 10px;
  }
  .subscription_btns_container {
    flex-direction: row;
    align-items: stretch;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .frequency_listing_billing {
    flex-direction: column;
    align-items: flex-start;
  }
  .input_container_subscription {
    width: 100%;
  }
  .sub_inputs_main_section {
    flex-direction: column;
    gap: 15px;
  }
  .subscription_btns_container {
    flex-direction: row;
    align-items: stretch;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .billing_flex_container {
    gap: 20px;
  }
  .frequency_listing_billing {
    flex-direction: row;
    align-items: center;
  }
  .input_container_subscription {
    width: calc(100% / 3);
  }
  .sub_inputs_main_section {
    flex-direction: row;
    gap: 15px;
  }
  .subscription_btns_container {
    flex-direction: row;
  }
}

@media only screen and (max-width: 480px) {
  .adhcosub-main {
    padding: 2%;
  }
  .adhocsub-main1 {
    flex-direction: column;
    height: auto;
    overflow: visible;
  }
  .adhocsub-main6 {
    width: 147%;
    display: flex;
    align-items: center;
  }

  .adhcosub-main2 {
    width: 100%;
    height: auto;
    margin-top: 15px;
    overflow: visible;
  }

  .adhocsub-main9 {
    justify-content: flex-start;
    margin-top: 0;
    padding-bottom: 22px;
  }

  .adhocsub-item-countainer {
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .adhcosub-main {
    padding: 2%;
  }

  .adhocsub-main1 {
    flex-direction: column;
    height: auto;
    overflow: visible;
  }

  .adhocsub-main6 {
    width: 170%;
    display: flex;
    align-items: center;
  }
  .adhcosub-main2 {
    width: 100%;
  }

  .adhcosub-main2 {
    height: auto;
    margin-top: 15px;
    overflow: visible;
  }

  .adhocsub-main9 {
    justify-content: flex-start;
    margin-top: 0;
    padding-bottom: 22px;
  }

  .adhocsub-item-countainer {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .adhcosub-main {
    padding: 1% 2%;
  }

  .adhocsub-main1 {
    flex-direction: row;
    height: 70vh;
    overflow: scroll;
  }

  .adhocsub-main6 {
    width: 45%;
  }

  .adhcosub-main2 {
    width: 55%;
    height: 65vh;
    overflow: scroll;
  }

  .adhocsub-main9 {
    justify-content: flex-end;
  }

  .adhocsub-item-countainer {
    flex-direction: row;
    align-items: center;
  }
}

.subscription_preview_section {
  background: #fff;
  // padding: 20px;
  // border-radius: 8px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;

  .preview_header {
    font-size: 1.5rem;
    font-weight: bold;
    // margin-bottom: 16px;
  }

  .preview_header_container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .preview_block {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .feature_item_preview {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-top: 8px;
      padding: 0;
    }

    .priceType {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .selected_obj_container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .addPackage-button2 {
        max-width: 320px;
        height: auto;
        border-radius: 10px;
        padding: 5px;
        background-color: rgba(247, 247, 250, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: #f0f0f0;
        }
      }

      .addPackage-img1 {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        object-fit: cover;
      }

      .cartCardAdd2 {
        display: flex;
        align-items: center;
      }
    }
  }

  .frequency_item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 6px;
  }

  .subscription_btns_container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
