#review_card_main{

        background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 4px 0 rgba(30, 30, 30, .251);
            cursor: pointer;
            height: 198px;
            padding: 16px !important;
    .slider_content_box_profile {
            margin-bottom: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
    
            .slider_image {
                height: 52px;
                width: 52px;
                border-radius: 100%;
            }
    
            @media screen and (max-width: 1550px) {
                .slider_image {
                    height: 40px;
                    width: 40px;
                }
            }
    
            .slider_content_box_profile_wrapper {
                // border: solid;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-left: 10px;
    
                .slider_content_box_profile_name {
                    font-family: "HelveticaNeueLight", sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 17.91px;
                    text-align: left;
                    margin-bottom: 5px;
                }
    
                .slider_content_box_profile_title {
                    font-family: "HelveticaNeueLight", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 13.83px;
                    text-align: left;
                    width: max-content;
                }
            }
        }
}