.user_subscriptions_main_container {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
}

.subscription_card_new {
  width: 275px;
  min-width: 275px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  position: relative;
  margin: 0px 0px 1px 1px;
}

.subscription_listing_main {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  margin-top: 10px;
  flex-wrap: wrap;
  height: 100%;
  // overflow-y: auto;
}
.subscription_price {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2px;
}

.subscription_price_frequency {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.subscription_features_new {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 170px;
  overflow-y: scroll;
}
.feature_item_card_new {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
}

.subscription_card_header-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  // margin-bottom: 5px;
  width: 100%;
}

.subscription_main_container {
  width: 100%;
}

@media (max-width: 1200px) {
  .subscription_card_new {
    width: 275px;
    min-width: 275px;
  }
}

@media (max-width: 992px) {
  .subscription_card_new {
    width: 275px;
    min-width: 275px;
  }
}

@media (max-width: 767px) {
  .subscription_card_new {
    width: 100%;
    min-width: 100%;
  }
  .subscription_listing_main {
    flex-direction: column;
  }
  .user_subscriptions_main_container {
    height: auto;
  }
}
