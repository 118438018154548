.pip-container {
  /* color: red !important; */
  /* border: 2px solid blue; */
  /* background-color: yellow; */
  position: fixed;
  /* top: 75vh; */
  /* top: 55vh; */
  /* top: 67vh; */
  bottom: 29px;
  z-index: 1000;
  right: 40px;
  /* right: 40px; */
  /* height: 100vh; */
  width: 23%;
  min-width: 300px;
  /* width: 820px; */
  height: 200px;
  /* height: 500px; */
  cursor: pointer;
  pointer-events: none;
}

.pip-container > * {
  pointer-events: auto;
}

.pip-page-container {
  color: red !important;
  /* border: 2px solid blue; */
  background-color: white;
  position: fixed;
  bottom: 10px;
  /* top: 75vh; */
  /* z-index: 10; */
  /* left: 20px; */
  left: 200px;
  /* height: 100vh; */
  width: 68%;
  /* border: 2px solid blue; */
  top: 200px;
  /* height: 70vh; */
  height: calc(100vh - 220px);
}

.pip-reset-index {
  z-index: 0
}

/* @media (min-width: 1440px) {
  .pip-page-container {
    top: 223px;
  }
} */

.pip-draggable-mini-container {
  position: relative;
  bottom: 0px;
  right: 0px;
  /* 
  bottom: 29px !important;
  right: 40px !important; */
  transform: translate(0px, 0px) !important;
}
.pip-draggable-container {
  position: absolute;
  /* left: 20px !important;
  right: 20px !important;
  bottom: 10px !important; */
  transform: translateX(0px) !important;
}

.full-container {
  /* height: 80vh; */
  color: red !important;
  background-color: white;
  position: fixed;
  top: 30vh;
  /* top: 75vh; */
  z-index: 100;
  left: 10px;
  /* height: 100vh; */
  width: 80%;
  /* width: 420px; */
  /* height: 500px; */
  height: 70%;
}
