.todo-option-items-container {
  display: flex;
  flex-direction: column;
 min-width: 500px; 
  gap: 20px;
}
.todo-option-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.todo-option-item-first {
  display: flex;
  align-items: center;
  gap: 15px;
}
.todo-list-head1 {
  margin: 0px;
  padding: 16px;
  font-weight: 600;
  font-size: 20px !important;
}

.todo-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  
  border-radius: 10px 10px 10px 10px;
}

.todo-item-image {
  width: 25px;
  height: 25px;
}

.todo-label-text {
  font-size: 14px !important;
  max-width: 340px;
}
.todo-sublabel-text {
  font-size: 11px !important;
  color: rgba(92, 91, 87, 1);
}
.todo-sublabel-text:hover {
  color: rgba(48, 131, 239, 1);
}
.todo-btn {
  align-self: center;
  padding: 12px 10px;
  outline: none;
  background-color: rgba(235, 243, 254, 1);
  border: 1px solid rgba(48, 131, 239, 1);
  border-radius: 10px;
  color: rgba(48, 131, 239, 1);
  font-weight: 600;
  width: 80px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.todo-courses-container {
  margin: 20px 0px;
}

.todo-list-head2 {
  font-weight: 500;
  font-size: 18px !important;
  margin: 20px 0px;
}

.classes-join-section {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.todo-btn-course {
  align-self: center;
  padding: 12px 10px;
  outline: none;
  border: none;
  color: rgba(235, 243, 254, 1);
  background-color: rgba(48, 131, 239, 1);
  border-radius: 10px;
  font-weight: 600;
  width: 80px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.todo-option-course-style {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media screen and (max-width: 550px) {
  .todo-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 350px;
  }
  .todo-option-items-container{
    width: 98%;
    min-width: 100%;
  }
  .todo-label-text {
    font-size: 14px !important;
    max-width: 100%;
  }
}
