.reschedule-batch-card {
  width: 30%;
}

@media screen and (max-width: 720px) {
  .reschedule-batch-card {
    width: 100%;
  }
}

.level-item {
  padding: 10px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.flex-container {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
}

.level-item:hover {
  border: 1px solid #1c1c1c;
}
.course-types {
  width: 32.1%;
}

.center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 10px;
}

.active-class {
  background-color: #2076e50a;
  border-color: #2076e5 !important;
}

.buttons-cont {
  justify-content: flex-end;
  margin-top: 15px;
}
.start-end-time {
  background-color: #ffffff;
  width: 100%;
  // margin: 5px;
  // border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 15px;
}
.eachDateStart {
  width: 50%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}

.day-button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 3px 8px;
  align-items: center;
}
.tags-container {
  border: 1px solid #bebaba;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.tags-container:hover {
  border: 1px solid #1c1c1c;
}

@media (max-width: 600px) {
  .day-button {
      margin: 6px 0px;
      padding: 6px 12px;
    }
}