:root {
  --aauti-primary-color: #3083ef;
  --aauti-theme-color: var(--aauti-primary-color);
  --aauti-secondary-color: #6c757d;
  --aauti-font-family: "Arial, sans-serif";

  --aauti-theme-color-lighter: #a6caf8;

  --aauti-padding: 10px;

  --aauti-option-btn-border-radius: 25px;
  --aauti-option-btn-border-color: var(--aauti-primary-color);
  --aauti-option-btn-bg-color: white;
  --aauti-option-btn-selected-bg: #eaf3fd;
  --auuti-option-btn-text-color: black;

  --aauti-text-link: #458ff1;
  --aauti-text-link-disabled: lightgrey;

  --aauti-container-border-color: #dddddd;
  --aauti-container-padding: var(--aauti-padding);
  --aauti-container-border-radius: 5px;
}
