
.message-images-container{
    display: table;
    flex-direction: row;
    flex-wrap: wrap;
    width: 140;
    max-width: 160;
    height: 90;
    max-height: 180;
    column-gap: 8px;
}

.message-image-overlay{
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    cursor: pointer;
}