// @import url("https://fonts.googleapis.com/css2?family=Neucha&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@400;700&display=swap");
@import "../../../themes/colors.scss";
.create-course {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.steeps {
  width: 96%;
}

.css-7o8aqz-MuiSlider-rail {
  background-color: grey !important;
}

.tab {
  width: 100%;
  border-radius: 6px;
  padding: 20px;
  // border: 1px solid #5c5b5729;
  margin-bottom: 15px;
  // cursor: pointer;
}

.TypesDiv {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  width: 100px;
}
.session-cou1 {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 15px;
}
.sessions-con {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonplus {
  padding: 5px;
  font-size: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #ffffff;
  width: 35px;
  align-self: center;
  cursor: pointer;
}

.labels {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}
.uploades {
  // width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uplodes_new_container_background {
  margin-bottom: 10px;
}
.image-upload-btn {
  background: #f2f2f6;
  padding: 10px;
  border-radius: 10px;
}
.uploaded-image-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  gap: 5px;
}

.images-scroll-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.schedule-4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.scheduleCourse {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #333333;
  width: 50%;
  cursor: pointer;
}
.cate-sub-cat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.address-item {
  width: 90%;
  border: 1px solid rgb(212, 207, 207);
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  // margin-bottom: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}
.addressListMap {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.disable-hover-join {
  transform: none;
}
.add-new-address p {
  color: var(--aauti-theme-color) !important;
  transform: none;
}

.course-lessonTopics {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.topic-durations-course {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.documents-media-course {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.subTopics-con-create {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}
.sessions-bnt-con-create {
  display: flex;
  //   width: 80%;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.topic-course {
  padding: 10px;
  border-radius: 10px;
  background-color: #078dda;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.topic-inActive-course {
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.topic-name-course {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  margin-right: 10px;
}
.enter-title-duration {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
.sessions-tab {
  width: 85%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 68vh;
}
.enter-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.ScheduleCourses-pop {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  // margin-top: -50px;
}
.start-end-date {
  background-color: #ffffff;
  width: 100%;
  // margin: 5px;
  // border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.eachDateStart {
  width: 50%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}
.available-timings {
  display: flex;
  flex-wrap: row;
  justify-content: space-around;
  width: 100%;
  background-color: lightgrey;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 10px;
}
.timing-type {
  width: 33%;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.start-end-time {
  background-color: #ffffff;
  width: 100%;
  // margin: 5px;
  // border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 15px;
}
.weeknames-map {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.weekNae {
  border: 1px solid #dddddd;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.slots-mapped {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.each-slot {
  width: 49%;
  padding: 10px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.slt-title {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.tags-container {
  border: 1px solid #bebaba;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.tags-input {
  height: 30px;
  outline: none;
  border: none;
  // caret-width: 5px;
  // background-color: grey;
}
.added-tag {
  padding: 5px;
  background-color: #f2f2f6;
  border-radius: 5px;
  margin-right: 7px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.weeknames-inSlot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
.weekName-index-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #dddddd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 49%;
}
.weekname-each-slot {
  background-color: #3166ba;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 10px;
  height: 35px;
  width: 35px;
}
.relatedImage-mappi {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.each-images-course {
  width: 160px;
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  // align-items: flex-end;
  justify-content: flex-end;
}
.pop-content-5 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
  padding: 10px;
}
.input-radio {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}
.startDate-end-inputs {
  display: flex;
  margin-top: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.joinButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.PreviewMain {
  width: 98%;
  height: auto;
  min-height: 80vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previewbutt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: #fff;
  border-style: solid;
  border-color: grey;
  border-width: 1px;
  border-radius: 5;
  cursor: pointer;
}

.previewSub1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: auto;
  margin-top: 10px;
  padding: 5px;
}

.previewcat1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  min-width: 50%;
  // width: 100%;
  // margin-bottom: 5;
  padding: 10px;
}

.heading {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
}

.SessionCont1 {
  width: 100%;
  height: auto;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}
.previewSub2 {
  display: flex;
  flex-direction: column;
  width: 98%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: auto;
  margin-top: 10px;
  padding: 5px;
}
.heading2 {
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  padding: 2px 2px 2px 2px;
  margin: 5px;
  margin-bottom: 2;
  font-family: "Amazon Ember V2", sans-serif;
}

.text1 {
  font-family: "Montserrat", sans-serif;
}

#editor-container {
  height: 50px;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
  width: "100%";
}

.container {
  position: relative;
}

.container-1,
.container-2 {
  transition: transform 0.5s ease;
}

.show {
  transform: translateX(0);
}

.hide {
  transform: translateX(-100%);
  display: none;
}
