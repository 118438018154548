@import "../../themes/colors.scss";
#my_address_main_cont {
  background-color: #ffff;
  width: 100%;
  padding: 3%;
  height: 100%;
}

.my_address_listing_cont {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  overflow: scroll;
}

.my_address_listing_cont_height_full {
   height: calc(100vh - 70px);
  overflow: scroll;

}

.nav_buttons_in_add_new {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.my_adress_list {
  flex: 1;
  overflow-y: auto;
}

.my_addresss_list_flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  gap: 8px;
  margin-bottom: 10px;
}

.sticky-container {
  // position: sticky;
  // bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  z-index: 202;
  border-radius: 6px;
  box-shadow: rgba(33, 35, 38, 0.1) -10px 10px 10px 10px;
}

.address-container {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  margin-left: 5px;
  margin-top: 15px;
  border-radius: 5px;
}

.hidden {
  display: none;
}

.address-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99%;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
}

.checkbox {
  height: 16px;
  width: 16px;
}

.label {
  cursor: pointer;
}

.select-container {
  width: 20%;
}

.client-location-container {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  display: none;
}

.client-location-container_show {
  display: flex;
}

.slider_client_location {
  width: 96%;
  margin-left: 11px;
}

.radius-text {
  margin-top: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.join-button {
  margin-top: 20px;
  width: 200px;
}

.add_new_header_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;
  width: 100%;
  margin-top: 6px;
  gap: 8px;
  @media (min-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
.maps_search_drop {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  position: relative;

  @media (min-width: 768px) {
    width: 100%;
  }
}
.add_new_main_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  overflow: scroll;
}

.add_new_maps_container {
  background-color: #fff;
  margin-bottom: 10px;
}
.use_current_location_border_none {
  border: none !important;
}

.use_current_btn {
  margin-top: 0px !important;
}

.save_address_As_text {
  text-align: left !important;
  font-size: 18px !important;
  margin-bottom: 10px !important;
}

.save_address_cat_name {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.add_new_inputs {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}

.address-button-new {
  width: calc(100% / 3);
  height: 35px;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.address-button-default {
  border: 1px solid #dddddd;
  background-color: #ffffff;
}

.address-button-selected {
  border: 1px solid $Aauti_theme_color;
  background-color: $LIGHT_BLUE_BACKGROUND;
}

.address-button-disabled {
  background-color: lightgrey;
  border: none;
}

.back_text_add_new {
  padding: 0px;
  color: #000;
  text-decoration: underline;
  margin-top: 2px;
}

.complete_address_fields {
  width: 100%;
}

.drop_downs_address_fields {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.flex_inputs_column {
  flex-direction: column;
  align-items: flex-start;
}
