.user_subscriptions_main_container {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
}

.subscription_card-dev {
  width: calc(100% / 4);
  min-width: calc(100% / 4);
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
   position: relative;
}

.subscription_listing_main {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  margin-top: 10px;
  flex-wrap: wrap;
  height: 100%;
  // overflow-y: auto;
}
.subscription_price {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2px;
}

.subscription_price_frequency {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.subscription_features {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 300px;
  overflow-y: scroll;
}
.feature_item_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
}

.subscription_card_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
  width: 100%;
}

.subscription_main_container {
  width: 100%;
}

@media (max-width: 1200px) {
  .subscription_card-dev {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 992px) {
  .subscription_card-dev {
    width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 767px) {
  .subscription_card-dev {
    width: 100%;
    min-width: 100%;
  }
  .subscription_listing_main {
    flex-direction: column;
  }
  .user_subscriptions_main_container {
    height: auto;
  }
}
