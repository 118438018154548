@import "../../../themes/colors.scss";

#aauti-time-picker{

    width: 100%;
    .react-datepicker-wrapper{
        width: 100% !important;
        z-index: 506 !important;
    }
    .date-picker-wrapper {
            width: 100% !important;
        }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
        padding-right: 0px !important;
    }
    
    .react-datepicker{
        width: 100% !important;
        border: none !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-color: lightgrey !important;
        
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
    }
    
    .react-datepicker-popper{
        top: -11px !important; 
        border-top: none !important;  
        z-index: 506 !important;
    }
    
        .react-datepicker__time-container {
            width:100% !important;
            border: none !important;
        }
        .react-datepicker__time-box{
            width: 100%;
        }
        .react-datepicker__time-list{
            width: 200px !important;
        }
    .react-datepicker__time-list-item{
        font-family: 'Poppins';
    }
    .react-datepicker__time-list-item--selected{
        background-color: $Aauti_theme_color;
    }
        

}