.TRloadingdiv1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80vh;
}
@media (max-width: 600px) {
  .TRloadingdiv1 {
    flex-direction: column;
  }
}

.TRloadingdiv2 {
  width: 29%;
}
@media (max-width: 600px) {
  .TRloadingdiv2 {
    width: 100%;
  }
}

.TRloadingdiv3 {
  width: 69%;
}
@media (max-width: 600px) {
  .TRloadingdiv3 {
    width: 100%;
  }
}

.TRboxShadowDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 6px;
  height: 80vh;
  //   box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
  //     rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
@media (max-width: 600px) {
  .TRboxShadowDiv {
    flex-direction: column;
    // height: 50vh;
    row-gap: 10%;
  }
}

.TRleftmaindiv01 {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-start;
  margin-top: 15px;
  width: 30%;
  border-right: 1px #dedede solid;
}
@media (max-width: 600px) {
  .TRleftmaindiv01 {
    width: 100%;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .TRleftmaindiv01 {
    width: 50%;
  }
}
.TRSPleftmaindiv2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  justify-content: space-around;
}

.TRscrollDiv {
  flex-direction: column;
  overflow: scroll;
  height: 65vh;
  margin-top: 10px;
  display: flex;
}
.TRcartCard1 {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
}

.requestItem-div1,
.requestItem-div2 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 98%;
  margin-left: 1%;
  padding: 8px 8px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
}
.requestItem-div1 {
  border-radius: 4px;
  background-color: #c8dcf6;
}

.requestItem-div3 {
  margin-left: 5px;
}

.requestItem-div4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
}

.requestItem-img1,
.TRDialog-img1 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.TRDialog-img1 {
  margin-top: 10px;
}
.requestItem-text1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #1e1e1e;
}
.transferRequest-div1,
.transferRequest-div2,
.transferRequest-div12 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.transferRequest-div1 {
  gap: 4px;
  margin-bottom: 8px;
}
.transferRequest-div2 {
  gap: 5px;
}
.transferRequest-div3 {
  background: #066cb5;
  padding: 2px 6px;
  border-radius: 4px;
}

.transferRequest-div4 {
  width: 96%;
}
.transferRequest-div5 {
  border: 1px solid lightgrey;
  border-radius: 5px;
}
.transferRequest-div6,
.transferRequest-div7 {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
}
.transferRequest-div6 {
  border-bottom: 2px solid #078dda;
}
.transferRequest-div7 {
  border-bottom: 0px solid #dedede;
}

.transferRequest-div8 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.transferRequest-div9 {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.transferRequest-div10 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.transferRequest-div11 {
  display: flex;
  flex-direction: column;
  width: 81%;
  margin-top: 10px;
  padding: 10px;
  height: 80vh;
  gap: 5px;
}

.transferRequest-div13 {
  padding: 10px;
}

.transferRequest-div14 {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  margin-right: 10px;
}
.transferRequest-div15,
.transferRequest-div16 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.transferRequest-div15 {
  justify-content: space-between;
}
.transferRequest-div16 {
  margin-top: 5px;
}
.transferRequest-div17 {
  width: 100%;
  border: 1px solid #e8e8e8;
  font-size: 2px;
}
.transferRequest-div18 {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 5px;
  overflow: scroll;
  flex-shrink: 1;
}
.transferRequest-div19 {
  width: 19%;
  min-width: fit-content;
  border-radius: 10px;
  margin-right: 5px;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid lightgray;
  height: 50px;
  display: flex;
  padding: 3px;
  align-items: center;
}
.transferRequest-div20 {
  width: 42px;
  height: 42px;
  max-height: 42px;
  border-radius: 10px;
}

.TRText-1 {
  color: black;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.TRDialog-div1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.TRDialog-div2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.TRDialog-div3 {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  background: none;
  margin-bottom: 10px;
}
.TRDialog-div4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: #efefef;
}
.TRDialog-div5 {
  padding: 8px;
}
.TRDialog-div6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
}
.TRDialog-div7,
.TRDialog-div8 {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  background: none;
  margin-bottom: 10px;
}
.TRDialog-div8 {
  display: none;
}
.TRDialog-div9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  background: #efefef;
}
.TRDialog-div10 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.TRDialog-text1 {
  color: #4b4d4d;
}
.TRDialog-text2 {
  color: #1e1e1e;
}
.TRDialog-icon1 {
  width: 20px;
  height: 20px;
}
@media (max-width: 600px) {
  .transferRequest-div11 {
    width: 100%;
  }
}
