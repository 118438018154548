.my-schedule-container{
  overflow: hidden;
  height: 100%;
  padding: 8px;
  @media (min-width:998px) {
    overflow: scroll;
    padding: 12px;
  }
  @media (min-width:1200px) {
    padding: 16px 2vw;
  }
}
.my-schedule {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 3%;
  padding-right: 3%;
  //   justify-content: center;
  //   align-items: center;
}
.slots-tabs {
  width: 100%;
  background-color: lightgray;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.each-tab-sched {
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
}
.scheduled-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.cheduled-card {
  display: flex;
  border: 1px solid #cac8c8;
  border-radius: 5px;
  min-height: 200px;
  max-height: 200px;
  width: 30%;
  flex-direction: column;
  height: fit-content;
}
.cheduled-card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #cac8c8;
}
.schedule-card-des {
  padding: 5px;
}
.start-end-even {
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.week-daily-all {
  background-color: lightgray;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}
.each-week-dai {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
.add-time-slots {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 15px;
  margin-bottom: 20px;
}
.offtime-trash {
  border: 1px solid #cac8c8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.time-slot-offtime {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}
.weeknames-map-adhoc1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.weekName-adhoc1 {
  border: 1px solid #dddddd;
  padding: 4px;
  width: 50px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myCourses-div8 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.week-cont {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 8px;
}

.week-day-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.selected-day {
  background: none;
  border: none;
  color: #3083EF; 
}
 
.times-cont {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  text-wrap: nowrap;
  max-width: 400px;
}
