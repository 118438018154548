
.chat-main-container {
  height: max-content;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  @media (min-width: 1512px) {
    background-color: rgba(242, 242, 246, 1);
    padding: 16px 6vw 0px 6vw;
  }
}

.chat-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (min-width: 1512px) {
    border-top-right-radius: 2.8px;
    border-top-left-radius: 2.8px;
  }
}

.empty-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}


.search-box-and-chats-container {
  width: 100%;
  height: inherit;
  background: #ffffff;
  border-right: solid 1px #d1e0e7;
  column-gap: 10px;
  @media (min-width: 600px) {
    max-width: 350px;
  }

  @media (min-width: 992px) {
    max-width: 350px;
  }

  .chats-list-header {
    display: flex;
    flex-direction: column;
    min-height: 0px;

    .search-box-container {
      display: flex;
      flex-direction: row;
      padding: 16px;
      height: 72px;
      column-gap: 16px;

      @media (min-width: 600px) {
        padding-top: 0px;
        height: 56px;
      }

      .create-group-btn {
        height: 40px;
        cursor: pointer;
        border: none;
        background-color: #f2f2f6;
        padding: 8px;
        border-radius: 8px;
      }
    }
  }
  .overflow-container {
    height: calc(100% - 110px);
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
  }

  .chats-list-container {
    width: 100%;
    margin: 0px;
    padding: 0px 16px 0px 16px;
    list-style-type: none;
  }

  button.search-box-btn-style {
    border: none;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.chats-list-border {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: solid 1px rgba(233, 233, 233, 1) !important;
  margin: 0 !important;
}
