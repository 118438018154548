@import "../../../themes/colors.scss";

.cancelIcon,
.cancelIcon2 {
  display: none;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.cancelIcon {
  position: relative;
  margin-right: -8px;
  margin-top: -20px;
}
// .cancelIcon2 {
//   width: 28px;
// }
// .return-button {
//   display: none;
// }
.notCard,
.notCard2,
.notCard3 {
  background: white;
  // margin-bottom: 10px;
  // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  //   rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px;
  // border-radius: 5px;
  outline: none;
  // border: 1px solid #fff;
  border-bottom: 1px solid $BORDER_COLOR;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  // cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.notCard2 {
  background: #3083ef1a;
}
.more-btns-notifications {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
}
.see-all-btn-notifications {
  width: 48%;
  border: 1px solid $BORDER_COLOR;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5px 0px;
  border-radius: 3px;
  cursor: pointer;
}
.notCard3 {
  background: whitesmoke;
}
.notCard:hover,
.notCard2:hover,
.notCard3:hover {
  // border: 1px solid #078dda;
  background: #3083ef1a;
  .cancelIcon,
  .cancelIcon2 {
    display: flex;
  }
  .cancelIcon2 {
    display: flex;
  }
  // .return-button {
  //   display: flex;
  //   flex-direction: row;
  //   width: 100%;
  //   justify-content: flex-end;
  // }
}
.return-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
.buttonStyleNotif {
  background: #3083ef;
  border: none;
  border-radius: 4px;
  padding: 5px 4px;
  margin-top: 4px;
  cursor: pointer;
  width: auto;
}
.buttonStyleNew {
  border: 1px solid #3083ef;
  border-radius: 4px;
  padding: 5px 4px;
  margin-top: 4px;
  cursor: pointer;
  width: auto;
  margin-right: 5px;
  background-color: #fff;
  flex-direction: row;
}
.notification-div1 {
  // max-height: 350px;
  overflow: scroll;
}
.notification-div2 {
  padding: 0px;
}
.notification-div3,
.notification-div4,
.notification-div8 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.notification-div8 {
  width: 100%;
}
.notification-div4 {
  width: 100%;
  // margin-top: 8px;
  justify-content: flex-end;
  // padding: 10px;
}
.notification-div5 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 73vh;
}
.notification-div6 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notification-div7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
}
.notification-div9 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
}
.notification-div10 {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // margin-top: 20px;
}
.notification-div11 {
  height: 300px;
}
.notification-div12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.rstextmargin {
  color: #078dda;
  margin: 0px 4px;
}
.rsimagestyle {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.rsgroupDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notification-text1 {
  color: #fff;
}
.notification-text3 {
  color: #078dda;
}
.notification-text2 {
  color: #6b6b6b;
}
.notification-text3 {
  cursor: pointer;
}
.notification-text4 {
  color: #3083ef;
  cursor: pointer;
  margin-right: 9px;
}
.notification-text5 {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #6b6b6b;
}
.notification-text6 {
  color: #6b6b6b;
  font-size: 8px;
}
.notification-text7,
.notification-text8 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  color: #6b6b6b;
  width: 90%;
}
.notification-text7 {
  width: 98%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.notification-text8 {
  max-width: 100%;
}
.notification-button1 {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.notification-button2 {
  width: 10%;
}
.notification-img2,
.notification-img1 {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.notification-img1 {
  border-radius: 4px;
  margin-top: 4px;
}
@media (max-width: 600px) {
  .cancelIcon,
  .cancelIcon2 {
    display: flex;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .notification-div1 {
    max-height: 500px;
  }
  .notification-div2 {
    padding: 10px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .cancelIcon,
  .cancelIcon2 {
    display: flex;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
.NFbatchRSdiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.NFdiv2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  margin: 15px 0px;
  border-bottom: 1px solid #dedede;
}

.NFimagestyle {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin-right: 10px;
}
.NFmapData {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.NFbuttonStyle {
  display: "flex";
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 50px;
}
.NFcolor {
  color: #1e1e1e;
}
.NFstudents {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.rsmainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  // align-items: center;
  // justify-content: center;
}
.rsmaindiv2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}
.rsradio1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  max-height: 100%;
  gap: 10px;
}
.rsmarginB {
  // margin-bottom: 15px;
  display: flex;

  align-items: center;
}
.rsmarginB2 {
  margin-bottom: 10px;
  display: flex;

  align-items: center;
}
.rsradiocursor {
  margin-right: 8px;
  cursor: pointer;
}
.rscursor {
  cursor: pointer;
}
.rscolor {
  color: #078dda;
}
.rsmapdiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0px;
  height: 100%;
  overflow: scroll;
}

.rstextline {
  // text-decoration-line: line-through;
  color: #078dda;
}
.rstext90 {
  width: 90%;
}
.rschilddiv {
  width: 48%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
}
.rschilddiv2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  background-color: #dedede;
  justify-content: flex-start;
  border-bottom: 1px solid #5c5b5733;
  align-items: center;
}
.rsicondiv {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.rsicondiv2 {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
}
.rsJoinDiv {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 15px;
}

.scroll_btn_up {
  opacity: 0.8;
  z-index: 89;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: white;
  border-radius: 20px;
  border: solid 1px #e6ebff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  position: absolute;
  right: 65px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scroll_btn_up:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 720px) {
  .rschilddiv {
    width: 100%;
  }
}
