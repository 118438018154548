@import "../../../themes/colors.scss";

//AllTrendingCourses

.allTrending-div1 {
  width: 100%;
  margin-bottom: 20px;
  padding: 1%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.ProfileMenu-menu2 {
  overflow: visible;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  margin-top: 6.5rem; // Assuming mt: 5 means 5rem
  width: 220px;
  z-index: 4500;

  .MuiAvatar-root {
    width: 32px;
    height: 32px;
    margin-bottom: 0.5rem; // Assuming mb: 0.5 means 0.5rem
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 20;
    right: 15px;
    width: 10px;
    height: 10px;
    background-color: #ffffff; // Assuming you have a variable $background-paper defined for the background color
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
  }
}
.allTrending-div2 {
  height: fit-content;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
}
.allTrending-div3 {
  overflow: hidden;
  overflow-y: scroll;
  height: 82vh;
  width: calc(100% - 280px);
}

.allTrending-div4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
}
.allTrending-div5 {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upComing-div1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
@media (max-width: 600px) {
  .allTrending-div2 {
    width: 100%;
    max-width: 100%;
    min-width: 99%;
  }
  .allTrending-div1 {
    flex-direction: column;
  }
  .allTrending-div3 {
    width: 100%;
  }
}

//Course Filters

.courseFilter-button1 {
  border: 1px solid #e5e9ed;
  padding: 8px 12px;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}

.courseFilter-div1 {
  width: 100%;
  // padding: 15px;
  height: fit-content;
}
.courseFilter-div2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.courseFilter-div3 {
  width: 100% !important;
  padding-left: 0px !important;
  background: #f2f2f2 !important;
  margin-top: 1px !important;
}
.courseFilter-div4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 4px;
}
.courseFilter-text1 {
  color: $Aauti_theme_color;
  cursor: pointer;
}
.applied-filters-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 4px 10px 10px 10px;
}
.applied-filters-button {
  display: flex;
  flex-direction: row;
  justify-content: "space-between";
  font-family: "Helvetica Neue";
  font-weight: 400;
  color: $Aauti_theme_color;
  border: 1px solid $Aauti_theme_color;
  border-radius: 6px;
  line-height: 14px;
  padding: 8px 12px 8px;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
.closeIcon {
  margin-left: 10px;
}
