.abstract_reply_wrapper {
    width: 100%;
    height:100%;
    border-radius: 5px;
    row-gap: 5px;
    padding: 6px;
    display: flex;
    background-color: #FAFAFA;
    border-left:solid 3px #066cb5;
}

.abstract_reply_to_text {
    color: black;
    font-size: 10px !important;
    font-weight: 400 !important;
}

.abstract_reply_to_wrapper{
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    flex-grow: 1;
    row-gap: 4px;
}

.abstract_reply_icon{
    font-size: 26;
}