.notification-titles {
    display: flex;
    gap: 10px;
    margin-bottom: 12px;
  }
  .notification-text {
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
  }