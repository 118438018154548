.image-view-with-cropper-wrapper{
    height: inherit;
    width: inherit;

    .form-image-view {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }

    .image-menu{
        position: absolute;
        bottom:0px;
        right: 0px;
        background-color:#3166ba;
    }

    .preview-dialog-image{
        align-self: center;
        height: 80vh !important;
        width: 100% !important;
        aspect-ratio: 16 / 9;
    }
}