.add-participants-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 10px;
  height: 100%;
  border-radius: 2.4px;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: scroll;

  .participants-list-wrapper {
    flex-grow: 1;
    overflow-y: scroll;
  }

  .participant-list-item {
    max-width: inherit;
    border-radius: 10px;
    padding: 8px 8px;
    cursor: pointer;
    margin-bottom: 2px;

    .card-texts-wrapper{
      height: 60px;
      width: 100%;
      margin-left: 8px;
    }

    .username {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #1e1e1e;
      font-family: "Poppins", Helvetica, sans-serif;
    }

    .role {
      width: 90%;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #5c5b57cc;
      margin-top: 4px;
      font-family: "Poppins", Helvetica, sans-serif;
    }
  }

  .participant-list-item.active {
    background-color: rgba(32, 118, 229, 0.1);
    border: none;
  }

  .added-participant-item {
    display: flex;
    justify-content: space-between;
  }

  .action-buttons-wrapper {
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
  }
}
