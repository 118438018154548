@import "../../themes/colors.scss";
.add-more-button-adhoc {
  border: 1px solid $Aauti_theme_color;
  outline: none;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  color: $Aauti_theme_color;
  align-self: flex-end;
  width: fit-content;
  padding: 5px 10px;
  height: auto;
}

.start-end-even {
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.week-daily-all {
  background-color: lightgray;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}
.each-week-dai {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.mms-main1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 4px;
}
.mms-main2,
.mms-main3 {
  padding: 6px 12px;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.mms-main2 {
  border: 1px solid $Aauti_theme_color;
}
.mms-main3 {
  border: 1px solid lightgrey;
}
.mms-main4 {
  color: $Aauti_theme_color;
}
.mms-main5 {
  color: #000;
}
.mms-main6 {
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  padding: 10px;
  background: #fff;
  height: auto;
  overflow: scroll;
  margin-bottom: 10px;
  position: relative;
}
.mms-main7 {
  align-self: flex-end;
  position: absolute;
  right: 8px;
  padding: 0.5px;
}
.mms-main8 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96vh;
}
@media (max-width: 600px) {
  .mms-main8 {
    height: 86vh;
  }
}

@media only screen and (min-width: 610px) and (max-width: 1023px) {
  .mms-main8 {
    height: 90vh;
  }
}
.mms-main9 {
  width: 100%;
  margin-top: 10px;
  flex: 1px;
  overflow: scroll;
  margin-bottom: 5px;
}
.mms-main10,
.mms-main11 {
  padding: 10px 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mms-main10 {
  border: 1px solid #3083ef;
  background: #ebf3fe;
  display: none;
}
.mms-main11 {
  border: 1px solid #b0b0b0;
  background: #fff;
  display: flex;
}
.mms-main12 {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.mms-main13 {
  height: 40px;
  width: 100%;
}

//activePOPcontent
.active-pop-content {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.active-pop-content-date-range {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.search-container-adhoc-content {
  width: 100%;
  padding: 8px;
  flex: 1;
  overflow-y: auto;
}

.filtered-cat-container {
  width: 100%;
}

.filtered-cat-item {
  padding: 6px;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
}

.filtered-cat-item-text {
  color: #000;
}

.filtered-cat-item-text-active {
  color: #2076e5;
}

//activeContent

.active-content-container {
  width: 100%;
}

.active-content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.active-content-header-text {
  margin-bottom: 8px;
}

.active-content-add-button {
  background: none;
  border: none;
  color: #3083ef;
  cursor: pointer;
}

.active-content-list {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}

.active-content-list-item {
  background: #ebf3fe;
  padding: 6px 10px;
  border: 1px solid #3083ef;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.active-content-list-item-text {
  color: #3083ef;
}

.active-content-close-icon {
  height: 14px;
  width: 14px;
  color: #3083ef;
}

.active-content-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.active-content-footer-hidden {
  display: none;
}

.active-content-next-button {
  background: #3083ef;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 4px;
  border: none;
  color: #fff;
}

//categorySelection

.category-selection-container {
  width: 100%;
}

.category-selection-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
}

.category-selection-button {
  padding: 6px 12px;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.category-selection-button-active {
  border: 1px solid #1e98d7;
}

.category-selection-button-inactive {
  border: 1px solid lightgrey;
}

.category-selection-text-active {
  color: #1e98d7;
}

.category-selection-text-inactive {
  color: #000;
}

.category-selection-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.category-selection-save-button {
  background: $Aauti_theme_color;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
}

//match-courses
.match-courses-container {
  width: 100%;
  margin-bottom: 15px;
}

.match-courses-container-hidden {
  display: none;
}

.carousel-list-container {
  width: 100%;
}

.infinite-scroll-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 15px;
  overflow: auto;
}

.infinite-scroll-container-nowrap {
  flex-wrap: nowrap;
}

.infinite-scroll-container-wrap {
  flex-wrap: wrap;
}

.match-courses-button {
  background: none;
  cursor: pointer;
  border: none;
  display: none;
}

.match-courses-button-toprated {
  display: flex;
}

//matchmyschedule.css
.match-my-schedule-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 12px;
}

.match-my-schedule-date-range {
  width: 100%;
}

.match-my-schedule-checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: flex-start;
  align-self: flex-start;
  overflow: hidden;
  width: 100%;
}

.match-my-schedule-checkbox-tooltip {
  z-index: 5000000000 !important;
  width: 100px !important;
}

.match-my-schedule-selected-ranges {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.match-my-schedule-selected-range-item {
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: hidden;
  overflow-x: scroll;
  width: 398px;
}

.match-my-schedule-date-item {
  border-style: solid;
  border-color: #ddd;
  border-width: 1px;
  background: transparent;
  width: 90px !important;
  max-width: 90px;
  min-width: 90px;
  border-radius: 6px;
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.match-my-schedule-date-item-active {
  background: #066cb5;
}

.match-my-schedule-date-item-text {
  color: #000;
}

.match-my-schedule-date-item-text-active {
  color: #fff;
}

.match-my-schedule-schedule-list {
  background: #f2f2f2;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}

.match-my-schedule-schedule-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border-radius: 6px;
  gap: 2px;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.match-my-schedule-schedule-button-active {
  background: #fff;
}

.match-my-schedule-timings-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
  gap: 10px;
}

.match-my-schedule-timing-button {
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: #f2f2f2;
  background: transparent;
  color: #000;
}

.match-my-schedule-timing-button-active {
  background: green;
  color: #fff;
}

//matchMyscheduleFilters
.match-my-schedule-filters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.match-my-schedule-filters-when {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 4px;
  width: 100%;
  margin: 10px 0px;
}

.match-my-schedule-filters-when-button {
  padding: 6px;
  border: 1px solid $Aauti_theme_color;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  color: $Aauti_theme_color;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.match-my-schedule-filters-when-text {
  width: max-content;
}

.match-my-schedule-filters-clear-button {
  height: 30px;
  width: fit-content;
}

.match-my-schedule-filters-day-time {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  padding: 6px;
  width: 100%;
}

.match-my-schedule-filters-week-slots {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  cursor: pointer;
}

.match-my-schedule-filters-week-slots-button {
  display: flex;
  background: #fff;
  border-radius: 4px;
  border: 1px solid lightgrey;
  width: 100%;
  padding: 4px;
  align-items: center;
  gap: 4px;
}

.match-my-schedule-filters-week-slots-more {
  color: $Aauti_theme_color;
  display: flex;
  margin-left: 4px;
  display: flex;
  gap: 1px;
}

.match-my-schedule-filters-time-slots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  padding: 6px;
}

.match-my-schedule-filters-time-slots-button {
  display: flex;
  background: #fff;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 4px;
  align-items: center;
}

.match-my-schedule-filters-choose-button {
  height: 30px;
  width: fit-content;
}

.match-my-schedule-filters-clear-button-outlined {
  border-color: $Aauti_theme_color;
  height: 30px;
  align-self: flex-end;
  width: fit-content;
}

.match-my-schedule-filters-other {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 4px;
}

.match-my-schedule-filters-other-button {
  padding: 6px 12px;
  border: 1px solid lightgrey;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.match-my-schedule-filters-other-button-active {
  border: 1px solid $Aauti_theme_color;
}

.match-my-schedule-filters-other-button-text {
  color: #000;
}

.match-my-schedule-filters-other-button-text-active {
  color: $Aauti_theme_color;
}

.match-my-schedule-filters-other-button-icon {
  color: $Aauti_theme_color;
}

//matchMyscheduleListView
.match-my-schedule-list-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.match-my-schedule-list-view-tabs {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 6px;
  padding-top: 4px;
  margin-bottom: 10px;
  overflow-x: scroll;
  flex-wrap: nowrap;
}

.match-my-schedule-list-view-tab-button {
  padding: 6px 12px;
  border: 1px solid lightgrey;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  min-width: fit-content;
  width: fit-content;
}

.match-my-schedule-list-view-tab-button-active {
  border: none;
  background-color: #3083ef;
}

.match-my-schedule-list-view-tab-text {
  color: #000;
}

.match-my-schedule-list-view-tab-text-active {
  color: #fff;
}

.match-my-schedule-list-view-results {
  width: 99%;
  height: 83vh;
  overflow: scroll;
}

.match-my-schedule-list-view-results-mobile {
  height: 70vh;
  padding-bottom: 20px;
}

//moreResults
.more-results-container {
  width: 100%;
  padding-left: 1.5%;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  gap: 15px;
}

.more-results-container-mobile {
  flex-direction: column;
  margin-top: 2px;
}

.more-results-filters {
  width: 20%;
  max-width: 20%;
  min-width: 20%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 4px;
  padding: 10px;
  background: transparent;
  height: fit-content;
}

.more-results-filters-mobile {
  width: 99%;
  max-width: 99%;
  min-width: 99%;
  padding: 0;
}

.more-results-content {
  width: 78%;
}

.more-results-content-mobile {
  width: 100%;
}

.more-results-scroll {
  width: 100%;
  height: 80vh;
  overflow: scroll;
}

.more-results-title {
  margin-bottom: 10px;
}

.more-results-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50vh;
  width: 100% !important;
  align-items: center;
}

.more-results-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
  align-items: flex-start;
}

//slotSelection.js

.slot-selection-container {
  width: 100%;
}

.slot-selection-title {
  margin-bottom: 8px;
}

.slot-selection-checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
}

.slot-selection-weekdays-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: auto;
}

.slot-selection-weekday {
  border-style: solid;
  border-color: #ddd;
  border-width: 1px;
  background: transparent;
  width: fit-content;
  border-radius: 6px;
  padding: 2px 10px;
  cursor: pointer;
  color: #000;
}

.slot-selection-weekday-active {
  background: #3083ef;
  color: #fff;
}

.slot-selection-schedule-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
  border-bottom: 1px solid #b0b0b0;
}

.slot-selection-schedule-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  gap: 2px;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
}

.slot-selection-schedule-button-active {
  background: #fff;
  border-bottom: 3px solid #3083ef;
}

.slot-selection-timings-container {
  display: flex;
  flex-direction: row;
  padding: 8px;
  flex-wrap: wrap;
  gap: 10px;
}

.slot-selection-timing-button {
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: #f2f2f2;
  background: transparent;
  color: #000;
}

.slot-selection-timing-button-active {
  background: #3083ef;
  color: #fff;
}

.slot-selection-selected-times {
  margin-top: 10px;
  margin-bottom: 10px;
}

.slot-selection-week-slots {
  display: flex;
  width: 100%;
  align-items: center;
}

.slot-selection-week-slots-more {
  overflow: hidden;
  display: flex;
  gap: 10px;
}

.slot-selection-week-slots-more-text {
  color: $Aauti_theme_color;
  margin-left: 10px;
}

.slot-selection-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.slot-selection-next-button {
  background: $Aauti_theme_color;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 4px;
  border: none;
  color: #fff;
}

//index.js>matchmyschedule
.match-my-schedule-container-main {
  width: 100%;
  padding-left: 1.5%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 15px;
}

.match-my-schedule-container-mobile {
  flex-direction: column;
}

.match-my-schedule-filters {
  width: 20%;
  max-width: 20%;
  min-width: 20%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 4px;
  padding: 10px;
  height: 89vh;
  overflow: scroll;
}

.match-my-schedule-filters-mobile {
  width: 99%;
  max-width: 99%;
  min-width: 99%;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  height: 40px;
  overflow: none;
}

.match-my-schedule-filters-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 8px;
  align-items: center;
}

.match-my-schedule-filters-header-mobile {
  gap: 2px;
  margin-bottom: 2px;
}

.match-my-schedule-search {
  width: 100%;
}

.match-my-schedule-search-input {
  width: 100%;
}

.match-my-schedule-search-icon {
  display: flex !important;
  padding: 0px !important;
}

.match-my-schedule-search-icon-hidden {
  display: none !important;
}

.match-my-schedule-content {
  width: 78%;
}

.match-my-schedule-content-mobile {
  width: 100%;
}

.match-my-schedule-dialog {
  width: 100%;
}

.match-my-schedule-backdrop {
  color: #fff;
  z-index: 1301; /* theme.zIndex.drawer + 1 */
}

.blockBuster-div1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 8px;
  width: 100%;
}
