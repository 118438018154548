.comments-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
}

.comment-video-cont {
  width: 30%;
  height: 78vh;
  overflow: hidden;
}
.comment-content {
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
}
.comment-header-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.comments-det {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  padding: 4px;
}

.comment-menu {
  position: absolute;
  right: 0px;
  top: 0px;
}

.reply-btn {
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}

.comments-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.comment-buttons-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}

.input-styles {
  border: 0px 0px 1px 0px;
  width: 100%;
}

.button {
  width: fit-content;
  padding: 5px 15px;
  border-radius: 5px;
  border-color: #2076e5;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  background-color: transparent;
}

.save {
  background-color: #2076e5;
}
