.chat-bot {
  &-window {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5;
      width: 350px;
    }
    &-wrapper {
      height: 70vh;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      width: 350px;
    }
  }

  &-header {
    &-wrapper {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #ddd;
      position: sticky;
      top: 0px;
      z-index: inherit;
      background-color: "white";
    }
    &-logo {
      background-color: #ffffff;
      border-radius: 50%;
      object-fit: contain;
      padding: 7px;
      height: 45px;
      width: 45px;
      box-shadow: inset 0 0 10px 1px #e6ebff;
      margin-right: 10px;
    }

    &-title {
      font-size: 20px;
      color: #404040;
      align-items: center;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  &-messages-container {
    flex-grow: 1;
    overflow-y: auto;
  }

  &-message-item {
    &-container {
      display: flex;
      flex-direction: column;
      overflow-wrap: anywhere;
    }
    &-container.right {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &-container.left {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &-content-wrapper{
      display: flex;
      column-gap: 10px;
      padding: 10px;
      max-width: 80%;
      box-shadow: none !important;
      align-items: center;
    }
  }
}
