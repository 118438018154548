.main-container {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;

}

.date-picker-cont {
    width:45%;
}

.date-time-cont {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title-input {
    width:50%;
}

.dropdown-language {
    width: 55%;
}

.price-cont {
    width: 37%;
    margin: 0;
}

.title-language-price-cont {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}
.class-type-main-container {
    gap: 20px;
    display: flex;
    flex-direction: row;
}
.class-type-child-cont {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
}
.dd-price-cont {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.batches-container {
    display: flex;
    flex-direction: row;
    height: 50px;
    gap: 10px;
    overflow-y: scroll;
}
.address {
    width: 50%;
}
.batch-text {
    min-width: 150px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.adhoc-buttons {
    display: flex;
    gap: 20px;
    align-self: flex-end;
}
.batch-adhoc-cont {
    display: flex;
    flex-direction: column;
}
.conflict-button {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-start;
    align-items: center;
    margin: 0;
    img {
        height: 12px;
        width: 12px;
    }
}

.sub-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.hover-scroll {
    overflow: hidden;
    white-space: nowrap;
    margin-top: 1%;
    width: 100%;
    text-overflow: ellipsis;
  }

.scrolltext {
    display: inline-block;
}





@media screen and (max-width:800px) {
    .date-time-cont  {
        width: 100%;
    }
    .date-picker-cont  {
        width: 48%;
    }
    .dropdown-language, .price-cont ,.title-input,.dd-price-cont, .address, .conflict-button {
        width: 100%;
    }
    .dd-price-cont {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}