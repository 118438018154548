.screen-container{
    display: flex;
    flex-direction: column;
    padding: 8px;
  
    @media (min-width:998px){
      padding: 12px;
    }
    @media (min-width:1200px){
      padding: 16px 2vw;
    }
  }