@import "../../../themes/colors.scss";
@media (min-width: 600px) {
  .TSPmainCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 420px;
    width: 298px;
    background-color: rgb(255, 255, 255);
    flex-direction: column;
    border-radius: 8px;
    background-size: cover;
    border: none;
    border-radius: 6px;
    border-color: lightgrey;
    position: relative;
  }
}
.spTagsDivTopCard {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  overflow-x: auto;
  max-width: 48%;
  justify-content: flex-start;
  white-space: nowrap;
}
@media (max-width: 600px) {
  .spTagsDivTopCard {
    max-width: 100%;
    max-width: 50%;
  }
}
@media (max-width: 599px) {
  .TSPmainCard {
    width: 100%;
    height: 110px;
    padding: 4px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border-style: solid;
    border-radius: 6px;
    border-width: 1px;
    border-color: lightgrey;
    margin-bottom: 4px;
    background-color: #ffffff;
  }
}

.TSPmainCardNew {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  flex-direction: column;
  border-radius: 8px;
  background-size: cover;
  // border-style: solid;
  border-radius: 6px;
  // border-width: 1px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

  // border-color: lightgrey;
  padding: 8px;
  margin-right: 2px;
  cursor: pointer;
  margin-bottom: 3px;
}

@media (min-width: 600px) {
  .cardsecondDiv {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 8px;
  }
}

@media (max-width: 600px) {
  .cardsecondDiv {
    height: 100%;
    width: 68%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
  }
}

.cardsecondDivNew {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0px;
}

.TOPnewstyles {
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
}

.TOPnewstylesdiv2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.TOPnewstylesdiv3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.TOPimagediv {
  height: 12px;
  width: 18px;
  margin-right: 5px;
}

.TOPimagediv2 {
  height: 12px;
  width: 20px;
  margin-right: 5px;
}

.TOPtextstyle {
  overflow: hidden;
  color: #616a6b;
  text-align: center;
  margin: 5px 0px;
}

.cardmobileStyle {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.cardmobileStyleNew {
  width: 100px;
  max-width: 100px;
  min-height: 100px;
  height: 100px;
  border-radius: 50%;
}

.default-image-view {
  object-fit: cover;
  width: 100px;
  max-width: 100px;
  min-height: 100px;
  height: 100px;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .cardmobileStyle {
    width: 30%;
  }
}

.TSPheartDivNew {
  display: flex;
  align-self: flex-end;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
}

.TSPheartDiv {
  display: flex;
  align-self: flex-end;
  // width: 40px;
  // height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  // background-color: white;
  margin-right: 5px;
  margin-top: 5px;
  // box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}
.Tsp-div2 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .TSPheartDiv {
    margin-right: 0px;
    margin-top: 0px;
    width: 30px;
    height: 30px;
    align-self: flex-start;
  }
  .Tsp-div2 {
    display: none;
  }
}

.TSPsecondDiv {
  background-color: rgba(255, 255, 255, 0.78);
  opacity: 55;
  padding: 10px;
  width: 95%;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: transform 0.3s ease;
  cursor: pointer;
}

@media (max-width: 600px) {
  .TSPsecondDiv {
    width: 100%;
    padding: 0px;
    opacity: 1;
    background-color: none;
  }
}

@media (min-width: 600px) {
  .TSPsecondDiv:hover::after {
    transform: scale(1);
    transition-duration: 1s;
    transform: translateY(-5px);
  }
}

@media (min-width: 600px) {
  .TSPsecondDiv:hover {
    transform: scale(1.01);
    transition-duration: 1s;
    transform: translateY(-5px);
  }
}

.TSPhoverdiv1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.TSPhoverdiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 9px;
}

.TSPhoverdiv2 {
  display: flex;
  flex-direction: row;
  // width: 33%;
  justify-content: space-around;
  align-items: center;
}
.TSPhoverdiv3,
.TSPhoverdiv4 {
  display: flex;
  flex-direction: column;
  // width: 33%;
  justify-content: space-around;
  align-items: center;
}
.TSPhoverdiv4 {
  border: none;
  margin: 0px 5px;
}

.MoreTSPdiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  flex-direction: column;
}

.TOPnodata {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Tsp-div3 {
  padding: 0px 3px 0px 3px;
  position: absolute;
  top: 84px;
  border-radius: 6px;
  width: fit-content;
  color: #ffffff;
  background-color: rgba(48, 131, 239, 1);
}
@media (max-width: 600px) {
  .Tsp-div3 {
    top: 86px;
    border-radius: 4px;
  }
}
.Tsp-div4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Tsp-text1 {
  color: #616a6b;
}
.Tsp-text2 {
  color: rgb(43, 43, 43);
}
.Tsp-text3 {
  color: #616a6b;
}
.Tsp-text4 {
  color: #fff;
}
.Tsp-text5 {
  margin-right: 5px;
}
.Tsp-text6 {
  color: #616a6b;
  margin-left: 5px;
}
.Tsp-button1 {
  height: 40px !important;
  margin-top: 5px !important;
  width: 120px !important;
}
.Tsp-button2 {
  width: 100px;
  padding: 4px 6px;
  margin-right: 10px;
  background-color: #078dda;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
}
.Tsp-icon2 {
  cursor: pointer;
  color: #ffffff;
  margin-right: 4px;
}
.Tsp-icon1 {
  cursor: pointer;
  color: #078dda;
  margin-right: 8px;
}
.Tsp-icon1 {
  align-self: flex-start;
}

@media (max-width: 600px) {
  .TOPnodata {
    width: 100%;
    height: 100px;
  }
  .TSPhoverdiv4 {
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
  }
  .Tsp-icon1 {
    margin-right: 5px;
  }
}

@media (max-width: 600px) {
  .TOPskeletondiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.TOPskeletondiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.moreTSPdiv2 {
  width: 93%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: wrap;
  gap: 10px;
}

@media (max-width: 600px) {
  .moreTSPdiv2 {
    width: 98%;
    justify-content: center;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .moreTSPdiv2 {
    width: 90%;
  }
}

.TPnewcardDiv {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  height: 195px;
}

.TPdispynameText {
  color: rgb(43, 43, 43);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-left: 8px;
}

.TPskillname {
  overflow: hidden;
  color: rgb(97, 106, 107);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  margin-left: 8px;
  max-width: 100%;
}

.TPhighDiv {
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  align-items: center;
  // margin-top: 10px;
}

.TPhighTextdiv {
  display: flex;
  flex-direction: row;
  background-color: rgb(249, 239, 153);
  padding: 4px 6px;
  margin-right: 2px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .TPhighTextdiv {
    padding: 3px 5px;
  }
}
.TPgapDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.TPFollowTextDiv {
  display: flex;
  flex-direction: row;
  background-color: $Aauti_theme_color;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 5px;
  border: none;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.TPshareDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 30%;
  cursor: pointer;
}

.TSimageNewStyle {
  height: 25px;
  width: 25px;
  z-index: 100px;
}

.TSimageNewStyle2 {
  height: 22px;
  width: 22px;
  z-index: 100px;
}

.TSAiFillStar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TSAiFillStarNew {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TPshareDiv2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 100%;
}

//FavouriteServiceProviders

.favouriteSp-div1 {
  width: 100%;
  padding: 1%;
}
.favouriteSp-div2 {
  width: 100%;
  // display: flex;
  flex-direction: row;
  margin-top: 6px;
  gap: 10px;
  flex-wrap: wrap;
}
.favouriteSp-skeleton1 {
  width: 300px !important;
  height: 250px !important;
}

@media (max-width: 599px) {
  .favouriteSp-skeleton1 {
    width: 100% !important;
    height: 110px !important;
  }
}

//Main Index.js
.topProvider-div1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
  overflow-x: scroll;
}
.topProvider-div2 {
  width: 60%;
}

//More TopProviders

.moreTopSp-div1 {
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 100%;
  padding: 1%;
  width: 100%;
}
.moreTopSp-div2 {
  display: flex;
  height: fit-content;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}
.moreTopSp-div3 {
  width: calc(100% - 280px);
  height: 100%;
}
.moreTopSp-div4 {
  width: 293px;
  height: 220px;
  
}

@media (max-width: 599px) {
  .moreTopSp-div2 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 0px !important;
  }
  .moreTopSp-div1 {
    flex-direction: column;
  }
  .moreTopSp-div3 {
    width: 100%;
  }
  .moreTopSp-div4 {
    width: 100%;
    height: 110px;
  }
}
// .slick-slider .slick-track {
//   margin-left: 2px;
// }
