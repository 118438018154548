.Students-page {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.head {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-color: red;
  // align-items: center;
}
.count-students {
  display: flex;
  padding: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.students-courses-map {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
.each-course {
  // border-bottom: 2px solid #f0f0f0;
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 10px;
}
.course-coutn-map {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 86%;
}
.stu-dialog-con {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.selected-details-con {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 2px solid #f0f0f0;
}
.users-map-pop {
  display: flex;
  flex-direction: row;
  border: 1px solid #f0f0f0;
  padding: 10px;
  width: 180px;
  align-items: center;
  border-radius: 6px;
  // border-top: 2px solid #f0f0f0;
  cursor: pointer;
}
@media (max-width: 600px) {
  .each-course {
    padding: 5px;
  }
}
.bactches-students-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.student-profile-div {
  display: flex;
  flex-direction: column;
  margin-left: 10px !important;
}
.student-profile-main-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 75vh;
}
.student-profile-pageType {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.student-profile-page-div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.student-profile-avatar-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
  margin-bottom: 10px;
}
.student-profile-avatar-div2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.student-profile-screename-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
  margin-bottom: 10px;
  height: 45vh;
  overflow: scroll;
}
.student-profile {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.student-profile-disaply-div {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0px;
}
.student-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.student-profile-length-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.student-profile {
  display: flex;
  flex-direction: row;
}
.student-profile-useRole-div {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
