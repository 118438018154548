@import "../../themes/colors.scss";
.ribbon-2 {
  --f: 10px;
  /* control the folded part*/
  --r: 15px;
  /* control the ribbon shape */
  --t: -22px;
  /* the top offset */
  min-width: 70px;
  padding: 5px 20px;
  height: 33px;
  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: orange;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;
}
.overview-container {
  border-radius: 7px;
  width: 100%;
  padding: 10px;
}

.slick-initialized .slick-slide {
  margin-top: 10px;
}
.loader {
  display: block;
  position: relative;
  height: 5px;
  width: 140px;
  background-image: linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 5px auto;
  background-position: 0 0, 20px 0, 40px 0, 60px 0;
  animation: pgfill 1s linear infinite;
  margin-bottom: 7px;
  margin-top: 34px;
}
.detailsComp-div1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 10px;
}

@keyframes pgfill {
  0% {
    background-image: linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0);
  }

  25% {
    background-image: linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0);
  }

  50% {
    background-image: linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0);
  }

  75% {
    background-image: linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#fff 20px, transparent 0);
  }

  100% {
    background-image: linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#1065b2 20px, transparent 0),
      linear-gradient(#1065b2 20px, transparent 0);
  }
}

.click:hover {
  transform: scale(1.01);
  transition-duration: 0.4s;
}

.SPnewReportSelect {
  padding: 4px 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 600px) {
  .SPnewReportSelect {
    padding: 4px 10px;
    border-radius: 5px;
  }
}

.countryDrop-div2,
.countryDrop-div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.countryDrop-div1 {
  width: 100%;
  justify-content: space-between;
}
.countryDrop-text1 {
  margin-right: 10px;
  width: 80px;
}

.SPnewreportDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin: 10px 0px 5px 0px;
  max-height: 40vh;
  overflow: scroll;
}

@media (max-width: 600px) {
  .SPnewreportDiv {
    gap: 10px;
    margin: 10px 0px;
  }
}

.SPnewReoprtSK {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  gap: 10px;
}
.aautiReport-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}
.category-box-gig-map {
  border: 1px solid #e5e9ed;
  width: 140px;
  display: flex;
  min-height: 135px;
  max-height: 135px;
  flex-direction: column;
  // justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.category-box-gig-map img {
  width: 140px;
  height: 100px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  object-fit: fill;
  transform-origin: 50% 0%;
  transition: 0.2s all ease-in-out;
}

.category-box-gig-map:hover img {
  width: 140px;
  height: 80px;
}

#global_list_component {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .main-cont {
    height: inherit;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0% 2% !important;
  }
  .main-filter-cont {
    background: #fff;
    // border-radius: 5px;
    // box-shadow: 0 2px 8px 0 #63636333;
    margin-top: 10px;
    height: inherit;
    width: 280px;
  }
  .content-main2 {
    width: 100%;
    flex-grow: 1;
    overflow: scroll;
    height: 83vh;
    padding: 5px;
    .header_text {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
      margin-bottom: 10px;
    }
    .main-cont-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      height: 100%;
    }
  }
}

@media (max-width: 600px) {
  #global_list_component {
    .main-cont {
      flex-direction: column;
      padding: 0px 6px !important;

      .main-filter-cont {
        width: 100%;
        background-color: transparent;
        padding: 0px;
        height: fit-content;
        max-width: 100%;
        min-width: 100%;
        box-shadow: none;
      }
      .content-main2 {
        width: 100%;
        height: 73vh;
        flex-grow: 0;
      }
    }
  }
}

/* AutoScroll.css */
.scrolling-container {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.no-scrolling {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.scrolling-content {
  display: flex;
  flex-direction: row;
  gap: 4px;
  transform: translateX(0);
  transition: transform 1s linear;
}

.scrolling-container:hover .scrolling-content {
  transform: translateX(-100%);
}

//container layout
.container-layout {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 74vh;
}

.container-layout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 20%;
  align-items: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
  .container-layout-header {
    width: 52%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .container-layout-header {
    width: 71%;
    justify-content: flex-end;
  }
}

.search-input {
  min-width: calc(100% - 49px);
  max-width: 400px;
}

.search-input-desktop {
  min-width: 40%;
}

.add-button {
  width: 38px;
  height: 38px;
  border-radius: 4px;
  background-color: $Aauti_theme_color;
  color: #fff;
  cursor: pointer;
}

.tab {
  width: 100%;
  border-radius: 6px;
  padding: 20px;
  border: 1px solid #5c5b5729;
  margin-bottom: 15px;
  position: relative;
}
.text-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .tab {
    padding: 10px !important;
    margin-bottom: 5px !important;
  }
}

//navButton.js
.flex-container {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
}

.buttons-cont {
  justify-content: flex-end;
  margin-top: 15px;
}

.save-button-main {
  background-color: #3083ef;
  padding: 10px 25px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 4;
  border: none;
  color: #fff;
}

.outlined-nav-btn {
  border: 1px solid #3083ef;
  padding: 10px 25px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 4;
  color: #3083ef;
  background-color: transparent;
}

.alignFlex {
  align-items: flex-start;
}

.add-list {
  display: flex;
  flex-direction: row;
  // gap: 10px;
}
.address-card {
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #5c5b5729;
  // margin-bottom: 15px;
  cursor: pointer;
  width: 45%;
  min-width: 300px;
  align-content: flex-start;
  text-align: left;
}
.active-class {
  background-color: #2076e50a;
  border-color: #2076e5 !important;
}

.adress-card-width {
  width: 75%;
}

@media (max-width: 600px) {
  .buttons-cont {
    margin-top: 1px;
  }

  .flex-container {
    flex-wrap: wrap;
  }
  .address-card {
    width: 100%;
  }
}

#verification_by_otp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: calc(100vh - 150px);
  width: 100%;

  .verify_height_auto {
    height: calc(100vh - 150px) !important;
  }

  .display_flex_cont {
    width: 80%;
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
    // border: 1px solid grey;
  }
  .otp_verify_cont {
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .otp_verify_img {
    width: 50%;
    height: 100%;
  }
  .only_otpShow {
    width: 50%;
    height: 100%;
    padding: 15px;
  }
  .only_otpShow_form {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .join_button_verify_otp {
    border: none !important;
    margin-top: 10px !important;
  }

  .verify_btn_display_none {
    display: none;
  }

  .verify_width_100 {
    width: 100%;
  }

  .box_shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }

  .verify_success_text {
    color: #1e1e1e !important;
    font-family: Helvetica !important;
    margin-bottom: 10px;
  }
  .verify_success_text_sub {
    color: #5c5b57 !important;
    font-family: Helvetica !important;
  }
}
// bank styles
.imageDivStyle {
  height: 90px;
  width: 150px;
  margin-top: 15px;
}
.imageStyle {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 6px;
}
.textStyle4 {
  color: #1e1e1e;
}
.bank-div-49 {
  width: 49%;
  flex-direction: column;
  display: flex;
  row-gap: 5px;
  @media (max-width: 650px) {
    width: "100%";
  }
}
// bank styles
.imageDivStyle {
  height: 90px;
  width: 150px;
  margin-top: 15px;
}
.imageStyle {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 6px;
}
.textStyle4 {
  color: #1e1e1e;
}
.bank-div-49 {
  width: 49%;
  flex-direction: column;
  display: flex;
  row-gap: 5px;
  @media (max-width: 650px) {
    width:"100%";
    }
}

.address_card_full_width {
  width: 100%;
  min-width: 100%;
}
