@import "../../themes/colors.scss";

.view-all-gigs-container {
  padding: 16px;
}
.categories-map-gig {
  width: 100%;
  display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // gap: 12px;
}

video::-internal-media-controls-download-button {
  display: none;
}

.detailsComp-div1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 10px;
}
.detailsComp-div2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.subcategory-count-gig {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
}

.overview-container-gig-details {
  // border-radius: 7px;
  width: 100%;
  // padding: 10px;
  margin-top: 15px;
}

.overview-container3-details {
  border-radius: 7px;
  width: 100%;
  // padding: 10px;
  margin-top: 10px;
  background: #fff;
  // padding: 15px;
  // border: 1px solid #f2f2f2;
  border-radius: 6px;
  cursor: pointer;
  // margin-top: 15px;
}

.title-view-all-cont-gig {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.allCard-video1 {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.allCard-div1 {
  height: 100%;
}

.allCard-div2 {
  width: 100%;
  height: 100%;
  position: relative;
}

.allCard-div3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.allCard-div4 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  height: 10%;
}

.allCard-div5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60%;
  justify-content: space-between;
  position: relative;
}

.allCard-div6 {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allCard-div7 {
  width: 20%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  justify-content: flex-end;
}

.allCard-div8 {
  width: 100%;
  padding-top: 15px;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-image: linear-gradient(
    -180deg,
    rgba(201, 201, 201, 0) 3.070175438596502%,
    rgba(69, 69, 69, 0.65) 40.1885964912281%,
    #000000 98.24561403508774%
  );
}

.allCard-div9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.allCard-div10 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.allCard-div11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
}

.allCard-button1 {
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.allCard-text1 {
  color: #000000;
}

.allCard-text2 {
  color: #ffffff;
}

.allGigs-div1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  padding: 15px;
}

.allGigs-div2 {
  height: fit-content;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
}

.allGigs-div3 {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  width: calc(100% - 280px);
}

.allGigs-div5,
.allGigs-div4 {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.allGigs-div4 {
  margin-top: 20px;
  margin-left: 10px;
}

// .gigLoader-div1 {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 100%;
// }

// .gigLoader-div2 {
//   width: 68%;
// }

// .gigLoader-div3 {
//   display: flex;
//   flex-direction: row;
//   gap: 10px;
// }

// .gigLoader-div4 {
//   width: 60%;
// }

// .gigLoader-div5 {
//   width: 100%;
// }

// .gigLoader-div6 {
//   width: 30%;
// }

.main-div1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
  overflow-x: scroll;
}

.previewCont-div1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.previewCont-div2 {
  overflow: hidden;
  color: grey;
  width: 120px;
}

.gigPreview-div1 {
  width: 90%;
  // margin-top: 10px;
  flex-direction: row;
  display: flex;
  // padding: 0% 5%;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
}

.gigPreview-div2 {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
}

.gigPreview-div3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.gigPreview-div5,
.gigPreview-div4,
.gigPreview-div6,
.gigPreview-div7 {
  flex-direction: row;
  display: flex;
}

.gigPreview-div4 {
  margin: 10px 0px;
  justify-content: space-between;
}

.gigPreview-div6,
.gigPreview-div7 {
  background-color: #f9ef99;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.gigPreview-div7 {
  background-color: $Aauti_theme_color;
  cursor: pointer;
}

.gigPreview-div6 {
  margin-right: 15px;
}
.tag-gigPreview {
  background-color: $TAG_BACKGROUND;
  padding: 2px 4px 2px;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gig-details {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gigPreview-div8 {
  padding: 8px;
  border: 2px solid $BORDER_COLOR;
  border-radius: 6px;
  margin-top: 10px;
}

.gigPreview-div9 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.gigPreview-div9 div {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.gigPreview-div10 {
  margin-top: 15px;
}

.gigPreview-div11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
}

.gigPreview-div12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 10px;
}

.gigPreview-div13 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.gigPreview-div14-details {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  // margin-bottom: 10px;
}

.gigPreview-avatar1 {
  height: 26px !important;
  width: 26px !important;
}

.gigPreview-avatar1 {
  height: 26px !important;
  width: 26px !important;
}

.gigPreview-div21 {
  margin-top: 20px;
  border: 1px #dedede solid;
  border-radius: 6px;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gigPreview-img1 {
  width: 100% !important;
  // object-fit: cover;
  border-radius: 5px;
  margin-bottom: 15px;
  height: 400px !important;
}
@media (max-width: 600px) {
  .gigPreview-img1 {
    height: 200px !important;
  }
}
.allGigs-div2 {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  background-color: #fff;
  padding: 0px;
}
.gigPreview-img2 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.gigPreview-text1 {
  margin: 10px 0px 0px 0px;
  color: $PARAGRAPH_COLOR;
}

.gigPreview-text2 {
  margin-right: 7px;
  cursor: pointer;
}

.gigPreview-text3 {
  color: #ffffff;
  align-self: center;
  margin: 0px 5px;
}

.cancellation-polict-container-previc {
  margin-top: 10px;
  width: 100%;
  border: 2px solid #f2f2f2;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.gigPreview-text5 {
  margin-bottom: 15px;
}

.gigPreview-text6 {
  color: $PARAGRAPH_COLOR;
  // margin-bottom: 5px;
  margin-left: 5px;
}
.topic-name-container:hover {
  background-color: #3083ef1a;
}
.gigPreview-text6-time {
  color: $PARAGRAPH_COLOR;
  margin-left: 5px;
}

.gigPreview-text7 {
  margin-bottom: 10px;
}

.gigPreview-text8 {
  color: $PARAGRAPH_COLOR;
  margin-bottom: 10px;
  // margin-left: 5px;
}

.gigPreview-text9 {
  cursor: pointer;
  color: $Aauti_theme_color;
}

.gigPreview-text10 {
  color: #1e1e1e;
}

.gigPreview-text11 {
  color: $Aauti_theme_color;
  cursor: pointer;
}

.gigPreview-text12 {
  color: #1e1e1e;
}

.gigPreview-text13 {
  cursor: pointer;
  color: $Aauti_theme_color;
  margin-left: 5px;
}

.gigPreview-text15 {
  font-size: 7px;
  max-width: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: center;
}

.gigPreview-icon1 {
  color: #ffffff;
  margin-right: 5px;
}

.gigPreview-icon2 {
  margin-right: 8px;
  cursor: pointer;
}

.similarGigs-div1 {
  width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.similarGigs-div2 {
  height: fit-content;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
}

.similarGigs-div3 {
  height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
}

.similarGigs-div4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // height: 100%;
  gap: 10px;
  width: 100%;
}

.similarGigs-div5 {
  width: 263px;
}

.similarGigs-div6 {
  width: 100%;
}

.viewAllGigs-div1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 88vh;
  width: 98%;
  overflow: scroll;
}

.viewAllGigs-div2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  margin-bottom: 10px;
}

.viewAllGigs-div3 {
  display: flex;
  align-items: center;
}

.viewAllGigs-div4 {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  margin-left: 5px;
}

.viewAllGigs-div5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px;
}

.viewAllGigs-text1 {
  color: #00000099;
  margin-right: 10px;
}

.viewAllGigs-icon1 {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: $Aauti_theme_color;
  color: #fff;
  cursor: pointer;
}

.myCourses-div8 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 8px;
}

@media screen and (max-width: 600px) {
  .allGigs-div2 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    background-color: #fff;
    padding: 0px;
  }
  .allGigs-div1 {
    flex-direction: column;
    width: 100%;
  }
  .gigPreview-div1 {
    display: contents;
    overflow: scroll;
  }
  .gigPreview-div9 div {
    width: 60%;
  }
  // .gigPreview-div2 {
  //   height: 60vh;
  // }
  .similarGigs-div1 {
    flex-direction: column;
  }
  .similarGigs-div2 {
    width: 98%;
    min-width: 98%;
    max-width: 98%;
  }
  .allGigs-div3,
  .similarGigs-div3 {
    width: 100%;
  }
  .similarGigs-div4 {
    flex-direction: column;
    // height: 69vh;
    overflow: scroll;
  }

  .allGigs-div4 {
    margin-top: 10px;
  }

  .allCard-div8 {
    width: 95%;
  }

  .viewAllGigs-div1 {
    width: 100%;
  }

  .viewAllGigs-div5 {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.hover-me {
  display: none;
}

.hover-main:hover .hover-me {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: none;
}
