

.slider-arrow {
    position: absolute;
    // height: 100%;
    width: 100%; 
}

.arrow-btn {
    top: 46%;
    z-index: 1;
    left: 3px;
}

.next {
    float: right;
}

.right-arrow,
.left-arrow {
    color: #55a4ea78
}

.right-arrow:hover {
    color: #3997eadb;
}

.left-arrow:hover {
    color: #3997eadb;
}








 