@import "../../themes/colors.scss";

.AautiForInstitution1Main {
  width: 80%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

@media (max-width: 600px) {
  .AautiForInstitution1Main {
    width: 98%;
    flex-direction: column !important;
    margin-top: 10px;
    justify-content: center;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution1Main {
    width: 98%;
    margin-top: 20px;
  }
}

.saveMain {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

@media (max-width: 600px) {
  .saveMain {
    flex-direction: column !important;
    justify-content: center;
    margin: 15px 0px;
    width: 96%;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .saveMain {
    margin-top: 30px;
  }
}

.AautiForInstitution1chield1 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .AautiForInstitution1chield1 {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution1chield1 {
    width: 48%;
  }
}

.savechield1 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .savechield1 {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .savechield1 {
    width: 50%;
  }
}

.FocusScreen {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 7.5%;
}

@media (max-width: 600px) {
  .FocusScreen {
    width: 100%;
    padding-left: 0px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .FocusScreen {
    width: 49%;
    padding-left: 1%;
  }
}

.AautiForInstitution1text1 {
  font-size: 45px;
  line-height: 1.6;
  margin: 0px;
  margin-bottom: 30px;
  font-family: Montserrat, sans-serif;
}

@media (max-width: 600px) {
  .AautiForInstitution1text1 {
    font-size: 24px;
    line-height: auto;
    margin: 0px;
    margin-bottom: 10px;
    font-family: Montserrat, sans-serif;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution1text1 {
    font-size: 26px;
    line-height: auto;
    margin: 0px;
    margin-bottom: 10px;
  }
}

.FSsubText {
  margin-bottom: 40px;
  color: #393737;
  line-height: 32px;
}

@media (max-width: 600px) {
  .FSsubText {
    margin-bottom: 10px;
    line-height: 22px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .FSsubText {
    margin-bottom: 15px;
    line-height: 28px;
  }
}

.JoinButton {
  height: 50px;
  width: 160x !important;
  background: #078dda;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: 600;
}

.explore {
  height: 50px;
  width: 180px;
  background: #078dda;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: 600;
}

.AautiForInstitution1chield2 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .AautiForInstitution1chield2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution1chield2 {
    width: 50%;
  }
}

.savechield2 {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-right: 5%;
}

@media (max-width: 600px) {
  .savechield2 {
    width: 100%;
    margin-right: 0px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .savechield2 {
    width: 50%;
    margin-right: 0px;
  }
}

.STtext {
  margin: 20px 0px;
}

@media (max-width: 600px) {
  .STtext {
    margin: 10px 0px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .STtext {
    margin: 15px 0px;
  }
}

.AautiForInstitution1imagestyle {
  width: 100%;
  height: 540px;
  // z-index: 1000000;
  border-radius: 20px;
}

@media (max-width: 600px) {
  .AautiForInstitution1imagestyle {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    margin-left: 0px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .AautiForInstitution1imagestyle {
    width: 100%;
    margin-left: 0px;
    height: 300px;
  }
}

@media (min-width: 901px) and (max-width: 1050px) {
  .AautiForInstitution1imagestyle {
    width: 85%;
    margin-left: 10%;
    height: 400px;
  }
}

.AautiForInstitution1imagestyle2 {
  width: 100%;
  height: 600px;
  border-radius: 10px;
}

.AautiForInstitution1imagestyle3 {
  width: 70%;
  height: 280px;
  border-radius: 10px;
  align-self: center;
}

@media (max-width: 600px) {
  .AautiForInstitution1imagestyle3 {
    height: 180px;
    margin: 10px 0px;
    width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution1imagestyle3 {
    height: 250px;
  }
}

.AautiSaveText {
  font-size: 34px;
  margin: 20px 0px;
  font-family: Montserrat, sans-serif;
}

@media (max-width: 600px) {
  .AautiSaveText {
    font-size: 20px;
    margin: 10px 0px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiSaveText {
    font-size: 24px;
    margin: 15px 0px;
  }
}

.AautiForInstitution2map {
  flex-flow: wrap;
  display: flex;
  gap: 20px;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .AautiForInstitution2map {
    margin-bottom: 10px;
    gap: 10px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution2map {
    margin-bottom: 15px;
    gap: 5px;
  }
}

.AautiForInstitution2mapDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 48%;
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution2mapDiv {
    width: 49%;
  }
}

.FocusSlider {
  margin-top: 70px;
  width: 100%;
}

@media (max-width: 600px) {
  .FocusSlider {
    width: 96%;
    margin-bottom: 15px;
  }
}

.FocusDotImage {
  top: 33px;
  height: 100px;
  width: 150px;
  position: absolute;
}

@media (max-width: 600px) {
  .FocusDotImage {
    display: none;
  }
}

.FocusNullImage {
  height: 400px;
  width: 380px;
  top: 0px;
  background-color: rgb(218, 238, 250);
  position: absolute;
  display: flex;
  align-self: flex-end;
}

@media (max-width: 600px) {
  .FocusNullImage {
    display: none;
  }
}

.RoofScreenDiv {
  justify-content: space-between;
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  width: 85%;
  align-items: center;
}

@media (max-width: 600px) {
  .RoofScreenDiv {
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .RoofScreenDiv {
    margin-top: 35px;
    width: 98%;
  }
}

.RoofMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  // z-index: -2;
}

@media (max-width: 600px) {
  .RoofMain {
    width: 96%;
    margin-bottom: 15px;
  }
}

.saveStylishImage {
  object-fit: fill;
  width: 85%;
  position: relative;
  height: 75vh;
}

@media (max-width: 600px) {
  .saveStylishImage {
      width: 100%;
      height: 300px;
      align-self: center;
      margin-bottom: 10px;
    }
}



@media (min-width: 601px) and (max-width: 1050px) {
  .saveStylishImage {
    width: 95%;
    height: 350px;
    align-self: flex-start;
    margin-bottom: 15px;
  }
}

.saveDotImage {
  top: 1091px;
  height: 100px;
  width: 150px;
  position: absolute;
  left: 34%;
}

.savestartImage {
  top: 700px;
  height: 520px;
  width: 530px;
  left: 150px;
  z-index: 200000000;
  position: absolute;
}

.saveRectangleImage {
  top: 725px;
  height: 515px;
  width: 400px;
  position: absolute;
}

.AautiForInstitution2mapicon {
  color: palegreen;
  font-size: 32px;
  margin-right: 12px;
  align-self: flex-start;
}

.AautiForInstitution3map {
  flex-flow: wrap;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 600px) {
  .AautiForInstitution3map {
    gap: 10px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution3map {
    gap: 10px;
  }
}

.ORSsubText {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .ORSsubText {
    margin-bottom: 10px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .ORSsubText {
    margin-bottom: 10px;
  }
}

.ORSsubTextLineHight {
  color: #393737;
  line-height: 25px;
}

@media (max-width: 600px) {
  .ORSsubTextLineHight {
    line-height: 20px;
  }
}

.AautiForInstitution3mapdiv {
  display: flex;
  flex-direction: column;
  width: 49%;
}

@media (max-width: 600px) {
  .AautiForInstitution3mapdiv {
    margin-bottom: 0px;
    width: 48%;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution3mapdiv {
    margin-bottom: 0px;
    width: 48%;
  }
}

.AautiForInstitution3circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.AautiForInstitution6main1 {
  width: 100%;
  background-color: #212529;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 50px 0px;
  margin-top: 50px;
}

@media (max-width: 600px) {
  .AautiForInstitution6main1 {
      padding: 20px 0px;
      margin-top: 10px;
      width: 100%;
    }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .AautiForInstitution6main1 {
    padding: 40px 0px;
    margin-top: 40px;
  }
}

.AautiForInstitution6main2 {
  width: 85%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .AautiForInstitution6main2 {
    flex-direction: column;
    justify-content: center;
    width: 95%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .AautiForInstitution6main2 {
    width: 98%;
  }
}

@media (min-width: 901px) and (max-width: 1050px) {
  .AautiForInstitution6main2 {
    width: 85%;
  }
}

.AautiForInstitution6chield1 {
  width: 45%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .AautiForInstitution6chield1 {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .AautiForInstitution6chield1 {
    width: 46%;
  }
}

@media (min-width: 901px) and (max-width: 1050px) {
  .AautiForInstitution6chield1 {
    width: 45%;
  }
}

.AautiForInstitution6chield2 {
  width: 40%;
  display: flex;
}

@media (max-width: 600px) {
  .AautiForInstitution6chield2 {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .AautiForInstitution6chield2 {
    width: 51%;
  }
}

@media (min-width: 901px) and (max-width: 1050px) {
  .AautiForInstitution6chield2 {
    width: 45%;
  }
}

.AautiForInstitution6chield2div {
  background-color: grey;
  width: 80%;
  border-radius: 10px;
  border: 1px solid lightgrey;
  align-self: flex-end;
}

.my-input::placeholder {
  color: white;
}

.my-input {
  height: 25px;
  padding: 10px;
  width: 95%;
  border-radius: 8px;
  border: 1px solid lightgrey;
  background-color: #424549;
  color: white;
  outline: none;
  margin-bottom: 25px;
  font-size: 18px;
}

@media (max-width: 600px) {
  .my-input {
    height: 20px;
    padding: 10px;
    width: 93%;
    border-radius: 8px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@media (min-width: 601px) and (max-width: 1050px) {
  .my-input {
    width: 93%;
    margin-bottom: 8px;
  }
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
  border-radius: 5px !important;
  background-color: #424549 !important;
  color: white !important;
  margin-bottom: 20px !important;
  border: 1px solid #ffffff !important;
}

@media (min-width: 601px) and (max-width: 1050px) {
  .react-tel-input .form-control {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 600px) {
  .react-tel-input .form-control {
    height: 35px !important;
    margin-bottom: 10px;
  }
}

.AautiForInstitution6submit {
  height: 45px;
  width: 100%;
  background: #078dda;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  margin-top: 25px;
  cursor: pointer;

}

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dotDiv {
  width: 40px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
}

.doubtscreenDiv {
  background-color: #424549;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  border: 0.1px solid #5c5f5fc7;
  align-self: flex-end;
}

@media (max-width: 600px) {
  .doubtscreenDiv {
    align-self: center;
    padding: 10px;
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .doubtscreenDiv {
    width: 98%;
    padding: 10px;
  }
}

@media (min-width: 901px) and (max-width: 1050px) {
  .doubtscreenDiv {
    padding: 20px;
  }
}

.doubtscreenDivInput {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 5px;
  height: 40px;
  outline: none;
  padding-left: 8px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(66, 69, 73);
}

@media (max-width: 600px) {
  .doubtscreenDivInput {
    height: 35px;
    margin-bottom: 10px;
  }
}


.doubtscreenDivbutton {
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: 1px solid rgb(255, 255, 255);
  margin-top: 16px;
}

@media (max-width: 600px) {
  .doubtscreenDivbutton {
    height: 35px;
    margin-top: 10px;
  }
}
// bank details
.details-admin-card {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .details-admin-card {
    margin-bottom: 10px;
    flex-direction: column;
  }
}
.bank-admin-image-dialog {
  width:100%;
  height:100%
}