#READT_TO_START {
    margin-left: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    margin-right: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    border: solid 1px; // Adjust border width as needed
    height: calc(250px + (300 - 250) * ((100vw - 320px) / (1550 - 320)));
    border-radius: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #1E1E1E;
    margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
    margin-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));


    .readt_to_start_container {
        padding-left: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));




        .readt_to_start_label {
            font-family: "HelveticaNeueBold", sans-serif;
            font-size: calc(24px + (56 - 24) * ((100vw - 320px) / (1550 - 320)));
            font-weight: 700;
            line-height: calc(40px + (80 - 40) * ((100vw - 320px) / (1550 - 320)));
            text-align: left;
            color: #ffffff;

            .readt_to_start_span {
                background-color: #3083EF;
                border-radius: 20px;
                padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
                padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
            }

        }

    }

    .readt_to_start_store_container {
        // border: solid;
        padding-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
    }
}

@media screen and (max-width: 899px) {
    #READT_TO_START {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 25px;
        height: max-content;
        margin: 10px;

        .readt_to_start_container{
            padding-left: 0px;
            margin-bottom: 10px;
            .readt_to_start_label{
                padding-left: 0px;
                text-align: center;
            }
        }


        .readt_to_start_store_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-right:0px ;

            .readt_to_start_store_button {
                width: calc(150px + (201 - 150) * ((100vw - 320px) / (1550 - 320)));
                height: calc(60px + (81 - 60) * ((100vw - 320px) / (1550 - 320)));
            }
        }


    }
}