.ns-main-container {
  padding: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: center;
}
.ns-sub-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.card-container {
  border: 1px solid #eeeeee;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.main-container-at {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .ns-main-container {
    width: 100%;
  }
}
