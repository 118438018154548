@import "../../../src/themes/colors.scss";
.childMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
}
.each-child {
  width: 100%;
  border: 1px solid #dddddd;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.coupon-discount {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.T0Pnodata {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.Waiting-con-detai {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: #fbfbfb;
  padding: 5px 10px;
  border-bottom: 1px solid #5c5b5733;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.weekName-item {
  display: flex;
  flex-direction: row;
  // margin-top: 10px;
  width: fit-content;
  justify-content: center;
}
.weekNaem {
  background-color: #3083ef;
  border-radius: 6px;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  // margin-left: 10px;
  justify-content: center;
}
.some-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #dddddd;
  padding: 5px;
  border-radius: 5px;
  // margin-bottom: 5px;
  margin-right: 5px;
  // width: 31%;
}
.navigation-class {
  display: flex;
  cursor: pointer;
  // align-self: flex-end;
  width: 100%;
}
.child-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.avatar-img {
  margin-right: 20px;
}

.checkout-card {
  border: 1px solid lightgrey;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 10px;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #e5e9ed;
  margin-top: 10px;
  // box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
  //   rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}
.add-btn-coupon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  align-items: center;
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  cursor: pointer;
}
.cartCard {
  width: 100%;
  margin-right: 50px;
  padding: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  height: auto;
  min-height: 150px;
  border: 1px solid #e5e9ed;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  // box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
  //   rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}
.available-timings-details {
  display: flex;
  flex-wrap: row;
  justify-content: space-around;
  width: 100%;
  background-color: lightgrey;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 10px;
}
.timing-type {
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.students-count-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cartCard1 {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.content-con-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.pop-up-coupons-heade {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // margin-bottom: 10px;
}

.each-coupon-pop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #dddddd;
}
.code-contnet {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #a9a9a9;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.terms-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  align-items: flex-start;
  justify-content: flex-start;
}

.family-discount {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.family-discount.expand {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  // margin-bottom: 4px;
}

.hide-expand {
  display: none;
}

.family-btn {
  background-color: transparent;
  padding: 0px !important;
  border: none;
  align-self: flex-start;
  display: flex;
  color: #2076e5;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
