
.security-options{
    &-list{
        padding: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media (min-width: 992px){
            width: 50%;
            justify-self: center;
        }
        
    }
    &-item{
        width: 100%;
        &-wrapper{
            padding: 12px 20px;
            width:100%;
            border: 1px solid #eeeeee;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 2.5px;
        }

    }
}