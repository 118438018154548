.render-header-tabs-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-self: center;
  margin-bottom: 10;
}
.head {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box-styles {
  display: flex;
  width: 100%;
  border-radius: 1.5;
  box-shadow: 0px 1px 1px 0.5px #d0dfe6,
    /* Bottom shadow */ 0px -1px 1px 0.5px #d0dfe6,
    /* Top shadow */ 1px 0px 1px 0.5px #d0dfe6,
    /* Right shadow */ -1px 0px 1px 0.5px #d0dfe6; /* Left shadow */
}
.return-type-tabs {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}
.sp-titles-text {
  background-color: #f5f8fc;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}
.sp-titles-div {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
}
.sp-attendance-titles {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}
.sp-attendance-text {
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
}
.search-text-input {
  align-self: center;
  justify-content: center;
}
.batches-students-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  overflow: scroll;
  cursor: pointer;
}
.each-course {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid lightgray !important;
}

// right side
.sp-header-div {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.sp-card-item-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100% !important;
}
.sp-header-item-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid lightgray !important;
}
.count-styles {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
}
.bactches-students-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.courses-batches-students-div {
  width: 100%;
}
.accordian-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.students-div {
  display: flex;
  flex-direction: row;
  border: 1px solid #f0f0f0;
  padding: 10px;
  align-items: center;
  border-radius: 6px;
  gap: 10px;
}
// search
.search-styles {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-bottom: 10px;
}
.no-data-found {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sp-card-main-div {
  overflow: scroll;
  width: 100%;
}
.sp-card-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.sp-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
}
.batch-details-div {
  display: flex;
  flex-direction: column;
}
.accordian-styles {
  border-radius: 0px;
  width: 100% !important;
}
.accordian-details-main-div {
  display: flex;
  flex-wrap: wrap;
}
.average-ratings-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
// skeleton

.TRloadingdiv1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .TRloadingdiv1 {
    flex-direction: column;
  }
}

.TRloadingdiv2 {
  width: 100%;
}
@media (max-width: 600px) {
  .TRloadingdiv2 {
    width: 100%;
  }
}

.TRloadingdiv3 {
  width: 69%;
}
@media (max-width: 600px) {
  .TRloadingdiv3 {
    width: 100%;
  }
}

.TRboxShadowDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 6px;
  height: 80vh;
  //   box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
  //     rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
@media (max-width: 600px) {
  .TRboxShadowDiv {
    flex-direction: column;
    height: 50vh;
    row-gap: 10%;
  }
}

.TRleftmaindiv1 {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  width: 30%;
  border-right: 1px #dedede solid;
}
@media (max-width: 600px) {
  .TRleftmaindiv1 {
    width: 100%;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .TRleftmaindiv1 {
    width: 50%;
  }
}
.TRscrollDiv {
  flex-direction: column;
  overflow: scroll;
  height: 65vh;
  margin-top: 10px;
  display: flex;
}
@media (max-width: 600px) {
  .TRscrollDiv {
    height: 35vh;
  }
}
.cartCard1 {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.table-cell-styles {
  font-weight: bold !important;
}
.sp-card-loading {
  padding: 10px;
}
// show more styles

.show-more-main-div{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.description-div{
    display: flex;
    justify-content: flex-end;
    align-items:flex-end;
}
.ratings-show-div{
    width: 100% !important;
    align-items:flex-start !important
}

//  showmore text

.text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  transition: all 0.3s ease-in-out;
  white-space: normal;
}

.text-truncate.expanded {
  -webkit-line-clamp: unset; 
  white-space: normal;
}

.show-more-less {
  cursor: pointer;
  color: #078dda; 
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
