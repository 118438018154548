// @import "~react-image-gallery/styles/css/image-gallery.css";
// @import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../../themes/colors.scss";

.title-view-all-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.subCourses-div4 {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}
.spTagsDivUserCard {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  overflow-x: auto;
  max-width: 60%;
  justify-content: flex-start;
  white-space: nowrap;
}
@media (max-width: 600px) {
  .spTagsDivUserCard {
    max-width: 100%;
  }
}
.TPFollowTextDiv-userCard {
  display: flex;
  flex-direction: row;
  background-color: $Aauti_theme_color;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 5px;
  border: none;
  min-height: 30px;
  justify-content: center;
  align-items: center;
  width: 100px;
}
@media (max-width: 600px) {
  .TPFollowTextDiv-userCard {
    min-height: 25px;
    width: 80px;
  }
}
.cancelationPolicy-con {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.recenlty-updated-btn {
  width: 100%;
  display: flex;
  margin-top: -25px !important;
  // margin-left: 0px !important;
  z-index: 410;
  position: relative;
}
.recently-badge {
  background-color: $yello_tag_bG;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
}

.subCourses-text1 {
  margin: 5px;
}

.subCourses-text1 {
  margin: 5px;
}

// .image-gallery-left-nav,
// .image-gallery-right-nav {
//   padding: 50px 10px;
//   top: 50%;
//   transform: translateY(-50%);

//   .image-gallery-svg {
//     height: 70px !important;
//     width: 30px !important;
//   }

//   @media (max-width: $ig-small-screen) {
//     .image-gallery-svg {
//       height: 72px;
//       width: 36px;
//     }
//   }

//   @media (max-width: $ig-xsmall-screen) {
//     .image-gallery-svg {
//       height: 48px;
//       width: 24px;
//     }
//   }

//   &[disabled] {
//     cursor: disabled;
//     opacity: 0.6;
//     pointer-events: none;
//   }
// }

/* Filename - App.css*/
.Batches {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(102, 104, 102);
  width: 800px;
  height: 200px;
  display: flex;
  flex-direction: row;
}

.th {
  border-bottom: 1px solid black;
}

.td {
  text-align: center;
}

.course-details {
  width: 100%;
  // height: 100vh;
  padding-bottom: 20px;
  overflow: scroll;
  margin-top: 10px;
  // background-color: #fafafa;
}

// "https://img.freepik.com/free-photo/education-concept-books-laptop-library_1150-10587.jpg
.course-img-con {
  width: 100%;
  height: 50vh;
  // background-image: url("https://img.freepik.com/free-photo/education-concept-books-laptop-library_1150-10587.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  //   background-color: rgba(0, 0, 0, 0.6);
}

.main-details {
  opacity: 10;
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  // align-items: center;
}

.shaer-icons {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
  padding: 7px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.michale-im {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.profile,
.profile2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #f2f2f2;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.profile3-user-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding: 15px;
  cursor: pointer;
  gap: 10px;
}
@media (max-width: 600px) {
  .profile3-user-card {
    padding: 5px;
  }
}
.profile1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background-color: red;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.profile2 {
  flex-direction: column;
  height: auto;
  width: 30%;
  padding: 15px;
}

.profile3 {
  cursor: pointer;
  gap: 15px;
  border: 1px solid #f2f2f6;
  border-radius: 4px;
  padding: 15px;
}

.courses-headbackground {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: column;
}

.head-top-card {
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: center;
  // margin-top: 350px;
  border-style: solid;
  border-width: 1px;
  border-color: #f2f2f2;
  // margin-left: 15px;
}

.head-top-card-con {
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #f2f2f2;
}

.laptop-img {
  width: 90%;
  height: 60%;
  margin-bottom: 20px;
  border-radius: 5px;
}

.content-con1-details {
  // background-color: #fafafa;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // margin-top: 15px;
}

.content-con {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left-content-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: #ffffff;
  // border: 1px solid #f2f2f2;
  // border-radius: 8px;
  // padding: 10px;
}

.right-content-details {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.overview-container-rating {
  width: 100%;
  row-gap: 5px;
  display: flex;
  flex-direction: column;
}

.overview-container4 {
  width: 100%;
}

.overview-container3 {
  border-radius: 7px;
  width: 100%;
  // padding: 10px;
  margin-top: 8px;
  background: #fff;
  // padding: 15px;
  // border: 1px solid #f2f2f2;
  border-radius: 6px;
  cursor: pointer;
  // margin-top: 15px;
}
.container-realted-documents-map {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}

.overview-container4 {
  margin-bottom: 10px;
  width: 100%;
}

.overview-container2 {
  margin-top: 15px;
  background: #ffffff;
  padding: 15px;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.same-courses-by-sp {
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.details-card-1 {
  margin-top: 20px;
  width: 100%;
  //   margin-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  //   padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.chat-container-style {
  position: fixed;
  bottom: 30px;
  right: 15px;
  width: auto;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #ffffff;
  border-radius: 30px;
  z-index: 100;
  gap: 4px;
  overflow: hidden;

  &.offline {
    background-color: #ff4500;
  }

  &.online {
    background-color: #32cd32;
  }
}

.chat-message-cont {
  position: fixed;
  bottom: 30px;
  right: 15px;
  width: auto;
  // width: 350px;
  background-color: transparent;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #ffffff;
  border-radius: 12px;
  // height: 500px;
  z-index: 100;
  height: 70vh;
  overflow: hidden;
}

.course-review-card,
.course-review-card2 {
  display: flex;
  flex-direction: column;
  width: 250px;
  border: 1px solid #e5e9ed;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
}

.course-review-card2 {
  border: none;
  width: 100%;
}

.rating-show,
.rating-show2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin: 4px 0px;
}

.rating-show-time {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 5px;
}

.rating-show2 {
  gap: 6px;
}

.course-categories {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 10px;
}

.course-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}

.hr-line {
  border-top: 1px solid rgb(238, 231, 231);
  margin-top: 10px;
  margin-bottom: 10px;
}

.spCourseAndClass {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.spCourseAndClass1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
}

.categories-map {
  width: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: space-around;
  flex-wrap: wrap;
  gap: 12px;
}

.Waiting-con-detai {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fbfbfb;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.subcategory-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
}

.category-box {
  border: 1px solid #e5e9ed;
  width: 150px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.some-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #dddddd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  // width: 31%;
}

.available-timings-details {
  display: flex;
  flex-wrap: row;
  justify-content: space-around;
  width: 100%;
  background-color: lightgrey;
  border-radius: 5px;
  padding: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.timing-type {
  padding: 5px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.grp-in-btns {
  display: flex;
  flex-wrap: row;
  justify-content: flex-start;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 8px;
}

.coursetype-btn {
  padding: 10px;
  width: 48%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 4px;
  background-color: #078dda;
  border: none;
}

.report-container,
.report-container2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.report-container2 div {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.report-container2 {
  gap: 15px;
}

.selectedBatch-Item {
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 10px;
}

.weekNaem {
  background-color: #3166ba;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  align-items: center;
  // margin-left: 10px;
  justify-content: center;
}

.weekName-item {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: center;
  // margin-top: 10px;
}

.chooseBatches-con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #ede9e9;
  padding: 10px;
  border-radius: 5px;
}

.level-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.document-img-course {
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
  // padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 82px;
  min-height: 82px;
  max-height: 82px;
  width: 82px;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png");
}

.sp-detaisl {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.details-item2,
.details-item3,
.details-item4,
.details-item5 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-right: 2px solid #aaa1a1;
  padding-right: 10px;
  gap: 4px;
}

.details-item-user {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-right: 2px solid #aaa1a1;
  padding-right: 10px;
  gap: 4px;
}
.courseReviewsABuy-text2 {
  color: #000000;
}

.rightCont-div6-type {
  color: #626972;
}

.details-item-user:hover {
  .courseReviewsABuy-text2 {
    color: $Aauti_theme_color;
  }
  .rightCont-div6-type {
    color: $Aauti_theme_color;
  }
}

.details-item2 {
  padding-left: 0px;
}

.details-item4 {
  margin-left: 5px;
}

.details-item3 {
  border-right: none;
  margin-left: 5px;
}

.details-item5 {
  padding-left: 10px;
  border: none;
}

.TPhighTextdiv {
  display: flex;
  flex-direction: row;
  background-color: rgb(249, 239, 153);
  padding: 4px 6px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

#chat_room_container {
  padding: 5px;
}

#message_container_join {
  .join_class_chat_container {
    height: 100%;
    flex: 1;
    min-width: 10%;
    max-width: 10%;
    margin-left: 20px;
  }

  //.chat_messages_container {
  //  height: 64px;
  //  margin: 0 10px;
  //  display: flex;
  //  flex-direction: row;
  //  background-color: #ffffff;
  //  z-index: 100;
  //}

  .chat_messages_container {
    height: 64px;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    border-radius: 7px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
  }

  //.chat_profile_container {
  //    width: 100%;
  //    padding: 0 10px;
  //box-shadow: inset 7px 0 9px -7px #DDDDDD;
  //}

  .chat_profile_container {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  //.chat_profile_sub_container {
  //  width: 100%;
  //  padding: 10px 0;
  //  margin-left: 25px;
  //  display: flex;
  //  flex-direction: row;
  //  justify-content: space-between;
  //  align-items: center;
  //  background-color: #ffffff;

  //
  //}
  .chat_profile_sub_container {
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
  }

  .search_bar_styles {
    background-color: #e6ebff;
    font-size: 14px;
    height: 40px;
    border-radius: 20px;
    padding: 0 14px;
    outline-style: none;
    color: #404040;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    margin-left: 10px;
  }

  .search_input_styles {
    background-color: #e6ebff;
    font-size: 14px;
    height: 40px;
    border-radius: 20px;
    padding: 0 14px;
    outline-style: none;
    color: #404040;
    border: none;
  }

  //.custom_message_container {
  //  flex: 1;
  //  height: 90%;
  //}

  .custom_message_container1 {
    height: 92%;
    // position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .custom_message_sub_container1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
    padding-top: 10px;
    overflow: auto;
    height: 80%;
  }

  .message_text_input_container {
    width: 100%;
    padding: 10px;
    //border-radius: 20px;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 -7px 9px -7px #dddddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .message_text_input {
    width: 100%;
    max-height: 100px;
    height: 100%;
    background-color: #e6ebff;
    border-radius: 19px;
    margin: 0 10px;
    padding: 10px 16px;
    outline-style: none;
    border: none;
  }

  button.message_send_button {
    height: 45px;
    width: 45px;
    background: #ffffff;
    border: none;
    color: #3586ff;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .chat_header_button {
    padding: 0;
    margin: 0;
    border-style: none;
    outline-style: none;
    background-color: transparent;
    display: flex;
    align-items: center;
  }

  .chat_header_image_container {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    box-shadow: inset 0 0 10px 1px #e6ebff;
  }

  .null_image_container_style {
    display: flex;
    flex: 1;
    border-radius: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
  }

  .chat_header_icon_styles {
    color: #3363ff99;
  }

  .header_user_sub_view {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin-left: 6px;
  }

  .user_text_container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
  }

  .text_title_styles {
    font-size: 20px;
    color: #404040;
    align-items: center;
    font-weight: 600;
    margin-left: 10px;
  }
}

.connect-all-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.back-button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  align-self: flex-start;
  // margin-top: 5px;
}

.connect-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.input-contop {
  border-width: 1px;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 5px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #066cb5;
}

.input-field {
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  padding-left: 5px;
}

.button-each-avatar {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 5px 10px 5px 10px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // cursor: pointer;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.button-each-avatar:hover {
  // border: 1px solid #066cb5;
  background-color: rgb(228, 227, 227);
  border-radius: 5px;
  width: 100%;

  .chat-button-av {
    border: none;
    outline: none;
    background-color: #066cb5;
    padding: 5px 10px 5px 10px;
    width: 20%;
    display: flex;
    justify-content: center;
    border-radius: 3px;
  }
}

.chat-button-av {
  border: none;
  outline: none;
  background-color: #066cb5;
  padding: 5px;
  width: 20%;
  display: none;
  color: white;
  font-weight: 500;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
}

.chat-button-av1 {
  border: none;
  outline: none;
  background-color: #066cb5;
  padding: 5px 10px 5px 10px;
  width: 25%;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  color: white;
  cursor: pointer;
}

.cone-nor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.categories-map-gig {
  width: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: space-around;
  flex-wrap: wrap;
  gap: 12px;
}

.subcategory-count-gig {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
}

.overview-container-gig {
  border-radius: 7px;
  width: 100%;
  // padding: 10px;
}

.title-view-all-cont-gig {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.custom_message_container2 {
  height: 62vh;
  overflow: auto;
  flex: 1;
}

.courseBatches-div2 {
  display: flex;
  align-items: center;
}

.courseRating-div1 {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  gap: 10px;
  margin-bottom: 5px;
}

.courseRating-div2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.review-time-n-rting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.doesnot-have-refund {
  border: 2px solid $BORDER_COLOR;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.courseRating-icon1 {
  color: rgba(32, 118, 229, 1);
  margin-right: 0px;
}

.courseRating-button1 {
  background-color: #fff;
  border: 0px;
}

.courseRating-div3 {
  width: 100%;
  // padding-bottom: 15px;
  position: relative;
  margin-top: 10px;
}

.courseRating-div4 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: scroll;
}

.courseRating-text1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.courseRating-text2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis;
  max-width: 100%;
}

.courseRating-text3 {
  color: #002058;
}

.courseRating-text4 {
  cursor: pointer;
  color: #078dda;
}

.courseReviewsABuy-div1 {
  display: flex;
  // width: 100%;
  flex-direction: column;
}

.courseReviewsABuy-div2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.courseReviewsABuy-div3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.courseReviewsABuy-icon1 {
  color: rgba(48, 131, 239, 1);
  margin-bottom: 5px;
  height: 18px;
}

.courseReviewsABuy-div4 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.courseReviewsABuy-div5 {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.courseReviewsABuy-text1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-box-align: 2;
  height: 20px;
  text-overflow: ellipsis;
  max-width: 100%;
}

.courseReviewsABuy-text3 {
  color: #000000;
  margin-bottom: 4px;
}

.courseReviewsABuy-text4 {
  color: grey;
  margin-bottom: 5px;
}

.courseReviewsABuy-text5 {
  color: #626972;
  margin-bottom: 10px;
}

.courseReviewsABuy-div6 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.courseReviewsABuy-div7 {
  margin-top: 15px;
  width: 100%;
}

.courseReviewsABuy-div8 {
  display: flex;
  flex-direction: column;
}

.courseReviewsABuy-div9 {
  width: 98%;
}

.detailsComp-div1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 10px;
}

.detailsComp-div2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.detailsComp-text1 {
  overflow: hidden;
  color: grey;
  width: 120px;
}

.detailsComp-text2 {
  color: #ffffff;
}

.detailsComp-text3 {
  margin-right: 10px;
  margin-left: 10px;
}

.detailsComp-text4 {
  margin-left: 10px;
}

.detailsLeft-div1 {
  width: 600px;
  height: 410px;
  margin-bottom: 10px;
}

.detailsLeft-div2 {
  width: 100%;
  align-self: center;
}

.detailsLeft-carousel1 {
  width: 100%;
  // background-size: cover;
  // position: relative;
}

.detailsLeft-div3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); // Apply the blur effect
}

.detailsLeft-div4 {
  background: red;
  padding: 4px;
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 8px;
}

.detailsLeft-div5 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
}

.detailsLeft-div6 {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.detailsLeft-div7 {
  display: flex;
  flex-direction: row;
  background-color: #f9ef99;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.detailsLeft-div8 {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.detailsLeft-div9 {
  margin: 20px 0px;
  position: relative;
}

.detailsLeft-div10 {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}

.detailsLeft-div11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  margin-bottom: 6px;
}

.detailsLeft-div12-details {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
}

.detailsLeft-div13 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  padding: 5px;
  transition: all 0.35s ease;
}
.detailsLeft-div13:hover {
  transform: scale(1);
  // padding: 8px;
}

.detailsLeft-div14 {
  overflow: scroll;
  height: 84vh;
}

.detailsLeft-img1 {
  width: 100%;
  height: 100%;
  // object-fit: contain;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.detailsLeft-text1 {
  color: $HEADING_COLOR;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 6px;
}
.detailsLeft-text2 {
  color: $PARAGRAPH_COLOR;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 10px;
}

.detailsLeft-text2 {
  -webkit-line-clamp: 3;
}

.detailsLeft-text3 {
  color: #fff;
}

.detailsLeft-text5 {
  color: #5c5b57;
}

.detailsLeft-text6 {
  color: #626972;
  margin: 6px 6px;
}

.detailsLeft-text7 {
  margin-top: 10px;
  color: #626972;
}

.detailsLeft-text9 {
  color: $Aauti_theme_color;
  cursor: pointer;
}
.refund-btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.detailsLeft-text10 {
  color: $HEADING_COLOR;
  // margin-bottom: 5px;
}

.detailsLeft-text11 {
  cursor: pointer;
  color: #078dda;
  margin-bottom: 15px;
  margin-left: 5px;
}
.category-box-gig-new {
  border: 1px solid #e5e9ed;
  width: 150px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.TSAiFillStar {
  background: $Aauti_theme_color;
  padding: 6px;
  display: flex;
  border-radius: 4px;
  flex-direction: row;
  gap: 2px;
  width: fit-content;
}

.rating-card-rating {
  cursor: pointer;
  align-self: flex-start;
  color: $Aauti_theme_color;
}

.detailsLeft-icon1 {
  cursor: pointer;
  align-self: flex-start;
  color: #fff;
}

.nodata-div1 {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.reviewCard-div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
}

.reviewCard-div2 {
  border: none;
  cursor: pointer;
  background-color: #fff;
}

.reviewCard-div3 {
  margin-top: 0;
  overflow: visible;
  border: 1px solid #d3d3d385;
  background-color: #fff;
  will-change: transform;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

.reviewCard-div4 {
  width: 130px;
  border-radius: 10px;
  margin: 8px;
  margin-right: 5px;
}

.reviewCard-div5 {
  padding: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reviewCard-div6 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2px;
  align-items: flex-start;
  width: 100%;
}

.reviewCard-text1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
  text-align: left;
  height: 42px;
}

.reviewCard-text2 {
  color: #1e98d7;
}

.reviewCard-button1 {
  color: #db738b;
}

.reviewCard-div7 {
  width: 100%;
  margin-top: 5px;
}

.reviewCard-div8,
.reviewCard-div9 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.reviewCard-div9 {
  gap: 4px;
  margin-top: 5px;
}

.reviewCard-div10 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  margin-top: 5px;
}

.reviewCard-div11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 4px;
}

.reviewCard-div12 {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.reviewCard-div13 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.reviewCard-img1 {
  height: 17px;
  width: 17px;
  z-index: 100;
}

.reviewCard-img2 {
  height: 18px;
  width: 18px;
  z-index: 100;
}

.reviewCard-div14 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4px;
  width: 100%;
}

.reviewCard-div15 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.reviewCard-div16 {
  display: flex;
}

.rightCont-div1-details {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 55%;
  flex-wrap: wrap;
}

.rightCont-div2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
}

.rightCont-div3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // gap: 10px;
  // width: 100%;
}

.rightCont-div4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.rightCont-div5 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.rightCont-div7 {
  width: 100%;
}

.rightCont-div8 {
  margin-top: 15px;
}

.rightCont-text1 {
  background-color: #3166ba;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.rightCont-text6 {
  background-color: #232425;
  color: #fff;
  // border-radius: 50%;
  width: 100%;
  height: 23px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: ellipsis;
}

.rightCont-text2 {
  width: 45%;
}

.rightCont-text3-details {
  width: 45%;
}

.rightCont-text4-details {
  // color: $Aauti_theme_color;
  width: 55%;
}

.rightCont-text5 {
  color: $Aauti_theme_color;
  cursor: pointer;
}

.rightCont-div6 {
  color: $Aauti_theme_color;
}

.rightCont-input1 {
  margin: 0;
}

.flexStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.flexItem-preview {
  display: flex;
  flex-direction: row;
  // gap: 10px;
  align-items: center;
  width: 60%;
  justify-content: flex-start;
}

.chatWithSp-div1 {
  padding: 10px;
  display: flex;
  height: 100%;
}

.detailSLoad-div1 {
  display: flex;
  justify-content: space-between;
}

.detailSLoad-div2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.detailSLoad-div3 {
  width: 60%;
}

.detailSLoad-div4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.tag {
  background-color: $TAG_BACKGROUND;
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainComp-div1 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
}

.mainComp-button1 {
  width: 100px;
  height: 40px;
}

.slotCard-text1 {
  color: #008000;
}

.slotCard-text2 {
  color: #333333;
}

.slotCard-text3 {
  color: #598f00;
}

.slotCard-text4 {
  color: #e64b0e;
}

.slotCard-text5 {
  color: #e6990e;
}

.slotCard-text6 {
  color: #3166ba;
}

.slotCard-div1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
}

.slotCard-div2 {
  padding: 10px;
}

.slotCard-div3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;
  margin-bottom: 5px;
}

.slotCard-div7,
.slotCard-div4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.slotCard-div4 {
  margin-bottom: 5px;
}

.slotCard-div5 {
  display: flex;
  margin-bottom: 6px;
  margin-top: 6px;
  align-items: center;
  gap: 8px;
}

.slotCard-div6 {
  display: flex;
  flex-direction: column;
}

.slotCard-img1 {
  height: 15px;
  width: 15px;
}

.subCourses-div1 {
  width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.subCourses-div2 {
  height: fit-content;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
}

.subCourses-div3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.userCard-div1 {
  margin-bottom: 10px;
  width: 100%;
}

.userCard-div2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userCard-div3 {
  padding: 1px 4px 1px 4px;
  position: absolute;
  top: 68px;
  border-radius: 6px;
  width: fit-content;
  color: #ffffff;
  background-color: $Aauti_theme_color;
}

.userCard-div5 {
  width: 100%;
  overflow: hidden;
}

.userCard-div6 {
  display: flex;
  flex-direction: column;
  // gap: 2px;
  align-items: flex-start;
  margin-bottom: 4px;
  max-width: 100%;
}

.userCard-text1 {
  color: $HEADING_COLOR;
  margin-bottom: 10px;
}

.userCard-text2 {
  color: #fff;
}

.userCard-text3 {
  color: #000000;
  // margin-bottom: 4px;
}

.advertisement-container-details {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid #f2f2f6;
  border-radius: 6px;
  margin-top: 10px;
  height: 320px;
  min-height: 320px;
}

.userCard-icon1 {
  cursor: pointer;
  color: #fff;
  margin-right: 5px;
}

.userCard-div4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userCard-div7 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
@media (max-width: 600px) {
  .userCard-div7 {
    margin-top: 5px;
  }
}
.userCard-div8 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

@media screen and (max-width: 600px) {
  // .userCard-div7,
  .userCard-div6 {
    align-items: center;
  }
  .sp-detaisl
  // .userCard-div6
  {
    justify-content: center;
  }
  .subCourses-div2 {
    width: 100%;
    max-width: 100%;
    min-width: 99%;
  }
  .subCourses-div1 {
    flex-direction: column;
  }
  .courseReviewsABuy-div2 {
    flex-direction: column;
  }
  .reviewCard-div8 {
    gap: 3px;
  }
}

.container-realted-documents-map {
  display: flex;
  flex-wrap: wrap;
}

// slider styles in left container

.slide-container-details {
  width: 100%;
  height: 60vh;
  position: relative;
  border-radius: 20px;
}
.main-profile-container-mobile {
  display: none;
  overflow-x: auto;
  gap: 10px;
}
.mobile-profile-label-item {
  padding: 5px 10px;
  border-radius: 5px;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(92, 91, 87, 0.2);
  gap: 10px;
}

.mobile-profile-label {
  font-size: 12px !important;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 849px) {
  .main-section-container {
    background-color: unset;
    padding: unset;
  }
  // .main-profile-container,
  // .slide-container-details {
  //   display: none;
  // }
  .main-profile-container-mobile {
    display: flex;
  }
}

.profle-label-text {
  font-weight: 400;
  font-size: 15px !important;
}
.profile-sub-text {
  font-size: 13px !important;
  color: rgba(92, 91, 87, 1);
}
.profile-opt-img {
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.profile-user-container {
  display: flex;
  gap: 10px;
}
.profle-hr-line {
  border: 0.5px solid rgba(92, 91, 87, 0.2);
  margin: 20px 15px;
}
.profile-option-icon {
  width: 35px;
  height: 35px;
  position: relative;
}
.profile-active-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  right: -2px;
  bottom: -5px;
  border: 1px solid #fff;
}
// .profile-options {
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// }
// .profile-option-item {
//   display: flex;
//   gap: 10px;
//   cursor: pointer;
// }

.slide-container-details .slider-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 0.5px solid $BORDER_COLOR;
}
.slide-container-details .slick-slider.slick-initialized {
  height: 100%;
}
.slide-container-details .slick-dots {
  bottom: 20px !important;
}
.slide-container-details .slick-list,
.slick-track {
  height: 100%;
}
.slide-container-details .slick-slide {
  margin-top: unset !important;
  border-radius: 20px;
}
.slide-container-details .slick-slide div {
  height: 100% !important;
  display: flex !important;
  align-items: center;
  border-radius: 20px;
}
.slide-container-details button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  left: 10px;
  z-index: 99;
  background-color: blue;
  display: none !important;
}
// .slide-container-details button.slick-arrow.slick-next {
//   right: 10px;
//   z-index: 99;
//   background-color: blue;
// }

@mixin slide-btn {
  position: absolute;
  z-index: 99;
  height: 30px;
  width: 30px;
  outline: none;
  // background-color: $secondary_color;
  background-color: #fff;

  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-container-details .prev-btn {
  @include slide-btn;
  top: 45%;
  left: 20px;
  cursor: pointer;
}
.slide-container-details .next-btn {
  @include slide-btn;
  top: 45%;
  right: 20px;
  cursor: pointer;
}
.slide-container-details .slide-on-top-container {
  position: absolute;
  top: 60%;
  left: 70px;
}
.slide-container-details .slide-txt {
  color: rgba(92, 91, 87, 1);
  margin-bottom: 10px;
}
.slide-container-details .slide-btn-course {
  outline: none;
  border: none;
  padding: 15px 30px;
  background-color: rgba(48, 131, 239, 1);
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}
