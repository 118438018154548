#EASY_TO_START {
  // height: calc(200px + (382 - 200) * ((100vw - 320px) / (1550 - 320)));
  padding-left: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
  padding-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
  padding-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1550 - 320)));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: max-content;
  width: 100%;

  .easy_to_start_frame {
    width: calc(300px + (770 - 300) * ((100vw - 320px) / (1550 - 320)));
    // height: calc(200px + (380 - 200) * ((100vw - 320px) / (1550 - 320)));
    position: relative;
    height: max-content;

    .easy_to_start_label {
      font-family: "HelveticaNeueBold", sans-serif;
      font-size: calc(24px + (44 - 24) * ((100vw - 320px) / (1550 - 320)));
      font-weight: 700;
      line-height: calc(40px + (70 - 40) * ((100vw - 320px) / (1550 - 320)));
      text-align: left;
      color: #1e1e1e;
    }

    .easy_to_start_Learning_box {
      width: calc(150px + (210 - 150) * ((100vw - 320px) / (1550 - 320)));
      height: calc(50px + (70 - 50) * ((100vw - 320px) / (1550 - 320)));
      top: calc(150px + (165 - 150) * ((100vw - 320px) / (1550 - 320)));
      left: calc(80px + (100 - 80) * ((100vw - 320px) / (1550 - 320)));
      gap: 10px;
      border-radius: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
      border: 2px solid #3083ef;
      opacity: 0.8; // Adjust opacity for better visibility
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: calc(30px + (40 - 30) * ((100vw - 320px) / (1550 - 320)));

      .easy_to_start_Learning_label {
        font-family: "HelveticaNeueLight", sans-serif;
        font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1550 - 320)));
        font-style: italic;
        font-weight: 500;
        line-height: calc(24px + (42 - 24) * ((100vw - 320px) / (1550 - 320)));
        text-align: center;
      }
    }

    .easy_to_start_description_box {
      margin-top: 40px;

      .easy_to_start_description {
        font-family: "HelveticaNeueLight", sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 899px) {
  #EASY_TO_START {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    height: max-content;
    padding-bottom: 40px;

    .easy_to_start_frame {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .easy_to_start_description_box {
        .easy_to_start_description {
          text-align: center !important;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.easy_to_start_store_container_steps {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

@media screen and (max-width: 500px) {
  span.easy_to_start_label_span {
    background: #F9EF99;
    border-radius: 20px;
  }

  #EASY_TO_START .easy_to_start_frame .easy_to_start_Learning_box {
    height: 48px;
    width: 137px;
    border-radius: 40px;
  }

  #EASY_TO_START .easy_to_start_frame .easy_to_start_description_box .easy_to_start_description {
    font-size: 18px;
  }

  .easy_to_start_store_container_steps {

    width: 280px;
    height: 210px;
  }
}