.file_info_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  background: rgba(0, 0, 0, 0.4);
  padding: 6px;
  border-radius: 4px;
  column-gap: 8px;
  align-items: center;
  margin-top: 4px;
}

.file_content_wrapper{
  flex: 1 1 180px;
  display: flex;
  flex-direction: column;
}

.file_name {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  max-width: 180px;
}