@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Montserrat:wght@500;600&family=Mulish&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.text-input-clear-btn-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f7f7f7;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  outline-style: none;
  column-gap: 8px;
  padding:0px 9px;
}

.search-box-input {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  outline-style: none;
  background-color: #f7f7f7;
  border: none;
  font-family: "Mulish", sans-serif !important;
  padding-left: 5px;
  font-weight: 500;
}

button.search-box-btn {
    border: none;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }