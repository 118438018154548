.EPInfoContainer {
  padding: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
.EPbg {
  position: relative;

  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
  }

  .EPoverlay {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    color: #fff;
    opacity: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &:hover {
    .EPoverlay {
      opacity: 1;
    }

    img {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }
  }
}

.EPContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  align-items: flex-start;
}
.EPSpinnerStyle {
  display: flex;
  height: 90vh;
}
.EPleftContainer {
  width: 20%;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-top: 40px;
}

.EPitemDiv {
  border-bottom: 1px solid #f2f2f6;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.EPselectedItemBorder {
  position: absolute;
  border: 2px solid #3083ef;
  height: 65%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.EPflag-img {
  height: 15px;
  width: 20px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.detailsField {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.detailsSubField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
}

.studyCheckBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.editDetails {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f2f2f6;
}

.EPcloseIcon {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  color: black;
  background-color: lightGrey;
  border-radius: 50%;
}

.videoContainer {
  height: 100%;
  width: 100%;
  background-size: contain;
  cursor: pointer;
  border-radius: 5px;
}

.privacyBtnStyle {
  display: flex;
  border: 1px solid #dddddd;
  padding: 8px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.editCard {
  border-top: 1px solid #f2f2f6;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f2f2f6;
}

.EPlistContainer {
  display: flex;
  flex-direction: column;
}

.EPlistSubContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.addAddressDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.verifiedSpan {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addressContainer {
  border: 1px solid #2076e5;
  margin-top: 7px;
  padding: 10px;
  border-radius: 4px;
  background-color: #2076e50a;
}

.addressSubContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headFieldContainer {
  border-bottom: 1px solid #f2f2f6;
  padding-top: 14px;
  padding-bottom: 14px;
}

.headFieldSubContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headTitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headSubTitleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.headVideContainer {
  cursor: pointer;
  height: 48px;
  width: 60px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}
.verifyDiv {
  margin-right: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.countryField {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.globeStyle {
  cursor: pointer;
}

.globeStyle_out {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.cancelBtnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
}
.EPcamImg {
  width: 100%;
  justify-self: center;
  align-self: center;
}

.EPBasicInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.privacyModel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.privacyUpdateBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
}

.customHeadStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.customSubHead {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.achieveView {
  height: 80px;
  width: 138px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.EPuploadLoader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

.EPuploadedData {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-top: 7px;
}

.previewImageStyle {
  height: 48px;
  width: 60px;
  border-radius: 5px;
  margin-right: 8px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.infoHead {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EPtextHover:hover {
  color: black;
}

.EPtextHover {
  font-weight: 600;
  color: #5c5b57;
  cursor: pointer;
}

.EPImgContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.EPaccordion-box {
  width: 100%;
  max-height: 0; /* Initially hidden */
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.EPaccordion-box.open {
  max-height: 1000vh; /* Set a large enough value for the content */
  transition: max-height 0.5s ease-in;
}

.EPaccordionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.regLoader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.EPimgView {
  display: flex;
  flex-direction: row;
  position: relative;
}
.basicInfoNameFields {
  margin-top: 15px;
  border-bottom: 1px solid #f2f2f6;
  padding-bottom: 12px;
}
