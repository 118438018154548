// #FAQ_CONTAINER {
//     padding-bottom: 50px;
//     padding-left: 100px;
//     padding-right: 100px;

//     .faq_label_container {
//         text-align: center;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;

//         .faq_label {
//             font-family: "HelveticaNeueBold", sans-serif;
//             font-size: 44px;
//             font-weight: 700;
//             line-height: 56px;
//             text-align: center;
//             margin-bottom: 10px;

//         }
//     }

//     .faq_title {
//         font-family: 'HelveticaNeueLight', sans-serif;
//         font-size: 20px;
//         font-weight: 600;
//         line-height: 24px;
//         text-align: left;

//     }

//     .faq_description {
//         font-family: 'HelveticaNeueLight', sans-serif;
//         font-size: 16px;
//         font-weight: 500;
//         line-height: 32px;
//         text-align: left;

//     }

//     .faq_accordion {
//         margin-left: 100px;
//         margin-right: 100px;
//         margin-top: 100px;
//     }

//     .view_all_faqs_container {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         justify-content: center;
//         padding-top: 40px;

//         .view_all_faqs {
//             width: 248px;
//             height: 60px;
//             color: #fff;
//             padding: 10px 30px 10px 30px;
//             gap: 10px;
//             border-radius: 10px;
//             opacity: 0px;
//             background-color: #3083EF;
//             text-transform: capitalize;
//         }
//     }

// }

#FAQ_CONTAINER {
  padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1550 - 320)));
  padding-left: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
  padding-right: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));

  .faq_label_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .faq_label {
      font-family: "HelveticaNeueBold", sans-serif;
      font-size: calc(30px + (44 - 30) * ((100vw - 320px) / (1550 - 320)));
      font-weight: 700;
      line-height: calc(40px + (56 - 40) * ((100vw - 320px) / (1550 - 320)));
      text-align: center;
      margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
    }
  }

  .faq_title {
    font-family: "HelveticaNeueLight", sans-serif;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
    font-weight: 600;
    line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (1550 - 320)));
    text-align: left;
  }

  .faq_description {
    font-family: "HelveticaNeueLight", sans-serif;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1550 - 320)));
    font-weight: 500;
    line-height: calc(24px + (32 - 24) * ((100vw - 320px) / (1550 - 320)));
    text-align: left;
    margin-left: 15px;
  }

  .faq_accordion {
    margin-left: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    margin-right: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
    margin-top: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
  }

  .view_all_faqs_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));

    .view_all_faqs {
      width: calc(150px + (248 - 150) * ((100vw - 320px) / (1550 - 320)));
      height: calc(40px + (60 - 40) * ((100vw - 320px) / (1550 - 320)));
      color: #fff;
      padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1550 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
      gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
      border-radius: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
      opacity: 1;
      background-color: #3083ef;
      text-transform: capitalize;
    }
  }
}

@media screen and (max-width: 899px) {
  #FAQ_CONTAINER {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .faq_accordion {
      margin-left: 0px;
      margin-right: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .faq_label_span {
    background-color: #F9EF99;
    border-radius: 25px;
  }
}